import { format } from '../../utils'

const mapCard = (json) => {
    const {
        code,
        country,
        type,
        stiTaxCode,
        description,
        foreignCurrency,
        percentage,
        flatAmount,
        foreignCurrencyAmount
    } = json

    return [
        [
            { labels: ['code'], value: code, type: 'string' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ],
        [
            { labels: ['type'], value: type, type: 'string' }
        ],
        [
            { labels: ['sti_tax_code'], value: stiTaxCode, type: 'string' }
        ],
        [
            { labels: ['description'], value: description, type: 'string' }
        ],
        [
            { labels: ['foreign_currency'], value: foreignCurrency, type: 'string' }
        ],
        [
            { labels: ['percentage'], value: format((parseFloat(percentage) || 0).toFixed(2), 'normal', null), type: 'percentage' }
        ],
        [
            { labels: ['flat_amount'], value: format(flatAmount), type: 'currency' }
        ],
        [
            { labels: ['currency_amount'], value: format(foreignCurrencyAmount), type: 'currency' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.tax_type

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ["Code"], align: 'left' },
    { label: ["Country"], align: 'left' },
    { label: ["Type"], align: 'left' },
    { label: ["STI Tax Code"], align: 'left' },
    { label: ["Description"], align: 'left' },
    { label: ["Foreign Currency"], align: 'left' },
    { label: ["Percentage"], align: 'center' },
    { label: ["Flat Amount"], align: 'right' },
    { label: ["Foreign Currency Amount"], align: 'right' }
]

export default {
    map: mapper,
    header
}