import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		name,
		username,
		role
	} = json
	return [
		[
			{ labels: ['name'], value: name, type: 'string' },
		],
		[
			{ labels: ['username'], value: username, type: 'string' }
		],
		[
			{
				labels: ['role'],
				value: I18n.translate(`role_${role}`),
				type: 'string'
			}
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Name'], align: 'left' },
	{ label: ['Username'], align: 'left' },
	{ label: ['Role'], align: 'left' }
]

export default {
	map: mapper,
	header
}