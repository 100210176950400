import { date } from '../../utils'

const mapDetails = (json) => {
	const {
		assetID,
		registrationID,
		generalLedgerAccountRowKey,
		account,
		acquisition,
		commissioning,
		description
	} = json
	return [
		[
            { label: 'Asset', value: assetID, type: 'string' },
            { label: 'Registration ID', value: registrationID, type: 'string' },
            generalLedgerAccountRowKey ? 
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: account, __typename: 'GeneralLedger' }, type: 'object' } : 
                { label: 'Account', value: account, type: 'string' },
            { label: "Acquisition", value: date(acquisition), type: 'date' },
            { label: "Commissioning", value: date(commissioning), type: 'date' },
            { label: "Description", value: description, type: 'string' }
        ]
	]
}

const header = [
    { label: ["Description"], align: 'left' },
    { label: ["Asset"], align: 'left' },
    { label: ["Registration ID"], align: 'left' },
    { label: ["Acquisition"], align: 'center' },
    { label: ["Commissioning"], align: 'center' }
]

const mapCard = (json) => {
	const {
        description,
        assetID,
        registrationID,
        acquisition,
        commissioning
	} = json

	return [
        [
            { labels: ['description'], value: description, type: 'string' }
        ],
        [
            { labels: ['asset'], value: assetID, type: 'string' }
        ],
        [
            { labels: ['registration_id'], value: registrationID, type: 'string' }
        ],
        [
            { labels: ['acquisition'], value: date(acquisition), type: 'date' }
        ],
        [
            { labels: ['commissioning'], value: date(commissioning), type: 'date' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.assetID
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
        details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}