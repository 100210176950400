import App from '../controllers/App/App'
import { List, rows, charts, Filters, Grid, Widget } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'
// import theme from 'colbi_web_ui/lib/style/_theme.json'

const queries = (args = {}) => {
	return [
		{
			resource: args.entity,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
				sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined,
				page: parseInt(args.page, 10) || 0
			},
			body: `__typename, items{ ...${ args.entity } }, hasMore, total, filters(projectId:$projectId,fiscalYear:$fiscalYear){ prop, name, type, list }`,
			downloadable: true
		},
		{
			resource: `${ args.entity }_summary`,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'summary{ key, value }'
		},
	]
}

const overview = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: App,
	queries,
	exact,
	title: [title, 'label(param(entity))'].filter(l => l),
	components: [
		{
			component: Widget,
			modifiers: ['--root-margin-bottom-small'],
			props: {
				icon: 'sales',
				title: ['Number of ', 'param(entity)', ' per type'],
				children: [
					{
						component: Grid,
						props: {
							columnsTemplate: [1],
							aspect: ['18x6'],
							items: [
								{
									data: 'query(1).list',
									labelsPosition: 'inside',
									stacked: true,
									summarized: true,
									links: [
										{
											to: ['param(projectId)', 'param(fiscalYear)', '/', 'param(entity)'],
											filterColumn: 'period',
											options: {
												invoices: 'invoice_type',
												payments: 'payment_type',
												stock_movements: 'movement_type',
												transactions: 'journal_description',
												work_documents: 'work_type',
												purchase_invoices: 'invoice_type'
											}
										}
									]
								}
							],
							cards: [charts.BarChart]
						}
					}
				]
			}
		},
		{
			component: Filters,
			props: {
				filter: 'param(filter)',
				availableFilters: 'query(0).filters',
				stickyTop: 40,
				anchor: 'results'
			}
		},
		{
			component: List,
			modifiers: [
				'--root-margin-top-small',
				'--row-style-alternate'
			],
			props: {
				id: 'results',
				items: 'query(0).list',
				filter: 'param(filter)',
				sort: 'param(sort)',
				basePath: path,
				cardKey: 'param(entity)',
				card: rows.EntityCard,
				header: 'query(0).header',
				metadata: 'query(0).metadata',
				page: 'param(page)',
				status: 'status(0)',
				pageSize: 'param(pageSize)',
				footer: 'query(0).footer'
			}
		}
	]
})

export default overview
