import { stringify } from 'query-string'
export function hydrateRoute(routePath, params) {
	const withoutLocale = (routePath || '').replace(/^\/:locale\(.+?\)\?/, '')
	const hydrated = withoutLocale.replace(/:[a-zA-Z]+(\(.+?\))?\??\*?/ig, (part) => {
		const paramName = part.slice(1).replace(/\(.*?\)\??\*?|\?|\*/, '')
		const defaultValue = part.match(/\?$/) ? '' : part
		return params[paramName] !== undefined ? params[paramName] : defaultValue
	})

	return params.locale && hydrated.match(/^\//) ? `/${params.locale}${hydrated}` : hydrated
}

function injectFilters(path, queryParams) {
	if ((queryParams && !queryParams.filter) || !queryParams) {
		return path
	}
	return `${path}?${stringify({ filter: queryParams.filter })}`
}

export function createLink(basePath, proxy, item, params, queryParams) {
	if (!((item && item.id && `${item.id}`.length) || (item && item.to && `${item.to}`.length))) { return null }
	if (basePath) {
		return injectFilters(`${hydrateRoute(basePath, { ...params })}/${item.id}`, queryParams)
	}
	if (item.to) {
		return injectFilters(hydrateRoute(item.to, { ...params }), queryParams)
	}
	const proxyPath = (proxy || {})[item['__typename']]
	return injectFilters(hydrateRoute(proxyPath, { ...params, id: item.id }), queryParams)
}