const navigation = [
	// {
	// 	name: 'General',
	// 	to: '/settings',
	// 	exact: true,
	// 	icon: 'dashboard'
	// },
	// {
	// 	type: 'separator'
	// },
	{
		name: 'Projects',
		to: '/settings/projects',
		exact: true,
		icon: 'projects'
	},
	{
		name: 'Users',
		to: '/settings/users',
		exact: true,
		icon: 'users'
	},
	{
		type: 'separator'
	},
	{
		name: 'Rules',
		to: '/settings/rules',
		exact: true,
		icon: 'rules'
	},
	{
		type: 'separator'
	},
	{
		name: 'Home',
		to: '/',
		exact: true,
		icon: 'back'
	}
]

export default navigation
