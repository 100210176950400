import { date, format } from '../../utils'

const header = [
    { label: ["[Currency Code]"], align: 'left' },
    { label: ["Discount"], align: 'left' },
    { label: ["Due Date"], align: 'center' },
    { label: ["Date"], align: 'center' },
    { label: ["Payment Mechanism"], align: 'center' },
    { label: ["Debit Credit Indicator"], align: 'center' },
    { label: ["Ammount"], align: 'right' },
    { label: ["Currency Ammount"], align: 'right' }
]

const mapCard = (json) => {
	const {
        settlementAmountCurrencyCode,
        settlementDiscount,
        settlementDueDate,
        settlementDate,
        paymentMechanism,
        debitCreditIndicator,
        settlementAmount,
        settlementAmountCurrencyAmount
	} = json

	return [
        [
            { labels: ['code'], value: settlementAmountCurrencyCode || 'N/A', type: 'string' }
        ],
        [
            { labels: ['discount'], value: settlementDiscount || 'N/A', type: 'string' }
        ],
        [
            { labels: ['duoDate'], value: date(settlementDueDate), type: 'date' }
        ],
        [
            { labels: ['date'], value: date(settlementDate), type: 'date' }
        ],
        [
            { labels: ['paymentMechanism'], value: paymentMechanism, type: 'number' }
        ],
        [
            { labels: ['debitCreditIndicator'], value: debitCreditIndicator, type: 'number' }
        ],
        [
            { labels: ['ammount'], value: format(settlementAmount), type: 'currency' }
        ],
        [
            { labels: ['currencyAmmount'], value: format(settlementAmountCurrencyAmount), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.invoice_id || json.purchase_invoice_id || json.payment_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}