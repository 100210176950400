const navigation = [
	{
		name: 'label(Activity)',
		to: '/:projectId/repository/activity/status',
		exact: true,
		icon: 'activity'
	},
	{
		name: 'label(Folders)',
		to: '/:projectId/repository/folders',
		icon: 'folders',
		navigation: 'prop(folderData)'
	},
	{
		name: 'label(Overview)',
		to: '/:projectId/',
		exact: true,
		icon: 'back'
	}
]

export default navigation
