import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		d01,
		l01,
		f01,
		f02,
		f05,
		f06,
		f07,
		f08,
		code
	} = json

	return [
		[
			{ labels: ['taxonomy_code'], value: code, type: 'string', basis: '10%' }

		],
		[
			{ labels: ['account_code'], value: d01, type: 'string' }

		],
		[
			{ labels: ['description'], value: I18n.translate`${l01}`, type: 'string' }

		],
		[
			{ labels: ['debit_period'], value: format(f02), type: 'currency' }

		],
		[
			{ labels: ['credit_period'], value: format(f01), type: 'currency' }

		],
		[
			{ labels: ['accumulated_debit'], value: format(f06), type: 'currency' }

		],
		[
			{ labels: ['accumulated_credit'], value: format(f05), type: 'currency' }

		],
		[
			{ labels: ['debit_balance'], value: format(f08), type: 'currency' }

		],
		[
			{ labels: ['credit_balance'], value: format(f07), type: 'currency' }
		]
	]
}

const header = [
	{ label: ['Taxonomy Code'], align: 'left', basis: '10%' },
	{ label: ['Account Code'], align: 'left' },
	{ label: ['Description'], align: 'left' },
	{ label: ['Period Debit'], align: 'right' },
	{ label: ['Period Credit'], align: 'right' },
	{ label: ['Accumulated Debit'], align: 'right' },
	{ label: ['Accumulated Credit'], align: 'right' },
	{ label: ['Debit Balance'], align: 'right' },
	{ label: ['Credit Balance'], align: 'right' }
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/general_ledgers/${json.rowKeyGeneralLedgers || ''}`
		}
	}
}

export default {
	map: mapper,
	header
}