import { format, date } from "../../utils"

const mapDetails = (json) => {
	const {
		fiscalYear,
		auditFileVersion,
		startDate,
		endDate,
		createdDate,
		description,
		application,
		company = {},
		salesInvoices = {},
		glTransactions = {},
		workingDocuments = {},
		payments = {},
		stockMovements = {},
		file = {}
	} = json

	return [
		[
			{ label: 'Version', value: auditFileVersion, type: 'string' },
			{ label: 'Created Date', value: date(createdDate), type: 'date' },
			{ label: 'Start Date', value: date(startDate), type: 'date' },
			{ label: 'End Date', value: date(endDate), type: 'date' },
			{ label: 'Fiscal Year', value: fiscalYear, type: 'string' },
			{ label: 'Header Comment', value: description, type: 'string' },
		],
		[],
		[
			{ label: 'System', value: application.name, type: 'string' },
			{ label: 'System Version', value: application.version, type: 'string' },
			{ label: 'System Certificate', value: application.certificateNumber, type: 'string' },
			{ label: 'System Owner', value: application.owner, type: 'string' },
			{ label: 'System Type', value: application.type, type: 'string' },
		],
		{
			title: 'Company',
			rows: [
				[
					{ label: 'Name', value: company.name || 'N/A', type: 'string' },
					{ label: 'VAT', value: company.vat || 'N/A', type: 'string' },
					{ label: 'Tax Entity', value: company.taxEntity || 'N/A', type: 'string' },
					{ label: 'Business Name', value: company.businessName || 'N/A', type: 'string' },
					{ label: 'Registry', value: company.registry || 'N/A', type: 'string' },
					{ label: 'Phone', value: company.phone || 'N/A', type: 'string' },
					{ label: 'Fax', value: company.pax || 'N/A', type: 'string' },
					{ label: 'Email', value: company.email || 'N/A', type: 'string' },
					{ label: 'Website', value: company.website || 'N/A', type: 'string' }
				]
			]
		},
		{
			title: 'Sales Invoices',
			rows: [
				[
					{ label: 'Entries No', value: format(salesInvoices.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(salesInvoices.debitTotal), type: 'currency' },
					{ label: 'Credit Total', value: format(salesInvoices.creditTotal), type: 'currency' }
				]
			]
		},
		{
			title: 'GL Transactions',
			rows: [
				[
					{ label: 'Entries No', value: format(glTransactions.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(glTransactions.debitTotal), type: 'currency' },
					{ label: 'Credit Total', value: format(glTransactions.creditTotal), type: 'currency' }
				]
			]
		},
		{
			title: 'Working Documents',
			rows: [
				[
					{ label: 'Entries No', value: format(workingDocuments.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(workingDocuments.debitTotal), type: 'currency' },
					{ label: 'Credit Total', value: format(workingDocuments.creditTotal), type: 'currency' }
				]
			]
		},
		{
			title: 'Payments',
			rows: [
				[
					{ label: 'Entries No', value: format(payments.entriesNumber, null, null), type: 'number' },
					{ label: 'Debit Total', value: format(payments.debitTotal), type: 'currency' },
					{ label: 'Credit Total', value: format(payments.creditTotal), type: 'currency' }
				]
			]
		},
		{
			title: 'Stock Movements',
			rows: [
				[
					{ label: 'Entries No', value: format(stockMovements.entriesNumber, null, null), type: 'number' },
					{ label: 'Quantity Issued', value: format(stockMovements.quantityIssued, null, null), type: 'number' }
				]
			]
		},
		{
			title: 'File',
			rows: [
				[
					{ label: 'ID', value: file.id || 'N/A', type: 'string' },
					{ label: 'Type', value: file.type || 'N/A', type: 'string' },
					{ label: 'Version', value: file.version || 'N/A', type: 'string' },
					{
						label: 'Uploaded At',
						value: date(file.uploadedAt) || 'N/A',
						type: 'date'
					},
					{ label: 'Name', value: file.name || 'N/A', type: 'string' }
				]
			]
		}
	]
}

const mapCard = (json) => {
	const {
		fiscalYear,
		auditFileVersion,
		taxRegistrationNumber,
		businessName,
		startDate,
		endDate,
		glTransactionsCount,
		invoicesCount,
		stockMovementsCount,
		workingDocumentCount,
		paymentsCount
	} = json

	return [
		[
			{ labels: ['nif'], value: `${ taxRegistrationNumber }`, type: 'string' }
		],
		[
			{ labels: ['business_name'], value: `${ businessName ? businessName : 'N/A' }`, type: 'string' }
		],
		[
			{ labels: ['version'], value: `${ auditFileVersion }`, type: 'number' }
		],
		[
			{ labels: ['fiscal_year'], value: `${ fiscalYear }`, type: 'number' }
		],
		[
			{ labels: ['start_date'], value: date(startDate), type: 'date' }
		],
		[
			{ labels: ['end_date'], value: date(endDate), type: 'date' }
		],
		[
			{ labels: ['gl_transactions'], value: format(glTransactionsCount, null, null), type: 'number' }
		],
		[
			{ labels: ['invoices'], value: format(invoicesCount, null, null), type: 'number' }
		],
		[
			{ labels: ['stock_movement'], value: format(stockMovementsCount, null, null), type: 'number' }
		],
		[
			{ labels: ['working_document'], value: format(workingDocumentCount, null, null), type: 'number' }
		],
		[
			{ labels: ['payments'], value: format(paymentsCount, null, null), type: 'number' }
		]
	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['NIF'], align: 'left' },
	{ label: ['Business'], align: 'left', columnName: "business_name", sortable: true },
	{ label: ['Version'], align: 'center' },
	{ label: ['Fiscal Year'], align: 'center' },
	{ label: ['Start'], align: 'center', columnName: "start_date", sortable: true },
	{ label: ['End'], align: 'center', columnName: "end_date", sortable: true },
	{ label: ['GL Transactions'], align: 'center' },
	{ label: ['Invoices'], align: 'center' },
	{ label: ['Stock Mov.'], align: 'center' },
	{ label: ['Working Docs'], align: 'center' },
	{ label: ['Payments'], align: 'center' },
]

export default {
	map: mapper,
	header
}