let _f
let _c
let _l
let _d

const formatter = ({ currency = 'EUR', locale = 'pt-PT', minimumFractionDigits = 2 }) => {
	if (currency !== _c || locale !== _l || minimumFractionDigits !== _d) {
		const options = currency ? {
			style: 'currency',
			currency: currency,
			minimumFractionDigits
		} : {}
		_f = new Intl.NumberFormat(locale, options)
	}
	_c = currency
	_l = locale
	_d = minimumFractionDigits
	return _f
}

const formatToParts = (value) => {
	const parts = value.split('')

	let currency = ''
	let number = ''
	if (isNaN(parts[0])) {
		for (let i = 0; i < parts.length; i ++) {
			const char = parts[i].trim()
			if (!char.trim().length || !isNaN(char)) {
				number = value.slice(i)
				break
			}
			currency = `${ currency }${ char }`
		}
		return [
			{
				type: 'currency',
				value: currency
			},
			{
				type: 'number',
				value: number
			}
		]
	} else {
		for (let i = parts.length - 1; i >= 0; i --) {
			const char = parts[i].trim()
			if (char.length && !isNaN(char)) {
				number = value.slice(0, i + 1)
				break
			}
			currency = `${ char }${ currency }`
		}
		return [
			{
				type: 'number',
				value: number
			},
			{
				type: 'currency',
				value: currency
			}
		]
	}
}

export function format(value, compact = 'normal', currency) {
	let number = Math.abs(Number(value)) || 0
	let suffix = ''
	let minimumFractionDigits = !value ? 0 : 2
	
	const compactBreakpoints = compact === 'short' ? [1e3, 1e6] : [1e6, 1e9] 
	if (compact && number >= compactBreakpoints[1]) {
		number = Math.round((number / 1e6) * 100) / 100
		suffix = 'M'
		minimumFractionDigits = 1
	} else if (compact && number >= compactBreakpoints[0]) {
		number = Math.round((number / 1e3) * 100) / 100
		suffix = 'K'
		minimumFractionDigits = 0
	}
	const currentFormatter = formatter({ minimumFractionDigits, currency })
	const parts = currentFormatter.formatToParts ? currentFormatter.formatToParts(number) : formatToParts(currentFormatter.format(number))
	let suffixed = false
	let n = parts.reduce((n, p) => {
		suffixed = n.length && p.type === 'currency'
		return suffixed ? `${ n.trim() }${ suffix } ${ p.value }` : `${ n }${ p.value }`
	}, '')
	if (value < 0) {
		n = `(${ n })`
	}
	return !suffixed && suffix.length ? `${ n }${ suffix }` : n
}