import { format, date } from "../../utils"

const mapDetails = (json) => {
	const {
		status,

		period,
		postDate,
		type,
		user,

		taxEntity,

		currencyAmount,
		currencyCode,
		exchangeRate,

		eacCode,
		unicCode,

		transactionId,

		statusReason,

		customer,
		number,
		taxPayable,
		grossTotal,
		netTotal,
		registryDate,
		statusUser,
		statusDate,
		shipFrom,
		shipTo
	} = json

	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Document Number', value: number, type: 'string' },
			{ label: 'Status', value: status, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Document Date', value: date(postDate), type: 'date' },
		],
		[
			{ label: 'Gross Total', value: format(grossTotal), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal), type: 'currency' },
			{ label: 'Tax Payable', value: format(taxPayable), type: 'currency' },

			{ label: 'Currency Amount', value: format(currencyAmount), type: 'currency' },
			{
				label: 'Exchange Rate',
				value: format(exchangeRate),
				type: 'currency'
			},
			{
				label: 'Currency Code',
				value: currencyCode || 'N/A',
				type: 'string'
			}
		],
		[
			{ label: 'Registry Date', value: date(registryDate), type: 'date' },
			{ label: 'User', value: user, type: 'string' },
			{ label: 'Status User', value: statusUser, type: 'string' },
			{ label: 'Status Date', value: date(statusDate), type: 'date' },
			{
				label: 'Status Reason',
				value: statusReason || 'N/A',
				type: 'string'
			},

			customer ? { label: 'Customer', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			{
				label: 'Transaction',
				value: transactionId || 'N/A',
				type: 'string'
			},

			{ label: "EAC Code", value: eacCode || 'N/A', type: 'string' },
			{
				label: "Document Unic Code",
				value: unicCode || 'N/A',
				type: 'string'
			},
			{
				label: "Tax Entity",
				value: taxEntity || 'N/A',
				type: 'string'
			},

			// chave
		],
		shipFrom ? {
			title: 'Ship From',
			rows: [
				[
					{ label: 'ID', value: shipFrom.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipFrom.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipFrom.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: date(shipFrom.deliveryDate) || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipFrom.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipFrom.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipFrom.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipFrom.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipFrom.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipFrom.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipFrom.address.country || 'N/A', type: 'string' },
				]
			]
		} : null,
		shipTo ? {
			title: 'Ship To',
			rows: [
				[
					{ label: 'ID', value: shipTo.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipTo.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipTo.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: shipTo.deliveryDate || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipTo.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipTo.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipTo.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipTo.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipTo.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipTo.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipTo.address.country || 'N/A', type: 'string' },
				]
			]
		} : null
	]
}

const mapCard = (json) => {
	const {
		number,

		numberOfLines,

		series,
		serialNumber,
		status,

		period,
		postDate,
		type,
		user,

		customer,

		taxPayable,
		grossTotal,
		netTotal
	} = json


	return [
		[
			{ labels: ['ref'], value: `${number}`, type: 'string' }
		],
		[
			{ labels: ['number'], value: `[${serialNumber}]`, type: 'number' }
		],
		[
			{ labels: ['number_of_lines'], value: `(${numberOfLines})`, type: 'number' }
		],
		[
			{ labels: ['series'], value: `${series}`, type: 'number' }
		],
		[
			{ labels: ['serial_number'], value: `${serialNumber}`, type: 'number' }
		],
		[
			{ labels: ['status'], value: `${status}`, type: 'number' }
		],
		[
			{ labels: ['user'], value: user, type: 'string', mobileHidden: true }
		],
		[
			{ labels: ['type'], value: type, type: 'number', mobileHidden: true }
		],
		customer && customer.id ? [
			{ labels: ['customer'], value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object', mobileHidden: true }
		] : [
			{ labels: ['customer'], value: 'N/A', type: 'string', mobileHidden: true }
		],
		[
			{ labels: ['period'], value: period, type: 'number' }
		],
		[
			{ labels: ['date'], value: date(postDate), type: 'date' }
		],
		[
			{ labels: ['tax'], value: format(taxPayable), type: 'currency', mobileHidden: true }
		],
		[
			{ labels: ['net'], value: format(netTotal), type: 'currency' }
		],
		[
			{ labels: ['gross'], value: format(grossTotal), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {

	return {
		...json,
		name: json.number,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Ref"], align: 'left' },
	{ label: ["[No]"], align: 'center', columnName: "number", sortable: true },
	{ label: ["(Lines)"], align: 'center' },
	{ label: ["Series"], align: 'center' },
	{ label: ["Serial Number"], align: 'center' },
	{ label: ["Status"], align: 'center' },
	{ label: ["User"], align: 'left', mobileHidden: true },
	{ label: ["Type"], align: 'center', mobileHidden: true },
	{ label: ["Customer"], align: 'left', mobileHidden: true, columnName: "customer__company_name", sortable: true },
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true },
	{ label: ["Date"], align: 'center' },
	{ label: ["Tax Payable"], align: 'right', mobileHidden: true, columnName: "document_totals_tax_payable", sortable: true },
	{ label: ["Net Total"], align: 'right', columnName: "document_totals_net_total", sortable: true },
	{ label: ["Gross Total"], align: 'right', columnName: "document_totals_gross_total", sortable: true }
]

export default {
	map: mapper,
	header
}