const header = [
    { label: ["Description"], align: 'left' },
    { label: ["ID"], align: 'left' },
    { label: ["Tax Entity"], align: 'left' },
    { label: ["Type"], align: 'left' },
    { label: ["STI Analysis ID"], align: 'left' }
]

const mapCard = (json) => {
	const {
        analysisID,
        taxEntity,
        analysisType,
        description,
        stiAnalysisID
	} = json

	return [
        [
            { labels: ['description'], value: description, type: 'string' }
        ],
        [
            { labels: ['analysis_id'], value: analysisID, type: 'string' }
        ],
        [
            { labels: ['tax_entity'], value: taxEntity, type: 'string' }
        ],
        [
            { labels: ['type'], value: analysisType, type: 'string' }
        ],
        [
            { labels: ['sti_analysis_id'], value: stiAnalysisID, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.analysisID
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}