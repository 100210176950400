import { date } from '../../utils'

const header = [
    { label: ["Procedure"], align: 'left' },
    { label: ["Authorization Date"], align: 'center' },
    { label: ["Authorization Number"], align: 'left' },
    { label: ["Authorization Code"], align: 'left' }
]

const mapCard = (json) => {
	const {
        customsProcedure,
        authorizationDate,
        authorizationNumber,
        authorizationGoodsNomenclatureCode
	} = json

	return [
        [
            { labels: ['procedure'], value: customsProcedure, type: 'string' }
        ],
        [
            { labels: ['authorization_date'], value: date(authorizationDate), type: 'date' }
        ],
        [
            { labels: ['authorization_number'], value: authorizationNumber, type: 'string' }
        ],
        [
            { labels: ['authorization_code'], value: authorizationGoodsNomenclatureCode, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.physical_stock_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}