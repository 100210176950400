import { date, format } from '../../../utils'


const mapCard = (json) => {
    const { invoiceDate,
        invoiceNumber,
        netTotal,
        taxPayable,
        grossTotal,
        taxAmount,
        vatType,
        period,
        glPostingDate
    } = json;

    return [
        [
            { labels: 'Invoice Number', value: invoiceNumber, type: 'string' }
        ],
        [
            { labels: 'Vat Type', value: vatType, type: 'string' }
        ],
        [
            { labels: 'Period', value: period.replace(/^0+/, ''), type: 'string' }
        ],
        [
            { labels: 'GL Posting Date', value: date(glPostingDate), type: 'date' }
        ],
        [
            { labels: 'Invoice Date', value: date(invoiceDate), type: 'date' }
        ],
        [
            { labels: 'Net Total', value: format(netTotal), type: 'currency' }
        ],
        [
            { labels: 'Tax Payable', value: format(taxPayable), type: 'currency' }
        ],
        [
            { labels: 'Gross Total', value: format(grossTotal), type: 'currency' }
        ],
        [
            { labels: 'Tax Amount', value: format(taxAmount), type: 'currency' }
        ],
        [
            { labels: [], value: ['delete'], type: 'user_actions' }
        ]
    ]
}

const mapper = (json, card) => {
    return {
        ...json,
        id: json.id,
        name: json.invoiceNumber,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ['Invoice Number'], align: 'left' },
    { label: ['Vat Type'], align: 'left' },
    { label: ['Period'], align: 'left' },
    { label: ['GL Posting Date'], align: 'center' },
    { label: ['Invoice Date'], align: 'center' },
    { label: ['Net Total'], align: 'right' },
    { label: ['Tax Payable'], align: 'right' },
    { label: ['Gross Total'], align: 'right' },
    { label: ['Tax Amount'], align: 'right' },
    { label: ['checkbox'], align: 'center' }
]

export default {
    map: mapper,
    header
}
