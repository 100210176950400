import { format, date } from '../../utils'

const mapDetails = (json) => {
	const {
		name,
        country,
        sharesTransfersDate,
        sharesAcquisition,
        sharesQuantity,
        sharesAmount,
        sharesType,
        generalLedgerAccountRowKey,
        accountId
	} = json
	return [
		[
            { label: 'Name', value: name, type: 'string' },
            { label: "Country", value: country, type: 'string' },
            { label: 'Shares Transfers Date', value: date(sharesTransfersDate), type: 'date'},
            { label: 'Shares Acquisition Date', value: date(sharesAcquisition), type: 'date'}
        ],
        [
            { label: 'Shares Quantity', value: format(sharesQuantity), type: 'currency'},
            { label: 'Shares Amount', value: format(sharesAmount), type: 'currency' }
        ],
        [
            { label: 'Shares Type', value: sharesType, type: 'string'},
            generalLedgerAccountRowKey ? 
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: accountId, __typename: 'GeneralLedger' }, type: 'object' } : 
                { label: 'Account', value: accountId || 'N/A', type: 'string' }
        ]
	]
}

const header = [
    { label: ["Name"], align: 'left' },
    { label: ["Owner ID"], align: 'left' },
    { label: ["Country"], align: 'left' },
    { label: ["Shares Acquisition Date"], align: 'center' },
    { label: ["Shares Transfers Date"], align: 'center' },
    { label: ["Shares Quantity"], align: 'center' },
    { label: ["Shares Amount"], align: 'right' }
]

const mapCard = (json) => {
	const {
        name,
        ownerId,
        country,
        sharesAcquisition,
        sharesTransfersDate,
        sharesQuantity,
        sharesAmount
	} = json

	return [
        [
            { labels: ['name'], value: name, type: 'string' }
        ],
        [
            { labels: ['owner_id'], value: ownerId, type: 'string' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ],
        [
            { labels: ['acquisition_date'], value: date(sharesAcquisition), type: 'date' }
        ],
        [
            { labels: ['transfer_date'], value: date(sharesTransfersDate), type: 'date' }
        ],
        [
            { labels: ['shares_quantity'], value: sharesQuantity, type: 'number' }
        ],
        [
            { labels: ['shares_amount'], value: format(sharesAmount), type: 'currency' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.name
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
        details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}