import App from '../controllers/App/App'
import { SectionTitle } from 'colbi_web_ui/lib/components'

const page = (path, navigation) => ({
	path,
	navigation,
	component: App,
	exact: true,
	components: [
		{
			component: SectionTitle,
			props: {
				title: 'label(404)',
				subtitle: 'label(Item not found)'
			}
		}
	]
})

export default page
