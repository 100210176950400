import React from 'react'
import { connect } from 'react-redux'

import { popModal, removeModal } from '../../../store/actions'
import ModalViewController from './ModalViewController'

import Modal from 'colbi_web_ui/lib/components/Modal/Modal'
import styles from './ModalController.module.sass'

const ModalController = ({ modals, pop, remove }) => {

	return modals && modals.length ? modals.map((modal, index) => {
		const { id, title, transition, view } = modal
		let windowClassName = styles[transition]
		if (modals.length > 1) {
			if (modals.length > 1 && index === modals.length - 2 && modals[modals.length - 1].$$state === 'leave') {
				windowClassName = styles['push-front']
			} else if (index < modals.length - 1) {
				windowClassName = styles['push-back']
			}
		}
		return (
			<Modal key={ id }>
				<div className={ styles['backdrop'] } />
				<div
					className={ `${ styles['modal-window'] } ${ windowClassName || '' }` }
					data-form-id={ id }
					onAnimationEnd={ (e) => {
						if (e.animationName === styles['leave']) {
							remove(e.target.dataset.formId)
						}
					} }
				>
					<ModalViewController id={ id } title={ title } view={ view } />
				</div>
			</Modal>
		)
	}) : null
}

export default connect(
	({ modals }) => (
		{
			modals
		}
	),
	{
		pop: popModal,
		remove: removeModal
	}
)(ModalController)
