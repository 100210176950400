import I18n from '../../I18n'
import { format, date } from '../../utils'

const mapDetails = (json) => {
	const {
		type,
		period,
		invoiceDate,
		taxPayable,
		grossTotal,
		netTotal,
		supplierId,
		supplierRowKey
	} = json
	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
			supplierRowKey ? { label: 'Supplier', value: { id: supplierRowKey, name: supplierId, __typename: 'Supplier' }, type: 'object' } : null,
		],
		[
			{ label: 'Gross Total', value: format(grossTotal), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal), type: 'currency' },
			{ label: 'Tax Payable', value: format(taxPayable), type: 'currency' }
		]
	]
}

const mapCard = (json) => {
	const {
		refNumber,
		type,
		invoiceDate,
		taxPayable,
		grossTotal,
		netTotal,
		supplierId,
		supplierRowKey
	} = json

	return [
		[ { labels: ['id'], value: refNumber, type: 'string' } ],
		[ { labels: ['type'], value: I18n.translate`${ type }`, type: 'number' } ],
		supplierRowKey ? [
			{
				labels: ['Supplier'],
				value: {
					id: supplierRowKey,
					name: supplierId,
					__typename: 'Supplier'
				},
				type: 'object',
				mobileHidden: true
			}
		] : [
			{ labels: ['Supplier'], value: 'N/A', type: 'string',  mobileHidden: true }
		],
		[ { labels: ['date'], value: date(invoiceDate), type: 'date' } ],
		[ { labels: ['tax'], value: format(taxPayable), type: 'currency', mobileHidden: true } ],
		[ { labels: ['net'], value: format(netTotal), type: 'currency' } ],
		[ { labels: ['gross'], value: format(grossTotal), type: 'currency' } ]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Identifier"], align: 'left' },
	{ label: ["Type"], align: 'center' },
	{ label: ["Supplier"], align: 'left' },
	{ label: ["Date"], align: 'center' },
	{ label: ["Tax Payable"], align: 'right', mobileHidden: true },
	{ label: ["Net Total"], align: 'right' },
	{ label: ["Gross Total"], align: 'right' }
]

export default {
	map: mapper,
	header
}