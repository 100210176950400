// export function stringToColor(str) {
// 	let hash = 0
// 	for (let i = 0; i < str.length; i++) {
// 		hash = str.charCodeAt(i) + ((hash << 5) - hash)
// 	}
// 	let colour = '#'
// 	for (let i = 0; i < 3; i++) {
// 		const value = (hash >> (i * 8)) & 0xFF
// 		colour += `00${ value.toString(16) }`.substr(-2)
// 	}
// 	return colour
// }



// import Color from 'color'

// function shuffleArray(array) {
//     for (let i = array.length - 1; i > 0; i--) {
//         const j = Math.floor(Math.random() * (i + 1));
//         [array[i], array[j]] = [array[j], array[i]];
//     }
// }

// const chartColors = () => {


// 	let a = []
	
// 	const b = Color('#4AC9B8')
// 	for (let i = 0; i < 60; i++) {
// 		a.push(b.rotate(8 * i).saturate(0.1).lighten(0.3).mix(Color('#4AC9B8'), 0.2).hex())
// 	}

// 	console.log(JSON.stringify(a))

// 	return (
// 		<div style={ { display: 'flex' } }>{ a.map((c, index) => (<div key={ index } style={ { width: '20px', height: '20px', background: c } } />)) }</div>
// 	)
// }

const COLORS = ['#55BECD', '#7FD5E1', '#A48BE7', '#FF8967', '#8BA2E6', '#FFB067', '#A48BE7', '#FF8967', '#7495C6', '#FFAC67', '#67BCBC', '#FF8467', '#F2849C', '#D675B9', '#A074C8','#D675B9','#FF8967','#FFA18B','#FF8467','#A074C8','#67BCBC', '#FFBF67', '#7A87CA', '#6BAFBF', '#FFA18B','#6BAFBF','#7A87CA','#A48BE7','#7495C6','#8BA2E6','#F2849C','#FFB067','#55BECD','#7FD5E1','#FFAC67', '#FFBF67']

export function stringToColor(str) {
	
    let hash = 0
	if (str.length === 0) { return COLORS[0] }
	const whiteSpaceIndex = str.length
	const picked = str.slice(0, whiteSpaceIndex)
    for (let i = 0; i < picked.length; i++) {
		hash = picked.charCodeAt(i) - 'a'.charCodeAt(0) + ((hash << 5) - hash)
        hash |= 0
	}
	hash = ((hash % COLORS.length) + COLORS.length) % COLORS.length
    return COLORS[hash]
}
