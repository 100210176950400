const removeError = (data) => {
    return (
        {
            type: 'REMOVE_ERROR',
            payload: {
                data
            }
        }
    )
}

export const removeVatReportAction = ({ fiscalYear, projectId, key, value }) => (
    (dispatch) => {
        console.log(key);
        console.log(value);
        const queriesBody = {
            query: `mutation($p:String!,$f:String!,$k:String!,$v:String!){remove_vat_report(projectId:$p,fiscalYear:$f,key:$k,value:$v)}`,
            variables: {
                p: projectId,
                f: fiscalYear,
                k: key,
                v: value
            }
        }

        return fetch('/graphql', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(queriesBody)
        })
            .then((res) => res.json().catch(() => ({})))
            .then((res) => {
                const data = res.data || {}
                if (data.remove_vat_report) {
                    window.location.reload();
                } else {
                    throw new Error('Unknown')
                }
            })
            .catch((e) => {
                dispatch(removeError('Unknown'))
            })

    }
)
