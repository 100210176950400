import I18n from '../../I18n'
import { format } from "../../utils"
import { buildAddressRow } from './mappersUtils'

const mapDetails = (json) => {
	const {
		taxId,
		taxEntity,
		accountId,
		phone,
		contact,
		customerId,
		companyName,
		selfBilling,
		kpiPercentageOnSales,
		kpiSalesTotalNet,
		kpiSalesDebitTotalNet,
		fax,
		email,
		website,
		billingAddress,
		shipAddresses
	} = json

	return [
		[
			{ label: 'Customer Id', value: customerId, type: 'string' },
			{ label: 'Account Id', value: accountId, type: 'string' },
			{ label: 'VAT - Entity', value: `${taxId} - ${taxEntity}`, type: 'string' },
			{ label: 'Company', value: companyName, type: 'string' },
			{ label: 'Self Billing', value: selfBilling, type: 'boolean' },
		],
		[
			{
				label: 'Percentage on sales',
				value: format((parseFloat(kpiPercentageOnSales) || 0).toFixed(2), 'normal', null),
				type: 'percentage',
				backgroundColor: '#2196F3',
				color: '#FFFFFF',
				icon: 'percentage'
			},
			{
				label: 'Sales Total (N)',
				value: format(kpiSalesTotalNet),
				type: 'currency',
				backgroundColor: '#F44336',
				color: '#FFFFFF',
				icon: 'sales-amount'
			},
			{
				label: 'Total Credit and Returns (N)',
				value: format(kpiSalesDebitTotalNet),
				type: 'currency',
				backgroundColor: '#74AF27',
				color: '#FFFFFF',
				icon: 'returns'
			}
		],
		{
			title: 'Contacts',
			rows: [
				[
					{ label: 'Contact', value: contact || 'N/A', type: 'string' },
					{ label: 'Phone', value: phone || 'N/A', type: 'string' },
					{ label: 'Fax', value: fax || 'N/A', type: 'string' },
					{ label: 'Email', value: email || 'N/A', type: 'string' },
					{ label: 'Website', value: website || 'N/A', type: 'string' },
				]
			]
		},
		{
			title: 'Billing Address',
			rows: [
				buildAddressRow(billingAddress)
			]
		},
		{
			title: 'Shipping Addresses',
			rows: (shipAddresses || []).map(address => buildAddressRow(address))
		}
	]
}

const mapCard = (json) => {
	const {
		companyName,
		taxId,
		taxEntity,
		taxIdCount,
		accountId,
		phone,
		contact
	} = json
	return [
		[
			{ labels: ['name'], value: companyName, type: 'string' }
		],
		[
			{ labels: ['id'], value: `${accountId}`, type: 'string' }
		],
		[
			{ labels: ['entity'], value: `${taxEntity}`, type: 'number' }
		],
		[
			{ labels: ['phone'], value: phone || 'N/A', type: 'number' }
		],
		[
			{ labels: ['contact'], value: contact || 'N/A', type: 'number' }
		],
		[
			{ labels: ['nif'], value: `${taxId}`, type: 'string' }
		],
		[
			{ labels: ['nif_count'], value: `(${taxIdCount})`, type: 'number' }
		]
	]
}

const mapReports = (reports) => {

	const [salesClientPeriod, top, topProductsMovements] = reports

	const salesData = []

	for (let i = 0; i < 12; i++) {
		salesData.push({
			period: i + 1,
			value: ((salesClientPeriod || []).find(({ period }) => period === i + 1) || {}).value || 0
		})
	}

	const topsTabs = []
	const topsData = []
	if ((top || []).length) {
		topsTabs.push(I18n.translate('Top'))
		topsData.push(
			top.map((json) => ({
				...json,
				__typename: 'Product',
				percentage: Math.round(((json.value / json.total) * 100) * 100) / 100
			}))
		)
		topsData.push(
			topProductsMovements.map((json) => ({
				...json,
				__typename: 'Product',
				percentage: Math.round(((json.value / json.total) * 100) * 100) / 100
			}))
		)
	}
	if ((topProductsMovements || []).length) {
		topsTabs.push('Movements')
	}

	return (salesClientPeriod || []).length || (top || []).length || (topProductsMovements || []).length ? [
		{
			type: 'bar',
			icon: 'comparation',
			title: I18n.translate('Sales (Net)'),
			colspan: 2,
			data: [salesData],
			links: [
				{
					filterColumn: 'period'
				}
			],
			currency: true

		},
		{
			type: 'tops',
			title: I18n.translate('Products (N)'),
			tabs: topsTabs,
			colspan: 1,
			data: topsData
		},
	] : null
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.companyName,
		shortName: json.companyName,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		reports: mapReports(json.reports || [])
	}
}

const header = [
	{ label: ['Name'], align: 'left', columnName: "company_name", sortable: true },
	{ label: ['Account ID'], align: 'left' },
	{ label: ['Tax Entity'], align: 'center', columnName: "tax_entity", sortable: true },
	{ label: ['Phone'], align: 'center' },
	{ label: ['Contact'], align: 'center' },
	{ label: ['VAT'], align: 'left' },
	{ label: ['(Count)'], align: 'center', columnName: "customer_tax_id_dup", sortable: true }
]

export default {
	map: mapper,
	header
}