import I18n from '../../I18n'
import { datetime, date } from '../../utils'

const mapCard = (json) => {
	const {
		name,
		status,
		auditStatus,
		uploadedAt,
		startDate,
		endDate,
		submitedBy,
	} = json

	return [
		[
			{ labels: ['name'], value: name, type: 'string', align: 'align-left', basis: '30%' }
		],
		[
			{ labels: ['user'], value: submitedBy, type: 'string', mobileHidden: true }
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', mobileHidden: true }
		],
		[
			{ labels: ['status'], value: [auditStatus, I18n.translate`${auditStatus}`, 'center', status], type: 'status' }
		],
		[
			{ labels: ['start'], value: date(startDate), type: 'datetime', mobileHidden: true }
		],
		[
			{ labels: ['end'], value: date(endDate), type: 'datetime', mobileHidden: true }
		],
		[
			{ labels: [], value: ['delete'], type: 'user_actions' }
		]

	]
}

const mapper = (json, card) => {
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: `/:locale(pt|en)?/:projectId/repository/folders/${json.fiscalYear}/${json.id}`
		}
	}
}

const header = [
	{ label: ['Name'], align: 'left', basis: '29.3%', columnName: "name", sortable: true },
	{ label: ['Uploaded by'], align: 'left', mobileHidden: true },
	{ label: ['Uploaded at'], align: 'center', mobileHidden: true, columnName: "uploaded_at", sortable: true },
	{ label: ['Status'], align: 'center' },
	{ label: ["Start Date"], align: 'center', mobileHidden: true },
	{ label: ["End Date"], align: 'center', mobileHidden: true },
	{ label: ["checkbox"], align: 'center', width: 'actions-width' }
]

export default {
	map: mapper,
	header
}