import { format } from "../../../utils"

const mapDetails = (json) => {
    const {
        customerId,
        taxRegistrationNumber,
        taxRegistrationNumber1,
        name,
        streetName,
        city,
        postalCode,
        region,
        country,
        telephone,
        fax,
        accountId,
        openBalance
    } = json

    return [
        [
            { label: 'Customer ID', value: customerId || 'N/A', type: 'string' },
            { label: 'Account ID', value: accountId || 'N/A', type: 'string' },
            { label: 'Tax Registration Number', value: taxRegistrationNumber || 'N/A', type: 'string' },
            { label: 'Tax Registration Number 1', value: taxRegistrationNumber1 || 'N/A', type: 'string' },
            { label: 'Name', value: name || 'N/A', type: 'string' }
        ],
        [
            { label: 'Street Name', value: streetName || 'N/A', type: 'string' },
            { label: 'City', value: city || 'N/A', type: 'string' },
            { label: 'Postal Code', value: postalCode || 'N/A', type: 'string' },
            { label: 'Region', value: region || 'N/A', type: 'string' },
            { label: 'Country', value: country || 'N/A', type: 'string' }
        ],
        [
            { label: 'Telephone', value: telephone || 'N/A', type: 'string' },
            { label: 'Fax', value: fax || 'N/A', type: 'string' },
            { label: 'Open Balance', value: format(openBalance), type: 'currency' }
        ]
    ]
}

const mapCard = (json) => {
    const {
        customerId,
        name,
        accountId,
        taxRegistrationNumber,
        openBalance
    } = json

    return [
        [
            { labels: 'Customer ID', value: customerId, type: 'string' }
        ],
        [
            { labels: 'Customer Name', value: name, type: 'string' }
        ],
        [
            { labels: 'Account ID', value: accountId, type: 'string' }
        ],
        [
            { labels: 'Tax Registration Number', value: taxRegistrationNumber, type: 'string' }
        ],
        [
            { labels: 'Open Balance', value: format(openBalance), type: 'currency' }
        ]
    ]
}

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.name,
        shortName: json.name,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : [],
    }
}

const header = [
    { label: ['Customer ID'], align: 'left', columnName: 'customer_id', sortable: true },
    { label: ['Customer Name'], align: 'left' },
    { label: ['Account ID'], align: 'left', columnName: 'account_id', sortable: true },
    { label: ['Tax Registration Number'], align: 'left', columnName: 'tax_registration_number', sortable: true },
    { label: ['Open Balance'], align: 'right' }
]

export default {
    map: mapper,
    header
}