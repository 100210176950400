import React from 'react'
import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import styles from './FileDetail.module.sass'
import { NavLink } from 'react-router-dom'
import { date, hydrateRoute, stringifyFilter } from 'colbi_web_ui/lib/utils'
import Link from 'colbi_web_ui/lib/components/Link/Link'

const FileDetail = (props) => {

    const {
        modifiers,
        data
    } = props

    const { i18n, params } = useGlobalValue()
    const { locale, projectId, section, fiscalYear } = params

    const renderActivityDetails = () => (
        <>
            <h5>{data.name}</h5>
            <h6>{`${data.type} ${data.version} ${i18n`Document by`} ${data.uploadedByName} ${i18n`at`} ${date(data.uploadedAt)}`}</h6>
            <div className={styles['list-header']}>
                <span>{i18n`Information`}</span>
            </div>
            <ul>
                <li>
                    <small>Document Type</small>
                    <p>{data.type}</p>
                </li>
                <li>
                    <small>Document Version</small>
                    <p>{data.version}</p>
                </li>
            </ul>

            <div>
                <span>{i18n`Processing State`}</span>
            </div>
            <p>{data.findingMessage}</p>
        </>
    )

    const renderFoldersDetails = () => (
        <>
            <h5>{data.name}</h5>
            <h6>{`${data.type} ${data.version} ${i18n`Document by`} ${data.uploadedByName} ${i18n`at`} ${date(data.uploadedAt)}`}</h6>

            <div>
                <span>{i18n`Information`}</span>
            </div>
            <ul>
                <li>
                    <small>Document Type</small>
                    <p>{data.type}</p>
                </li>
                <li>
                    <small>Document Version</small>
                    <p>{data.version}</p>
                </li>
            </ul>
            <div className={styles['list-header']}>
                <span>{i18n`Content`}</span>
                <span>{i18n`Count`}</span>
            </div>
            <ul className={styles['list-headers-summary']}>
                <li>
                    <small>{i18n`generalLedgerEntries`}</small>
                    <p>{data.generalLedgerEntries}</p>
                </li>
                <li>
                    <small>{i18n`invoiceEntries`}</small>
                    <p>{data.invoiceEntries}</p>
                </li>
                <li>
                    <small>{i18n`paymentEntries`}</small>
                    <p>{data.paymentEntries}</p>
                </li>
                <li>
                    <small>{i18n`stockMovementEntries`}</small>
                    <p>{data.stockMovementEntries}</p>
                </li>
                <li>
                    <small>{i18n`workingDocumentEntries`}</small>
                    <p>{data.workingDocumentEntries}</p>
                </li>
            </ul>
            <div className={styles['list-header']}>
                <span>{i18n`Processing State`}</span>
                <span>{i18n`Count`}</span>
            </div>
            <ul className={styles['list-severity-summary']}>
                {
                    data.totalFindings
                        .sort((a, b) => b.severity.localeCompare(a.severity))
                        .map(({ severity, count }) => (
                            <li key={severity}>
                                <small>{i18n`${severity}`}</small>
                                <p>{count}</p>
                            </li>
                        ))
                }
            </ul>
        </>
    )

    const modifiersClasses = (modifiers || []).map(m => styles[m]).filter(m => m).join(' ')

    return (
        <div className={`${styles['detail']} ${modifiersClasses}`}>
            {(data && data.totalFindings && data.totalFindings.length > 0) &&
                <div className={styles['wrap-buttons']}>
                    <Link
                        to={
                            hydrateRoute(`../../../${fiscalYear}/audit/?filter=${
                                stringifyFilter(
                                    [{ column: 'name', filters: [{ value: data.id + ' - ' + data.name }] }]
                                )
                                }&resetFilters=${Date.now()}`, {})}
                        className={styles['close']}
                        label={i18n`See Content`} />
                    <NavLink to={`/${locale}/${projectId}/repository/${section}/${fiscalYear}`} className={styles['close']}>
                        {i18n`X Close`}
                    </NavLink>
                </div>
            }

            {((data && !data.totalFindings) || (data && data.totalFindings && !data.totalFindings.length)) &&
                <NavLink to={`/${locale}/${projectId}/repository/${section}/${fiscalYear}`} className={styles['close']}>
                    {i18n`Close X`}
                </NavLink>
            }
            <div className={`${styles['content']} ${styles[section]}`}>{data && (data.__typename === 'ActivityDetail' ? renderActivityDetails() : renderFoldersDetails())}</div>
        </div>
    )
}

export default FileDetail