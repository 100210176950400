function getInitialState() {
	try {
		const session = window.localStorage.getItem("session")
		return (session && JSON.parse(session)) || null
	} catch (e) {
		console.error("100 - Local storage")
		return null
	}
}

const user = (state = getInitialState(), action) => {
	switch (action.type) {
		case 'LOGIN_SUCCESS':
			try {
				window.localStorage.setItem("session", JSON.stringify(action.payload.data))
			} catch (e) {
				console.error("100 - Local storage")
			}
			return action.payload.data
		case 'LOGIN_ERROR':
		case 'LOGOUT_SUCCESS':
			return null
		default: break
	}
	return state
}

const loginError = (state = null, action) => {
	switch (action.type) {
		case 'LOGIN_SUCCESS':
			return null
		case 'LOGIN_ERROR':
			return action.payload.data || 'ERROR'
		default: break
	}
	return state
}

//RESET PASSWORD
const resetPasswordError = (state = null, action) => {
	switch (action.type) {
		case 'RESET_SUCCESS':
			return null

		case 'RESET_ERROR':
			return action.payload.msg

		default: break
	}
	return state
}

export {
	user,
	loginError,
	resetPasswordError
}
