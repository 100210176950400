import I18n from '../../I18n'
import { format, date } from '../../utils'

const mapDetails = (json) => {
	const {
		type,
		user,
		status,
		period,
		transactionDate,
		customer,
		taxPayable,
		grossTotal,
		netTotal,

		registryDate,
		statusUser,
		statusDate,
		transaction,
		shipFrom,
		shipTo
	} = json

	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Status', value: status, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Transaction Date', value: date(transactionDate), type: 'date' },
			{ label: 'Registry Date', value: date(registryDate), type: 'date' },
		],
		[
			{ label: 'Gross Total', value: format(grossTotal), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal), type: 'currency' },
			{ label: 'Tax Payable', value: format(taxPayable), type: 'currency' }
		],
		[
			{ label: 'User', value: user, type: 'string' },
			{ label: 'Status User', value: statusUser, type: 'string' },
			{ label: 'Status Date', value: date(statusDate), type: 'date' },
			customer ? { label: 'Customer', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			transaction ? { label: 'Transaction', value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object' } : null
		],
		shipFrom ? {
			title: 'Ship From',
			rows: [
				[
					{ label: 'ID', value: shipFrom.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipFrom.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipFrom.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: date(shipFrom.deliveryDate) || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipFrom.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipFrom.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipFrom.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipFrom.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipFrom.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipFrom.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipFrom.address.country || 'N/A', type: 'string' },
				]
			]
		} : null,
		shipTo ? {
			title: 'Ship To',
			rows: [
				[
					{ label: 'ID', value: shipTo.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipTo.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipTo.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: shipTo.deliveryDate || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipTo.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipTo.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipTo.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipTo.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipTo.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipTo.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipTo.address.country || 'N/A', type: 'string' },
				]
			]
		} : null
	]
}

const mapCard = (json) => {
	const {
		number,
		refNumber,
		numberOfLines,
		type,
		status,
		period,
		transactionDate,
		customer,
		taxPayable,
		grossTotal,
		netTotal,
		settlementAmount
	} = json

	return [
		[
			{ labels: ['id'], value: I18n.translate`${refNumber}`, type: 'string' },
		],
		[
			{ labels: ['number'], value: I18n.translate`[${number}]`, type: 'number' }
		],
		[
			{ labels: ['lines'], value: I18n.translate`(${numberOfLines} lines)`, type: 'number' }
		],
		[
			{ labels: ['type'], value: I18n.translate`${type}`, type: 'number' }
		],
		[
			{ labels: ['status'], value: I18n.translate`(${status})`, type: 'number' }
		],
		customer && customer.id ? [
			{
				labels: ['customer'],
				value: {
					id: customer.id,
					name: customer.name,
					__typename: 'Customer'
				},
				type: 'object',
				mobileHidden: true
			}
		] : [
				{ labels: ['customer'], value: 'N/A', type: 'string', mobileHidden: true }
			],
		[
			{ labels: ['period'], value: period, type: 'number' }
		],
		[
			{ labels: ['date'], value: date(transactionDate), type: 'date' }
		],
		[
			{ labels: ['settlement_amount'], value: format(settlementAmount), type: 'currency', mobileHidden: true }
		],
		[
			{ labels: ['tax'], value: format(taxPayable), type: 'currency', mobileHidden: true }
		],
		[
			{ labels: ['net'], value: format(netTotal), type: 'currency' }
		],
		[
			{ labels: ['gross'], value: format(grossTotal), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["ID"], align: 'left', columnName: "payment_ref_no", sortable: true },
	{ label: ["[No]"], align: 'center' },
	{ label: ["(Lines)"], align: 'center' },
	{ label: ["Type"], align: 'center' },
	{ label: ["(Status)"], align: 'center' },
	{ label: ["Customer"], align: 'left', mobileHidden: true },
	{ label: ["Period"], align: 'center', columnName: "period_raw", sortable: true },
	{ label: ["Date"], align: 'center' },
	{ label: ["Settlement Amount"], align: 'right', mobileHidden: true, columnName: "document_totals_settlement_amount", sortable: true },
	{ label: ["Tax Payable"], align: 'right', mobileHidden: true, columnName: "document_totals_tax_payable", sortable: true },
	{ label: ["Net Total"], align: 'right', columnName: "document_totals_net_total", sortable: true },
	{ label: ["Gross Total"], align: 'right', columnName: "document_totals_gross_total", sortable: true }
]

export default {
	map: mapper,
	header
}