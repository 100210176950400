import { format } from '../../utils'

const header = [
    { label: ["Characteristic"], align: 'left' },
    { label: ["Value"], align: 'right' }
]

const mapCard = (json) => {
	const {
        characteristic,
        characteristicValue
	} = json

	return [
        [
            { labels: ['characteristic'], value: characteristic, type: 'string' }
        ],
        [
            { labels: ['value'], value: format(characteristicValue), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.physicalStockId
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}