import { format, date } from '../../utils'

const mapDetails = (json) => {
	const {
		lineNumber,
        invoiceType,
        quantity,
        invoiceUOM,
        debitCreditIndicator,
        debitAmount,
        creditAmount,
        invoiceLineAmount,
        invoiceLineCurrencyAmount,
        shippingCostsAmount,
        shippingCostsCurrencyAmount,
        invoiceDate,
        taxPointDate,
        description,
        generalLedgerAccountRowKey,
        accountId,
        productRowKey,
        productCode
	} = json
	return [
		[
            { label: 'Line Number', value: lineNumber, type: 'string' },
            { label: 'Type', value: invoiceType, type: 'string' },
            { label: 'Quantity', value: quantity, type: 'number' },
            { label: 'UOM', value: invoiceUOM, type: 'string' },
            { label: 'Debit Credit Indicator', value: debitCreditIndicator, type: 'string' }
        ],
        [
            { label: 'Debit Amount', value: format(debitAmount), type: 'currency' },
            { label: 'Credit Amount', value: format(creditAmount), type: 'currency' },
            { label: 'Amount', value: format(invoiceLineAmount), type: 'currency' },
            { label: 'Currency Amount', value: format(invoiceLineCurrencyAmount), type: 'currency' },
            { label: 'Shipping Costs Amount', value: format(shippingCostsAmount), type: 'currency' },
            { label: 'Shipping Costs Currency Amount', value: format(shippingCostsCurrencyAmount), type: 'currency' }
        ],
        [
            { label: 'Date', value: date(invoiceDate), type: 'date' },
            { label: 'Tax Point Date', value: date(taxPointDate), type: 'date' },
            generalLedgerAccountRowKey ? 
                { label: 'Account', value: { id: generalLedgerAccountRowKey, name: accountId, __typename: 'GeneralLedger' }, type: 'object' } : 
                { label: 'Account', value: accountId || 'N/A', type: 'string' },
            productRowKey ? 
                { label: 'Product', value: { id: productRowKey, name: productCode, __typename: 'Product' }, type: 'object' } : 
                { label: 'Product', value: productCode || 'N/A', type: 'string' },
            { label: 'Description', value: description, type: 'string' }
        ]
	]
}

const header = [
    { label: ["Description"], align: 'left' },
    { label: ["[Line]"], align: 'left' },
    { label: ["Quantity"], align: 'center' },
    { label: ["UOM"], align: 'center' },
    { label: ["Unit Price"], align: 'center' },
    { label: ["Tax Amount"], align: 'right' },
    { label: ["Shipping Costs Amount"], align: 'right' },
    { label: ["Invoice Line Amount"], align: 'right' }
]

const mapCard = (json) => {
	const {
        description,
        lineNumber,
        quantity,
        invoiceUOM,
        unitPrice,
        taxAmount,
        shippingCostsAmount,
        invoiceLineAmount
	} = json

	return [
        [
            { labels: ['description'], value: description || 'N/A', type: 'string' }
        ],
        [
            { labels: ['line'], value: lineNumber || 'N/A', type: 'string' }
        ],
        [
            { labels: ['quantity'], value: quantity || 'N/A', type: 'number' }
        ],
        [
            { labels: ['uom'], value: invoiceUOM || 'N/A', type: 'number' }
        ],
        [
            { labels: ['unitprice'], value: unitPrice || 'N/A', type: 'number' }
        ],
        [
            { labels: ['tax'], value: taxAmount, type: 'currency' }
        ],
        [
            { labels: ['shipping'], value: shippingCostsAmount, type: 'currency' }
        ],
        [
            { labels: ['amount'], value: invoiceLineAmount, type: 'currency' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.description
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
        details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}