import App from '../controllers/App/App'
import { List, rows, Filters } from 'colbi_web_ui/lib/components'
import theme from 'colbi_web_ui/lib/style/_theme.json';
import { parseFilter } from 'colbi_web_ui/lib/utils'

const queries = (args = {}) => {
	return ([
		{
			resource: 'audit_execution_summary',
			args: {
				projectId: (args.projectId),
				page: parseInt(args.page, 10) || 0,
				sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined,
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
			},
			body: `__typename, items{ ...audit_execution_summary }, hasMore, total, filters(projectId:$projectId){ prop, name, type, list }`,
			downloadable: true
		}
	])
}

const auditSumary = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: App,
	queries,
	exact,
	title: [title],
	components: [
		{
			component: Filters,
			props: {
				filter: 'param(filter)',
				availableFilters: 'query(0).filters',
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: 'results'
			}
		},
		{
			component: List,
			modifiers: [
				'--root-margin-top-small',
				'--row-style-alternate'
			],
			props: {
				items: 'query(0).list',
				cardKey: 'param(id)',
				card: rows.EntityCard,
				sort: 'param(sort)',
				metadata: 'query(0).metadata',
				page: 'param(page)',
				status: 'status(0)',
				pageSize: 'param(pageSize)',
				pageDefault: 10,
				footer: 'query(0).footer',
				header: 'query(0).header'
			}
		}
	]
})

export default auditSumary
