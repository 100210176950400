import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { popModal, fetchModalData, updateModalData, saveModalData } from '../../../store/actions'

import { ActionButton } from 'colbi_web_ui/lib/components/inputs'
import { views } from 'colbi_web_ui/lib/components'
import { FORM_ACTIONS } from 'colbi_web_ui/lib/components/views/FormActions'
import Icon from 'colbi_web_ui/lib/components/Icon'

import { useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'

import styles from './ModalViewController.module.sass'

const ModalViewController = (props) => {
	const {
		id,
		view,
		title,
		data,
		args,
		status,
		errors,
		pop,
		fetchData,
		updateData,
		saveData
	} = props

	const { i18n } = useGlobalValue()
	const View = views[view]
	const form = useRef(null)
	const actions = (View.actions || []).map((action) => {
		if (action === FORM_ACTIONS.SAVE || action === FORM_ACTIONS.SUBMIT) {
			return (
				<ActionButton primary key="action-save" type="submit"
					onClick={() => {
						if (form.current) {
							form.current.submit()
						}
						// saveData(id)
					}}
				><small>{action === FORM_ACTIONS.SAVE ? i18n`Save` : i18n`Submit`}</small></ActionButton>
			)
		}
		if (action === FORM_ACTIONS.DELETE) {
			return (
				<ActionButton danger key="action-delete">{<small>{i18n`Delete`}</small>}</ActionButton>
			)
		}
		return null
	})


	useEffect(() => {
		if (View && status === 'initial') {
			const queries = (View.queries && View.queries(args || {})) || []
			const mapper = View.dataMapper || []

			if (queries.length && mapper.length) {
				fetchData({ id, queries, mapper })
			} else {
				updateData({ id, data })
			}
		}
	}, [id, data, View, args, fetchData, updateData, status])

	return (
		<>
			<header className={styles['header']}>
				<div>
					<button className={styles['close-button']} onClick={() => { pop(id) }}>
						<Icon name="close" />
					</button>
				</div>
				<div>
					<h5>{title}</h5>
				</div>
				<div className={styles['actions']}>
					{actions}
				</div>
			</header>
			<main className={styles['main']}>
				<div className={styles['errors-container']}>
					{(errors || []).map(({ message }) => (
						<div className={styles['error']} key={message}>
							<small>{message}</small>
						</div>
					))}
				</div>
				{View && <View
					{...(data || {})}
					ref={form}
					status={status}
					onChange={(e) => {
						updateData({
							id,
							data: e.target.value
						})
					}}
					onSave={() => {
						saveData(id)
					}}
				/>}
			</main>
		</>
	)
}

/*


	<div className={ styles['modal-header'] }>
		<button className={ styles['close-button'] } onClick={ () => { popModal(id) } }><Icon name="remove" /></button>
	</div>
	<View data={ data } />

*/


const mapStateToProps = (state, ownProps) => {
	return {
		args: (state.modalsData[ownProps.id] || {}).args,
		data: (state.modalsData[ownProps.id] || {}).data,
		status: (state.modalsData[ownProps.id] || {}).status,
		errors: (state.modalsData[ownProps.id] || {}).errors
	}
}

const mapDispatchToProps = {
	pop: popModal,
	fetchData: fetchModalData,
	updateData: updateModalData,
	saveData: saveModalData
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModalViewController)
