const uploads = (state = {open: true}, action) => {
	switch (action.type) {
		case 'OPEN': {
			if (action.payload.data) {
				return { open: action.payload.data }
			}
			return {}
		}
		case 'UPLOAD_FILE_START': {
			const result = (action.payload.files || []).map((f) => ({ file: f.name, progress: 'Zipping file' }))

			return { ...state, list: result }
		}
		case 'UPLOAD_FILE_PROGRESS': {
			const result = JSON.parse(JSON.stringify(state)).list
			const fileIndex = result.findIndex((f) => (f.file === action.payload.file))
			if (fileIndex >= 0) {
				result[fileIndex].progress = action.payload.progress
			}
			return { ...state, list: result }
		}
		case 'UPLOAD_FILE_COMPLETE': {
			const result = JSON.parse(JSON.stringify(state)).list
			const fileIndex = result.findIndex((f) => (f.file === action.payload.file))
			if (fileIndex >= 0) {
				result[fileIndex].progress = action.payload.progress
			}
			return { ...state, list: result }
		}
		case 'UPLOAD_FILE_FAILED': {
			const result = JSON.parse(JSON.stringify(state)).list
			const fileIndex = result.findIndex((f) => (f.file === action.payload.file))
			if (fileIndex >= 0) {
				result[fileIndex].progress = -1
				result[fileIndex].error = action.payload.message
			}
			return { ...state, list: result }
		}
		case 'DELETE_ERROR': {
			return 'ERROR'
		}
		default: break
	}
	return state
}


export default uploads