import accountMapper from './accountMapper'
import acquisitionMapper from './acquisitionMapper'
import addressMapper from './addressMapper'
import analyseMapper from './analyseMapper'
import analysistypeMapper from './analysistypeMapper'
import assetMapper from './assetMapper'
import assetsupplierMapper from './assetsupplierMapper'
import assettransactionMapper from './assettransactionMapper'
import assettransactionsvaluationMapper from './assettransactionsvaluationMapper'
import assetvaluationappreciationMapper from './assetvaluationappreciationMapper'
import assetvaluationextraordinarydepreciationMapper from './assetvaluationextraordinarydepreciationMapper'
import assetvaluationimpairmentMapper from './assetvaluationimpairmentMapper'
import assetvaluationMapper from './assetvaluationMapper'
import auditexecutionsummaryMapper from './auditexecutionsummaryMapper'
import auditrecordMapper from './auditrecordMapper'
import balanceMapper from './balanceMapper'
import bankaccountMapper from './bankaccountMapper'
import characteristicMapper from './characteristicMapper'
import companyrepositoryMapper from './companyrepositoryMapper'
import contactMapper from './contactMapper'
import creditnoteMapper from './creditnoteMapper'
import customerMapper from './customerMapper'
import customsprocedureMapper from './customsprocedureMapper'
import activityMapper from './activityMapper'
import datafileMapper from './datafileMapper'
import findingMapper from './findingMapper'
import findinglinesMapper from './findinglinesMapper'
import findingsummaryMapper from './findingsummaryMapper'
import generalledgerMapper from './generalledgerMapper'
import headerMapper from './headerMapper'
import invoicelineMapper from './invoicelineMapper'
import invoiceMapper from './invoiceMapper'
import journalMapper from './journalMapper'
import movementtypeMapper from './movementtypeMapper'
import openinvoiceMapper from './openinvoiceMapper'
import orderreferenceMapper from './orderreferenceMapper'
import ownerMapper from './ownerMapper'
import paymentlineMapper from './paymentlineMapper'
import paymentMapper from './paymentMapper'
import physicalstockMapper from './physicalstockMapper'
import productMapper from './productMapper'
import profitandlossMapper from './profitandlossMapper'
import projectMapper from './projectMapper'
import purchaseinvoiceLineMapper from './purchaseinvoiceLineMapper'
import purchaseinvoiceMapper from './purchaseinvoiceMapper'
import ruleMapper from './ruleMapper'
import settlementMapper from './settlementMapper'
import shipfromMapper from './shipfromMapper'
import shiptoMapper from './shiptoMapper'
import stockmovementlineMapper from './stockmovementlineMapper'
import stockmovementMapper from './stockmovementMapper'
import supplierMapper from './supplierMapper'
import taxinformationMapper from './taxinformationMapper'
import taxMapper from './taxMapper'
import taxregistrationMapper from './taxregistrationMapper'
import taxtableentrydetailMapper from './taxtableentrydetailMapper'
import taxtableMapper from './taxtableMapper'
import transactionlineMapper from './transactionlineMapper'
import transactionMapper from './transactionMapper'
import trialbalanceMapper from './trialbalanceMapper'
import trialbalancetaxonomyMapper from './trialbalancetaxonomyMapper'
import uomMapper from './uomMapper'
import uomtableentryMapper from './uomtableentryMapper'
import userMapper from './userMapper'
import workdocumentlineMapper from './workdocumentlineMapper'
import workdocumentMapper from './workdocumentMapper'

//ERP 
import erpcustomerMapper from './erp/customerMapper';
import erpsupplierMapper from './erp/supplierMapper';
import erpsaleinvoiceMapper from './erp/saleinvoiceMapper';
import erppurchaseinvoiceMapper from './erp/purchaseinvoiceMapper';
import vatdataMapper from './vat-report/vatdataMapper';

import vatreportMapper from './vat-report/vatreportMapper';

export {
	accountMapper,
	acquisitionMapper,
	addressMapper,
	analyseMapper,
	analysistypeMapper,
	assetMapper,
	assetsupplierMapper,
	assettransactionMapper,
	assettransactionsvaluationMapper,
	assetvaluationappreciationMapper,
	assetvaluationextraordinarydepreciationMapper,
	assetvaluationimpairmentMapper,
	assetvaluationMapper,
	auditexecutionsummaryMapper,
	auditrecordMapper,
	balanceMapper,
	bankaccountMapper,
	characteristicMapper,
	companyrepositoryMapper,
	contactMapper,
	creditnoteMapper,
	customerMapper,
	customsprocedureMapper,
	activityMapper,
	datafileMapper,
	findingMapper,
	findinglinesMapper,
	findingsummaryMapper,
	generalledgerMapper,
	headerMapper,
	invoicelineMapper,
	invoiceMapper,
	journalMapper,
	movementtypeMapper,
	openinvoiceMapper,
	orderreferenceMapper,
	ownerMapper,
	paymentlineMapper,
	paymentMapper,
	physicalstockMapper,
	productMapper,
	profitandlossMapper,
	projectMapper,
	purchaseinvoiceLineMapper,
	purchaseinvoiceMapper,
	ruleMapper,
	settlementMapper,
	shipfromMapper,
	shiptoMapper,
	stockmovementlineMapper,
	stockmovementMapper,
	supplierMapper,
	taxinformationMapper,
	taxMapper,
	taxregistrationMapper,
	taxtableentrydetailMapper,
	taxtableMapper,
	transactionlineMapper,
	transactionMapper,
	trialbalanceMapper,
	trialbalancetaxonomyMapper,
	uomMapper,
	uomtableentryMapper,
	userMapper,
	workdocumentlineMapper,
	workdocumentMapper,
	erpcustomerMapper,
	erpsupplierMapper,
	erpsaleinvoiceMapper,
	erppurchaseinvoiceMapper,
	vatdataMapper,
	vatreportMapper
}