const header = [
    { label: ["[Type]"], align: 'left' },
    { label: ["Street - Number"], align: 'left' },
    { label: ["Full Address"], align: 'left' },
    { label: ["Postal Code"], align: 'left' },
    { label: ["City"], align: 'left' },
    { label: ["Country"], align: 'left' },
    { label: ["Region"], align: 'left' },
]

const mapCard = (json) => {
	const {
        streetName,
        number,
        city,
        postalCode,
        country,
        addressType,
        region,
        fullAddress
	} = json

	return [
        [
            { labels: ['type'], value: addressType || 'N/A', type: 'string' },
        ],
		[
            { labels: ['street', 'number'], template: '{0} - {1}', values: [streetName || 'N/A', number || 'N/A'], type: 'string' }
        ],
        [
            { labels: ['fullAddress'], value: fullAddress, type: 'string' }
        ],
        [
            { labels: ['postalCode'], value: postalCode, type: 'string' }
        ],
        [
            { labels: ['city'], value: city, type: 'string' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ],
        [
            { labels: ['region'], values: [region || 'N/A'], type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}