import { buildAddressRow } from './mappersUtils'

const mapDetails = (json) => {
	const {
		taxId,
		taxEntity,
		accountId,
		phone,
		contact,
		supplierId,
		companyName,
		selfBilling,

		fax,
		email,
		website,
		billingAddress,
		shipAddresses
	} = json

	return [
		[
			{ label: 'Supplier Id', value: supplierId, type: 'string' },
			{ label: 'Account Id', value: accountId, type: 'string' },
			{ label: 'VAT - Entity', value: `${ taxId } - ${ taxEntity }`, type: 'string' },
			{ label: 'Company', value: companyName, type: 'string' },
			{ label: 'Self Billing', value: selfBilling, type: 'boolean' },
		],
		[],
		{
			title: 'Contacts',
			rows: [
				[
					{ label: 'Contact', value: contact || 'N/A', type: 'string' },
					{ label: 'Phone', value: phone || 'N/A', type: 'string' },
					{ label: 'Fax', value: fax || 'N/A', type: 'string' },
					{ label: 'Email', value: email || 'N/A', type: 'string' },
					{ label: 'Website', value: website || 'N/A', type: 'string' },
				]
			]
		},
		{
			title: 'Billing Address',
			rows: [
				buildAddressRow(billingAddress)
			]
		},
		(shipAddresses || []).length ? {
			title: 'Shipping Addresses',
			rows: (shipAddresses || []).map(address => buildAddressRow(address))
		} : null
	]
}

const mapCard = (json) => {
	const {
		companyName,
		taxId,
		taxEntity,
		taxIdCount,
		accountId,
		phone,
		contact,
		country
	} = json

	return [
		[
			{ labels: ['name'], value: companyName, type: 'string' }
		],
		[
			{ labels: ['country'], value: `${ country }`, type: 'number' }
		],
		[
			{ labels: ['entity'], value: `${ taxEntity }`, type: 'number' }
		],
		[
			{ labels: ['id'], value: `${ accountId }`, type: 'number' }
		],
		[
			{ labels: ['phone'], value: phone || 'N/A', type: 'number' }
		],
		[
			{ labels: ['contact'], value: contact || 'N/A', type: 'number' }
		],
		[
			{ labels: ['vat'], value: `${ taxId }`, type: 'number' }
		],
		[
			{ labels: ['vat_count'], value: `(${ taxIdCount })`, type: 'number' }
		]
	]
}

const mapper = (json, card, details) => {
	
	return {
		...json,
		name: json.companyName,
		shortName: json.companyName,
		card: card ? mapCard(json) : [],
		details: details ? mapDetails(json) : []
	}
}

const header = [
	{ label: ['Name'], align: 'left', columnName: "company_name", sortable: true },
	{ label: ['Country'], align: 'center' },
	{ label: ['Tax Entity'], align: 'center', columnName: "tax_entity", sortable: true },
	{ label: ['Account ID'], align: 'center' },
	{ label: ['Phone'], align: 'center' },
	{ label: ['Contact'], align: 'center' },
	{ label: ['VAT'], align: 'center' },
	{ label: ['(Count)'], align: 'center', columnName: "supplier_tax_id_dup", sortable: true }
]

export default {
	map: mapper,
	header
}