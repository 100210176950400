export function checkIfStuck(elem, positionAttribute = 'top', treshold = 0) {
	if (!elem) { return false }
	const pos = elem.getBoundingClientRect()[positionAttribute]
	const stickyPoint = parseInt(getComputedStyle(elem)[positionAttribute], 10)

	if (positionAttribute === 'top') {
		return pos <= (stickyPoint + treshold) && window.scrollY > 10
	} else if (positionAttribute === 'bottom') {
		return pos + stickyPoint >= (window.innerHeight - treshold)
	}
	return false
}
