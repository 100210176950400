import * as fragments from './fragments'

const isInt = (n) => (
	Number(n) === n && n % 1 === 0
)

export function number(value) {
	return value !== undefined ? Number(value) : undefined
}

export function array(value) {
	return (value || '').split(',').map(v => v.trim())
}

export function jsonFilter(filterString) {
	const jsonValue = {}
	const pairs = (filterString || '').split(',').filter(p => p)

	pairs.forEach((pair) => {
		const [key, value] = pair.split(':')
		jsonValue[key] = decodeURIComponent(value)
	})
	return jsonValue
}

export function searchFilter(json) {
	return Object.entries(json).map(([key, value]) => (`${ key }:${ value }`)).join(',')
}

export function parseFilter(filterString) {
	try {
		const s = decodeURIComponent(escape(window.atob(filterString)))
		return JSON.parse(s)
	} catch (_) {
		return {}
	}
}

export function stringifyFilter(filterString) {
	try {
		const s = unescape(encodeURIComponent(JSON.stringify(filterString)))
		return btoa(s)
	} catch (_) {
		return ''
	}
}

export function alias({ resource, args, locale, body }, appendBody, appendAll) {
	const aliasArgs = (args || {})

	const argsString = resource !== 'search' ? Object.entries(aliasArgs)
		.filter(([k, v]) => k !== 'page' && k !== 'pageSize' && v)
		.sort(([aK], [bK]) => aK.localeCompare(bK))
		.map(([k, v]) => (`${ k }_${ (Array.isArray(v) ? v.map((vv) => typeof vv === 'string' ? vv : JSON.stringify(vv)).join('_') : JSON.stringify(v)) }`))
		.join('_')
		.normalize('NFD')
		.replace(/-/g, '_')
		.replace(/&/g, 'e')
		.replace(/\|/g, 'o')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/[^\w ]+/g, '')
		.replace(/ +/g, '_') : ''

	return `${ locale ? `${ locale }_` : '' }${ resource }${ argsString ? `_${ argsString }` : '' }${ appendBody && body ? `(${ body })` : '' }${ appendAll && (args.page || args.pageSize) ? `_pagging_${ args.page }_${ args.pageSize }` : '' }`
}

const getRequiredFragements = (fragment, fragmentsList) => {
	const fragments = []
	const { entity, body } = fragmentsList[fragment] || {}

	const fragmentDependencies = ((body || '').match(/\.{3}[a-zA-Z_]+/gm) || []).map(f => f.substring(3))

	fragmentDependencies.forEach((dependency) => {
		const innerFragments = getRequiredFragements(dependency, fragmentsList)
		fragments.push(...innerFragments)
	})
	if (entity && body && entity.length && body.length) {
		fragments.push([
			fragment,
			`fragment ${ fragment } on ${ entity } {__typename,${ body }}`
		])
	}
	return fragments
}

export function buildRequestBody(queries = [], locale) {
	if (!queries || !queries.length) {
		return null
	}
	const variables = {}
	const variablesTypes = new Set()
	const queryFragments = {}
	const queriesString = []
	const qq = (queries || [])
	qq.forEach((query) => {
		const args = query.args || {}
		const queryStringArgs = []

		Object.entries(args).forEach(([key, argValue]) => {
			const type = (argValue || {}).type ? argValue.type : (Array.isArray(argValue) ? 'array' : typeof argValue)
			const value = (argValue || {}).value ? argValue.value : argValue
			// TODO: add type for FilterInput
			switch (type) {
				case 'number':
					if (value >= 0) {
						const numberType = isInt(value) ? 'Int' : 'Float'
						variablesTypes.add(`$${ key }: ${ numberType }`)
						variables[key] = value
						queryStringArgs.push(`${ key }:$${ key }`)
					}
					break
				case 'array':
					const innerType = typeof value[0] === 'number' ? isInt(value) ? 'Int' : 'Float' : 'String'
					variablesTypes.add(`$${ key }: [${ innerType }]`)
					variables[key] = value
					queryStringArgs.push(`${ key }:$${ key }`)
					break
				case 'object':
					variablesTypes.add(`$${ key }: JSON`)
					variables[key] = value
					queryStringArgs.push(`${ key }:$${ key }`)
					break
				case 'undefined':
					break
				default:
					variablesTypes.add(`$${ key }: ${ type !== 'string' ? type : 'String' }`)
					variables[key] = value
					queryStringArgs.push(`${ key }:$${ key }`)
					break
			}
		})

		const queryAlias = alias({
			resource: query.resource,
			args: query.args,
			locale
		})
		const queryString = `${ queryAlias }:${ query.resource }${ queryStringArgs.length ? `(${ queryStringArgs.join(',') })` : '' }${ query.body ? `{${ query.body }}` : '' }`
		queriesString.push(queryString)

		if (query.body) {
			const foundFragments = query.body.match(/\.{3}[a-zA-Z_]+/gm)
			if (foundFragments) {
				foundFragments.forEach((fragment) => {
					const fragmentName = fragment.substring(3)
					const requiredFragments = getRequiredFragements(fragmentName, fragments)
					requiredFragments.forEach(([name, body]) => {
						if (!queryFragments[name]) {
							queryFragments[name] = body
						}
					})
				})
			}

		}
	})
	// console.log(queryFragments)
	const query = `${ [...Object.values(queryFragments)].join('\n') }query${ variablesTypes.size ? `( ${ [...variablesTypes].join(',') } )` : '' }{${ queriesString.join() }}`
	return {
		query,
		variables
	}
}