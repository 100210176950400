import { format, date } from "../../../utils";

const mapDetails = (json) => {
    const {
        invoiceNumber,
        period,
        glPostingDate,
        invoiceDate,
        taxCode,
        netTotal,
        taxPayable,
        grossTotal,
        taxAmount,
        invoiceType,
        taxPercentage,
        supplierId,
        supplier,
        currencyCode
    } = json;

    return [
        [
            { label: 'Invoice Number', value: invoiceNumber || 'N/A', type: 'string' },
            { label: 'Invoice Type', value: invoiceType || 'N/A', type: 'string' },
            { label: 'Period', value: period, type: 'number' },
            { label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
            { label: 'GL Posting Date', value: date(glPostingDate), type: 'date' }
        ],
        [
            { label: 'Gross Total', value: format(grossTotal), type: 'currency' },
            { label: 'Net Total', value: format(netTotal), type: 'currency' },
            { label: 'Tax Payable', value: format(taxPayable), type: 'currency' },
            { label: 'Tax Amount', value: format(taxAmount), type: 'currency' }
        ],
        [
            { label: 'Tax Code', value: taxCode || 'N/A', type: 'string' },
            { label: 'Tax Percentage', value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' },
            { label: 'Currency Code', value: currencyCode || 'N/A', type: 'string' },
            supplier && supplier.id ? { label: 'Supplier', value: { id: supplier.id, name: supplier.name, __typename: 'ErpSupplier' }, type: 'object' } : { label: 'Supplier', value: supplierId, type: 'string' }
        ]
    ];
}


const mapCard = (json) => {
    const {
        invoiceNumber,
        invoiceType,
        invoiceDate,
        glPostingDate,
        netTotal,
        taxPayable,
        grossTotal,
        supplierId
    } = json;

    return [
        [
            { labels: 'Invoice Number', value: invoiceNumber || 'N/A', type: 'string' }
        ],
        [
            { labels: 'Invoice Type', value: invoiceType || 'N/A', type: 'string' }
        ],
        [
            { labels: 'Supplier ID', value: supplierId || 'N/A', type: 'string' }
        ],
        [
            { labels: 'Invoice Date', value: date(invoiceDate), type: 'date' }
        ],
        [
            { labels: 'GL Posting Date', value: date(glPostingDate), type: 'date' }
        ],
        [
            { labels: 'Net Total', value: format(netTotal), type: 'currency' }
        ],
        [
            { labels: 'Tax Payable', value: format(taxPayable), type: 'currency' }
        ],
        [
            { labels: 'Gross Total', value: format(grossTotal), type: 'currency' }
        ],
        [
            { labels: [], value: ['delete'], type: 'user_actions' }
        ]
    ]
};

const mapper = (json, card, details) => {
    return {
        ...json,
        name: json.invoiceNumber,
        shortName: json.invoiceNumber,
        details: details ? mapDetails(json) : [],
        card: card ? mapCard(json) : []
    };
};


const header = [
    { label: ['Invoice Number'], align: 'left', columnName: 'invoice_number', sortable: true },
    { label: ['Invoice Type'], align: 'left' },
    { label: ['Supplier ID'], align: 'left' },
    { label: ['Invoice Date'], align: 'center' },
    { label: ['GL Posting Date'], align: 'center' },
    { label: ['Net Total'], align: 'right', columnName: 'net_total', sortable: true },
    { label: ['Tax Payable'], align: 'right', columnName: 'tax_payable', sortable: true },
    { label: ['Gross Total'], align: 'right', columnName: 'gross_total', sortable: true },
    { label: ["checkbox"], align: 'right' }
];

export default {
    map: mapper,
    header
}
