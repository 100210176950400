import App from '../controllers/App/App'
import { StackLayout } from '../layouts'
import { List, rows, Filters } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'
import theme from 'colbi_web_ui/lib/style/_theme.json';

const queries = (args = {}) => ([
	{
		resource: 'master_data',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'entity, count'
	},
	{
		resource: args.entity,
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
			filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
			sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined,
			page: parseInt(args.page, 10) || 0
		},
		body: `__typename, items{ ...${args.entity} }, hasMore, total, filters(projectId:$projectId,fiscalYear:$fiscalYear){ prop, name, type, list }`,
		downloadable: true
	}
].filter(r => !!r.resource))

const masterData = (path, navigation, exact) => ({
	path,
	navigation,
	component: App,
	queries,
	exact,
	title: ['label(Master Data)', 'label(param(entity))'],
	components: [
		{
			component: Filters,
			props: {
				filter: 'param(filter)',
				availableFilters: 'query(1).filters',
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: 'results'
			}
		}
	],
	routes: [
		{
			path,
			exact: true,
			queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: [
						'--row-style-alternate'
					],
					props: {
						basePath: path,
						items: 'query(1).list',
						sort: 'param(sort)',
						cardKey: 'param(entity)',
						card: rows.EntityCard,
						header: 'query(1).header',
						metadata: 'query(1).metadata',
						page: 'param(page)',
						status: 'status(0)',
						pageSize: 'param(pageSize)',
						footer: 'query(1).footer'
					}
				}
			]
		}
	]
})

export default masterData
