import { format } from '../../utils'

const header = [
    { label: ["[Type]"], align: 'left' },
    { label: ["Tax Entity"], align: 'left' },
    { label: ["Debit Credit Indicator"], align: 'left' },
    { label: ["Asset Transaction Amount"], align: 'right' },
    { label: ["Book Value on Transaction"], align: 'right' },
    { label: ["Acquisition and Production Costs on Transaction"], align: 'right' }
]

const mapCard = (json) => {
	const {
        assetValuationType,
        taxEntity,
        debitCreditIndicator,
        assetTransactionAmount,
        bookValueOnTransaction,
        acquisitionAndProductionCostsOnTransaction
	} = json

	return [
        [
            { labels: ['type'], value: assetValuationType || 'N/A', type: 'string' },
        ],
        [
            { labels: ['tax_entity'], value: taxEntity || 'N/A', type: 'string' },
        ],
        [
            { labels: ['debit_credit_indicator'], value: debitCreditIndicator, type: 'string' }
        ],
        [
            { labels: ['asset_transaction_amount'], value: format(assetTransactionAmount), type: 'currency' }
        ],
        [
            { labels: ['book_value_on_transaction'], value: format(bookValueOnTransaction), type: 'currency' }
        ],
        [
            { labels: ['acquisition_production_costs'], value: format(acquisitionAndProductionCostsOnTransaction), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.asset_transaction_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}