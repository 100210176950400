import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { connect } from 'react-redux'
import { parse } from 'query-string'

import { GlobalProvider as ColbiUIProvider } from 'colbi_web_ui/lib/state/GlobalProvider'
import { GlobalProvider } from '../../state/globalProvider'

import { mapProperties, hydrateRoute, stringifyFilter } from 'colbi_web_ui/lib/utils'
import AsideMenu from 'colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu'

import { LoadingIndicator } from 'colbi_web_ui/lib/components';
import Header from 'colbi_web_ui/lib/components/layout/Header/Header'
import ModalController from '../App/ModalController/ModalController'
import DialogController from '../App/DialogController/DialogController'
import { ReactComponent as logo } from '../../assets/logo.svg'

import {
	logout,
	pushModal,
	pushDialog,
	popModal,
	uploadAction,
	toggleMenu,
	setLocale,
	auditNoteSubmit,
	removeModal,
	setDataFileStatusAction,
	deleteFilePermanentlyAction,
	reprocessRepositoryAction,
	downloadAction
} from '../../store/actions'
import navigation from './navigation'
import I18n from '../../I18n'
import locales from '../../I18n/locales.json'
import theme from '../../style/_theme.json'
import proxy from '../../routes/proxy'

import Connect from 'colbi_web_ui/lib/components/Connect'
import FileUploaderStatus from 'colbi_web_ui/lib/components/inputs/FileUploader/FileUploaderStatus'
import styles from './Repository.module.sass'

const tabs = [
	{
		section: 'activity',
		label: 'Activity',
		createType: 'Activity'
	},
	{
		section: 'folders',
		label: 'Folders',
		createType: 'Folder'
	}
]

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>
				{children}
			</ColbiUIProvider>
		</GlobalProvider>
	)
}

const Repository = (props) => {
	const {
		user,
		fetch,
		match,
		setLocale,
		location,
		toggleMenu,
		logout,
		history,
		pushModal,
		popModal,
		menu,
		reload,
		pushDialog,
		uploadFiles,
		submitAuditNote,
		route,
		setDataFileStatus,
		deleteFilePermanently,
		reprocessRepository,
		download
	} = props

	useEffect(() => {
		const pop = history.action === 'POP'
		// setLocale(locale)
		if (!pop && !fetch.activity) {
			if (location.hash) {
				const scrollElement = window.document.querySelector(location.hash)
				if (scrollElement) {
					const headerOffset = 70

					const previousRect = scrollElement.previousElementSibling.getBoundingClientRect()
					let stuckOffset = previousRect.height
					const elementPosition = scrollElement.offsetTop
					const offsetPosition = elementPosition - headerOffset - stuckOffset - 100

					setTimeout(() => {
						window.scrollTo({
							top: offsetPosition,
							behavior: 'smooth'
						})
					}, 0)
				}
				// TODO: Review this condition so that page doesn't scroll to top on new page request!
			} else if (location.search.search("page") === -1) {
				setTimeout(() => {
					window.scrollTo(0, 0)
				}, 0)
			}
		}
	}, [fetch.activity, location.hash, location.pathname, location.search, history.action])


	const locale = match.params.locale || 'en'
	const i18n = I18n.use(locale)
	setLocale(locale)

	if (!user) {
		return <Redirect to={'/login'} push />
	}

	if (user && parseInt(user.mysqlPasswordReset)) {
		return <Redirect to={'/reset_password'} />
	}

	const projectId = match.params.projectId

	const organizations = (fetch.results[`${locale}_organizations`] || { list: [] }).list

	if (!projectId) {
		return <Redirect to={`${locale ? `/${locale}` : ''}/`} />
	}

	const project = fetch.results[`${locale}_project_projectId_${projectId}`] || {}
	const queryParams = parse(location.search) || {}
	const routeParams = match.params || {}
	const params = {
		...queryParams,
		...routeParams
	}

	const currentSection = params.section
	const hydrated = hydrateRoute(route.path, { ...params })

	const folderData = (fetch.results[`${locale}_folder_data_projectId_${projectId}`] || { list: [] }).list
	const folderDataNav = folderData.map(({ fiscalYear, count }) => {
		return {
			name: `${i18n`${fiscalYear}`} (${count})`,
			to: `/:projectId/repository/folders/${fiscalYear}`
		}
	})

	const repositoryStatus = fetch.results[`${locale}_repository_status_fiscalYear_${match.params.fiscalYear}_projectId_${projectId}`] || { status: 1 }

	const navigationNav = mapProperties(
		{ navigation },
		(fetch || {}).results,
		(fetch || {}).status,
		Repository.queries(params),
		{
			...props,
			folderData: folderDataNav
		},
		params,
		i18n,
		locale
	)

	if (!currentSection) {
		return <Redirect to={`${hydrated}${tabs[0].section}/status`} replace />
	}

	if (params.section === 'activity' && !params.fiscalYear) {
		return <Redirect to={`${hydrated}status`} replace />
	}

	if (
		params.section === 'folders' &&
		!params.fiscalYear &&
		navigationNav &&
		navigationNav.navigation.length &&
		navigationNav.navigation[1].navigation.length
	) {
		return <Redirect to={`${hydrated}${navigationNav.navigation[1].navigation[0].name.split(" ")[0]}`} replace />
	}

	const routeQueries = (route.queries && route.queries(params)) || []

	const children = (route.components || []).map((c, index) => {
		const Component = c.component

		const componentProps = mapProperties(
			match.isExact ? c.props : { ...c.props, checkbox: false },
			(fetch || {}).results,
			(fetch || {}).status,
			routeQueries,
			props,
			params,
			i18n,
			locale
		)

		const sectionClass = `--root-repository-${params.section}`
		const modifiersClasses = c.modifiers ? [...Object.values(c.modifiers), sectionClass] : [sectionClass]

		return (<Component key={index} modifiers={modifiersClasses} {...componentProps} active={fetch.activity} />)
	})

	// const title = mapProperties(
	// 	{ title: route.title },
	// 	(fetch || {}).results,
	// 	(fetch || {}).status,
	// 	routeQueries,
	// 	props,
	// 	params,
	// 	i18n,
	// 	locale
	// ).title

	const branch = matchRoutes([route, ...(route.routes || [])], location.pathname)

	const branchQueries = branch.reduce((allQueries, { route }) => {
		const innerQueries = route.queries ? route.queries(params) : []
		allQueries = [
			...allQueries,
			...innerQueries
		]
		return allQueries
	}, [])

	const downloadableQueries = branchQueries.filter(({ downloadable }) => downloadable)

	const goto = (page, filters, anchor) => {
		const pageUrl = page ? (typeof page.join === 'function' ? page.join('/') : page).replace(/\/+/g, '/') : null
		const urlFilters = filters ? stringifyFilter(filters) : ''
		const localePrefixed = pageUrl ? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? '/' : ''}` : ''}` : null
		const gotoPage = pageUrl ? `${localePrefixed}${pageUrl}${urlFilters.length ? `?filter=${urlFilters}` : ''}` : `${location.pathname}?filter=${urlFilters}${anchor ? `#${anchor}` : ''}`
		history.push(gotoPage)
	}

	return (
		<div className={styles['app']}>
			<Provider
				user={user}
				proxy={proxy}
				theme={theme}
				actions={{
					pushModal, pushDialog, popModal, uploadFiles, submitAuditNote, removeModal, setDataFileStatus, deleteFilePermanently, reprocessRepository, logout,
					setLocale: (locale) => {
						history.push(`/${locale}${params.projectId ? `/${params.projectId}` : ''}${(params.fiscalYear && params.fiscalYear !== "status") ? `/${params.fiscalYear}` : ''}`)
					},
					goto, download
				}}
				i18n={i18n}
				locale={locale || locales[0].id}
				locales={locales}
				reload={reload}
				params={params}
				history={history}
				location={location}
				repositoryStatus={repositoryStatus}
			>
				<div className={`${styles['aside']} ${menu === 'expanded' ? '' : styles['is-collapsed']}`}>
					<AsideMenu
						logo={logo}
						title={menu === 'expanded' ? i18n`Repository` : ''}
						navigation={navigationNav.navigation}
						organizations={organizations}
						project={{ ...(project || {}), id: params.projectId, name: (project || {}).name || (project || {}).description }}
						expanded={menu === 'expanded'}
					/>
				</div>
				<div className={styles['main']}>
					<Header
						className={styles['header']}
						logout={logout}
						proxy={proxy}
						theme={theme}
						title={i18n`${params.section || ''}`}
						toggleMenu={toggleMenu}
						settingsAction={user.role === 's' || user.role === 'so'}
						downloadableQueries={downloadableQueries}
						expanded={menu !== 'expanded'}
						
						project={{ ...(project || {}), id: params.projectId, name: (project || {}).name || (project || {}).description }}
						navigation={navigationNav.navigation}
						organizations={organizations}
					/>
					<main className={`${styles['content'] || ''} ${styles['foreground'] || ''}`}>
						{fetch.activity &&
							<LoadingIndicator
								className={`${
									styles['loading-indicator']} ${menu === 'expanded' ? '' :
										styles['small-margin']
									}`}
							/>
						}
						{children}

						{renderRoutes(route.routes)}

						<Connect to={['uploads']}>
							{({ uploads }) => (
								<FileUploaderStatus
									uploads={uploads}
									uploadFiles={uploadFiles}
									reload={reload}
								/>
							)}
						</Connect>
					</main>
				</div>
				<ModalController popModal={popModal} />
				<DialogController i18n={i18n} />
			</Provider>
		</div>
	)
}

Repository.queries = (args) => ([
	{
		resource: 'organizations',
		body: 'id,name,projects{id,name,fiscalYears,status,taxRegistrationNumber}'
	},
	{
		resource: 'project',
		args: {
			projectId: (args.projectId)
		},
		body: 'id,name, description, fiscalYears'
	},
	{
		resource: 'folder_data',
		args: {
			projectId: (args.projectId)
		},
		body: 'fiscalYear, count'
	},
	{
		resource: 'repository_status',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'status'
	}
])

export default connect(
	({ user, fetch, menu }) => (
		{
			user,
			fetch,
			menu
		}
	),
	{
		logout,
		pushModal,
		popModal,
		removeModal,
		toggleMenu,
		setLocale,
		pushDialog,
		setDataFileStatus: setDataFileStatusAction,
		deleteFilePermanently: deleteFilePermanentlyAction,
		reprocessRepository: reprocessRepositoryAction,
		uploadFiles: uploadAction,
		submitAuditNote: auditNoteSubmit,
		download: downloadAction
	}
)(withRouter(Repository))
