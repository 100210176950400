const submitSucess = (data) => {
	return (
		{
			type: 'SUBMIT_SUCCESS',
			payload: {
				data
			}
		}
	)
}
const submitError = (data) => {
	return (
		{
			type: 'SUBMIT_ERROR',
			payload: {
				data
			}
		}
	)
}

export const auditNoteSubmit = ({ projectId, fiscalYear, auditNote }) => (
	(dispatch) => {
		const queriesBody = {
			query: `mutation($i:AuditNoteInput!,$projectId:String!,$fiscalYear:String!){audit_note(projectId:$projectId,fiscalYear:$fiscalYear,auditNote:$i){id,status}}`,
			variables: {
				i: auditNote,
				projectId,
				fiscalYear
			}
		}

		return fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res.json().catch(() => ({})))
			.then((res) => {
				const data = res.data.audit_note.status || {}
				if (data === 201 || data === 200) {
					dispatch(submitSucess(auditNote.findingId))
				} else {
					dispatch(submitError(auditNote.findingId))
				}
			})
			.catch((e) => {
				dispatch(submitError(auditNote.findingId))
				console.log(`ERROR: ${ e }`)
			})
	}
)
