import App from '../../controllers/App/App'
import { rows, List } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'


const queries = (args = {}) => {
    return [
        {
            resource: 'vat_reports',
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined
            },
            body: `__typename, items{...vat_reports}, hasMore, total`
        }
    ].filter(r => !!r.resource)
}


const vatDataHistory = (path, navigation, exact) => ({
    path,
    navigation,
    component: App,
    queries,
    exact,
    title: ['label(VAT Report)'],
    components: [
        {
            component: List,
            modifiers: ['--row-style-alternate'],
            props: {
                card: rows.EntityCard,
                header: 'query(0).header',
                sort: 'param(sort)',
                items: 'query(0).list',
                handleNoResults: true,
                metadata: 'query(0).metadata',
                page: 'param(page)',
                pageSize: 'param(pageSize)',
                pageDefault: 10,
                footer: 'query(0).footer'
            }
        }
    ]

})

export default vatDataHistory;