import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { connect } from 'react-redux'
import { parse } from 'query-string'

import { GlobalProvider as ColbiUIProvider } from 'colbi_web_ui/lib/state/GlobalProvider'
import { mapProperties, hydrateRoute, stringifyFilter } from 'colbi_web_ui/lib/utils'

import AsideMenu from 'colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu'
import Header from 'colbi_web_ui/lib/components/layout/Header/Header'
import Connect from 'colbi_web_ui/lib/components/Connect'

import { GlobalProvider } from '../../state/globalProvider'
import MainTabs from './MainTabs/MainTabs'

import ModalController from './ModalController/ModalController'
import DialogController from './DialogController/DialogController'

import { LoadingIndicator } from 'colbi_web_ui/lib/components'
import {
	logout,
	pushModal,
	pushDialog,
	popModal,
	uploadAction,
	downloadAction,
	toggleMenu,
	setLocale,
	auditNoteSubmit,
	removeModal,
	setDataFileStatusAction,
	deleteFilePermanentlyAction,
	reprocessRepositoryAction,
	searchAction,
	removeVatReportAction
} from '../../store/actions'
import I18n from '../../I18n'
import locales from '../../I18n/locales.json'
import theme from '../../style/_theme.json'
import proxy from '../../routes/proxy'

import { ReactComponent as logo } from '../../assets/logo.svg'

import styles from './App.module.sass'

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>
				{children}
			</ColbiUIProvider>
		</GlobalProvider>
	)
}

const items = (i18n) => {
	if (process.env.REACT_APP_LOCATION === 'SK') {
		return [
			{ type: 'erp_purchase_invoices', name: i18n`Erp Purchase Invoices` },
			{ type: 'erp_sale_invoices', name: i18n`Erp Sale Invoices` },
			{ type: 'erp_customers', name: i18n`Erp Customers` },
			{ type: 'erp_suppliers', name: i18n`Erp Suppliers` }
		]
	}
	return [
		{ type: 'invoice', name: i18n`Invoice` },
		{ type: 'payment', name: i18n`Payment` },
		{ type: 'stock_movement', name: i18n`Stock Movement` },
		{ type: 'transaction', name: i18n`Transaction` },
		{ type: 'work_document', name: i18n`Work Document` },
		{ type: 'customer', name: i18n`Customer` },
		{ type: 'supplier', name: i18n`Supplier` },
		{ type: 'product', name: i18n`Product` },
		{ type: 'general_ledger', name: i18n`General Ledger` }
	]
}

const App = (props) => {
	const {
		user,
		fetch,
		menu,
		logout,
		pushModal,
		popModal,
		searchAction,
		setLocale,
		match,
		route,
		location,
		history,
		reload,
		uploads,
		pushDialog,
		uploadFiles,
		download,
		submitAuditNote,
		setDataFileStatus,
		deleteFilePermanently,
		reprocessRepository,
		removeVatReport
	} = props

	const locale = match.params.locale || 'en'

	useEffect(() => {
		const pop = history.action === 'POP'
		setLocale(locale)
		if (!pop && !fetch.activity) {
			if (location.hash) {
				const scrollElement = window.document.querySelector(location.hash)
				if (scrollElement) {
					const headerOffset = 70

					const previousRect = scrollElement.previousElementSibling.getBoundingClientRect()
					let stuckOffset = previousRect.height
					const elementPosition = scrollElement.offsetTop
					const offsetPosition = elementPosition - headerOffset - stuckOffset - 100

					setTimeout(() => {
						window.scrollTo({
							top: offsetPosition,
							behavior: 'smooth'
						})
					}, 0)
				}
				// TODO: Review this condition so that page doesn't scroll to top on new page request!
			} else if (location.search.search("page") === -1) {
				setTimeout(() => {
					window.scrollTo(0, 0)
				}, 0)
			}
		}
	}, [fetch.activity, location.hash, location.pathname, location.search, history.action, locale, setLocale])

	if (!user) {
		return <Redirect to={'/login'} push />
	}

	if (user && parseInt(user.mysqlPasswordReset)) {
		return <Redirect to={'/reset_password'} />
	}

	const projectId = match.params.projectId

	const organizations = (fetch.results[`${locale}_organizations`] || { list: [] }).list

	if (!projectId) {
		return <Redirect to={`${locale ? `/${locale}` : ''}/`} />
	}

	const i18n = I18n.use(locale)

	const project = fetch.results[`${locale}_project_projectId_${projectId}`] || {}

	const fiscalYears = project.fiscalYears || []
	const fiscalYearsStatus = fetch.status[`${locale}_project_projectId_${projectId}(name, description, fiscalYears, taxRegistrationNumber)`] || []
	const fiscalYear = match.params.fiscalYear || fiscalYears[0] || 'current'

	const repositoryStatus = fetch.results[`${locale}_repository_status_fiscalYear_${fiscalYear}_projectId_${projectId}`] || { status: 1 }
	const masterDataSummary = (fetch.results[`${locale}_master_data_fiscalYear_${fiscalYear}_projectId_${projectId}`] || { list: [] }).list
	const transactionalDataSummary = (fetch.results[`${locale}_transactional_data_fiscalYear_${fiscalYear}_projectId_${projectId}`] || { list: [] }).list

	const erpMasterDataSummary = (fetch.results[`${locale}_erp_master_data_fiscalYear_${fiscalYear}_projectId_${projectId}`] || { list: [] }).list
	const erpTransactionalDataSummary = (fetch.results[`${locale}_erp_transactional_data_fiscalYear_${fiscalYear}_projectId_${projectId}`] || { list: [] }).list


	const masterDataNav = masterDataSummary.map(({ entity, count }) => {
		return {
			name: `${i18n`${entity}`} (${count})`,
			to: `/:projectId/:fiscalYear([0-9]{4})/master_data/${entity}`
		}
	})

	const erpMasterDataNav = erpMasterDataSummary.map(({ entity, count }) => {
		return {
			name: `${i18n`${entity}`} (${count})`,
			to: `/:projectId/:fiscalYear([0-9]{4})/erp_master_data/${entity}`
		}
	})

	const transactionalDataNav = transactionalDataSummary.map(({ entity, count }) => {
		return {
			name: `${i18n`${entity}`} (${count})`,
			to: `/:projectId/:fiscalYear([0-9]{4})/${entity}`
		}
	})

	const erpTransactionalDataNav = erpTransactionalDataSummary.map(({ entity, count }) => {
		return {
			name: `${i18n`${entity}`} (${count})`,
			to: `/:projectId/:fiscalYear([0-9]{4})/erp_transactional_data/${entity}`
		}
	})

	//en_audit_summary_fiscalYear_2014_projectId_51
	const auditSummary = (fetch.results[`${locale}_audit_summary_fiscalYear_${fiscalYear}_projectId_${projectId}`] || { list: [] }).list
	const totalFindings = (auditSummary || []).reduce((acc, { count }) => acc += count, 0)

	if (fiscalYear !== match.params.fiscalYear) {
		const to = hydrateRoute(match.path, { ...match.params, fiscalYear })
		if (to !== match.url && !to.match(/\*$/)) {
			return <Redirect to={to} />
		}
	}

	if (fiscalYearsStatus.success && !fiscalYears.length && !match.url.match(/\/(repository|settings)$/)) {
		return <Redirect to={`${locale ? `/${locale}` : ''}/${projectId}/repository`} />
	}

	const queryParams = parse(location.search) || {}
	const routeParams = match.params || {}
	const params = {
		...queryParams,
		...routeParams
	}

	const goto = (page, filters, anchor) => {
		const pageUrl = page ? (typeof page.join === 'function' ? page.join('/') : page).replace(/\/+/g, '/') : null
		const urlFilters = filters ? (Array.isArray(filters) ? stringifyFilter(filters) : stringifyFilter([filters])) : ''
		const localePrefixed = pageUrl ? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? '/' : ''}` : ''}` : null
		const gotoPage = pageUrl ? `${localePrefixed}${pageUrl}${urlFilters.length ? `?filter=${urlFilters}&resetFilters=${Date.now()}` : ''}` : `${location.pathname}?filter=${urlFilters}&resetFilters=${Date.now()}${anchor ? `#${anchor}` : ''}`
		history.push(gotoPage)
	}

	const routeQueries = (route.queries && route.queries(params)) || []

	const children = (route.components || []).map((c, index) => {
		const Component = c.component
		const componentProps = mapProperties(
			c.props,
			(fetch || {}).results,
			(fetch || {}).status,
			routeQueries,
			props,
			params,
			i18n,
			locale
		)

		return (<Component key={index} modifiers={c.modifiers} {...componentProps} activity={fetch.activity} />)
	})

	const title = mapProperties(
		{ title: route.title },
		(fetch || {}).results,
		(fetch || {}).status,
		routeQueries,
		props,
		params,
		i18n,
		locale
	).title

	const branch = matchRoutes([route, ...(route.routes || [])], location.pathname)

	const branchQueries = branch.reduce((allQueries, { route }) => {
		const innerQueries = route.queries ? route.queries(params) : []
		allQueries = [
			...allQueries,
			...innerQueries
		]
		return allQueries
	}, [])

	const downloadableQueries = branchQueries.filter(({ downloadable }) => downloadable)

	const { navigation } = mapProperties(
		{ navigation: route.navigation },
		(fetch || {}).results,
		(fetch || {}).status,
		App.queries(params),
		{
			...props,
			totalFindings,
			masterData: masterDataNav,
			transactionalData: transactionalDataNav,
			erpMasterData: erpMasterDataNav,
			erpTransactionalData: erpTransactionalDataNav
		},
		params,
		i18n,
		locale
	)

	return (
		<Provider
			user={user}
			proxy={proxy}
			theme={theme}
			uploads={uploads}
			actions={{
				pushModal,
				pushDialog,
				popModal,
				uploadFiles,
				download,
				submitAuditNote,
				removeModal,
				setDataFileStatus,
				deleteFilePermanently,
				reprocessRepository,
				logout,
				setLocale: (locale) => {
					history.push(`/${locale}${params.projectId ? `/${params.projectId}` : ''}${params.fiscalYear ? `/${params.fiscalYear}` : ''}`)
				},
				search: searchAction,
				goto,
				removeVatReport
			}}
			i18n={i18n}
			locale={locale || locales[0].id}
			locales={locales}
			reload={reload}
			params={{
				...params,
				fiscalYear: params.fiscalYear || fiscalYear
			}}
			history={history}
			location={location}
			repositoryStatus={repositoryStatus}
		>
			<div className={styles['app']}>
				<div className={`${styles['aside']} ${menu === 'expanded' ? '' : styles['is-collapsed']}`}>
					<AsideMenu
						logo={logo}
						navigation={navigation}
						organizations={organizations}
						project={{ ...(project || {}), id: params.projectId, name: (project || {}).name || (project || {}).description }}
						expanded={menu === 'expanded'}
					/>
				</div>
				<div className={styles['main']}>
					<Connect to={['search', 'downloading']}>
						{({ search, downloading }) => (
							<Header
								className={styles['header']}
								searchAction
								reportsAction
								settingsAction
								project={{ ...(project || {}), id: params.projectId, name: (project || {}).name || (project || {}).description }}
								fiscalYears={fiscalYears}
								items={items(i18n)}
								downloadableQueries={downloadableQueries}
								title={title}
								downloading={downloading}
								search={search}
								navigation={navigation}
								organizations={organizations}
							/>
						)}
					</Connect>
					<main className={`${styles['content'] || ''} ${styles['foreground'] || ''}`}>
						{fetch.activity &&
							<LoadingIndicator
								className={`${
									styles['loading-indicator']} ${menu === 'expanded' ? '' :
										styles['small-margin']
									}`}
							/>
						}
						{children}
						{
							(route.routes && route.routes.length > 1) &&
							<MainTabs className={styles['tabs']} routes={route.routes} params={match.params} />
						}
						{renderRoutes(route.routes)}
					</main>
				</div>
				<ModalController popModal={popModal} />
				<DialogController i18n={i18n} />
			</div>
		</Provider>
	)
}

App.queries = (args) => ([
	{
		resource: 'organizations',
		body: 'id,name,projects{id,name,fiscalYears,status,taxRegistrationNumber}'
	},
	{
		resource: 'project',
		args: {
			projectId: (args.projectId)
		},
		body: 'name, description, fiscalYears, taxRegistrationNumber'
	},
	{
		resource: 'audit_summary',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear)
		},
		body: 'severity, count'
	},
	{
		resource: 'master_data',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'entity, count'
	},
	{
		resource: 'transactional_data',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'entity, count'
	},
	{
		resource: 'repository_status',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'status'
	},
	{
		resource: 'erp_master_data',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'entity, count'
	},
	{
		resource: 'erp_transactional_data',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
		},
		body: 'entity, count'
	}
])

export default connect(
	({ user, fetch, menu, uploads }) => (
		{
			user,
			fetch,
			menu,
			uploads
		}
	),
	{
		logout,
		pushModal,
		popModal,
		removeModal,
		toggleMenu,
		setLocale,
		pushDialog,
		setDataFileStatus: setDataFileStatusAction,
		deleteFilePermanently: deleteFilePermanentlyAction,
		reprocessRepository: reprocessRepositoryAction,
		uploadFiles: uploadAction,
		download: downloadAction,
		submitAuditNote: auditNoteSubmit,
		searchAction,
		removeVatReport: removeVatReportAction
	}
)(withRouter(App))
