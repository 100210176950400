import { buildRequestBody } from "colbi_web_ui/lib/utils"

const downloadStart = () => (
	{
		type: 'DOWNLOAD_START'
	}
)
const downloadEnd = () => (
	{
		type: 'DOWNLOAD_END'
	}
)

export const downloadAction = (argQueries, locale) => (
	(dispatch, getState) => {
		const { downloading } = getState() || {}
		if (!downloading) {

		}
		const queries = (argQueries || []).map(q => {
			return {
				...q,
				args: {
					...(q.args || {}),
					page: 0,
					pageSize: 5000
				}
			}
		})

		const queriesBodies = buildRequestBody(queries, locale)

		dispatch(downloadStart())
		fetch('/api/download_xls', {
			method: 'POST',
			credentials: 'same-origin',
			body: JSON.stringify(queriesBodies)
		}).then((t) => {
			dispatch(downloadEnd())
			return t.blob().then((b) => {
				if (b && b.size > 20) {
					const a = document.createElement("a")
					a.href = URL.createObjectURL(b)
					a.setAttribute("download", `report_${Date.now()}.xlsx`)
					document.body.appendChild(a)
					a.click()
					document.body.removeChild(a)
				} else {
					console.error('empty file')
				}
			}
			);
		}).catch(() => {
			dispatch(downloadEnd())
		})
	}
)