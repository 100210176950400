import { date } from '../../utils'

const header = [
    { label: ["File Name"], align: 'left' },
    { label: ["Type"], align: 'left' },
    { label: ["Fiscal Year"], align: 'left' },
    { label: ["Sales"], align: 'center' },
    { label: ["Purchases"], align: 'center' },
    { label: ["Payments"], align: 'center' },
    { label: ["Stocks"], align: 'center' },
    { label: ["Transactions"], align: 'center' },
    { label: ["Assets"], align: 'center' },
    { label: ["Periods"], align: 'right' },
    { label: ["Fiscal Year From"], align: 'center' },
    { label: ["Fiscal Year To"], align: 'center' }
]

const mapCard = (json) => {
	const {
        name,
        type,
        fiscalYear,
        transactions,
        salesInvoices,
        purchaseInvoices,
        payments,
        stocks,
        assetsTransactions,
        periods,
        fiscalYearFrom,
        fiscalYearTo
	} = json

	return [
        [
            { labels: ['fileName'], value: name, type: 'string' }
        ],
        [
            { labels: ['type'], value: type, type: 'string' }
        ],
        [
            { labels: ['fiscalYear'], value: fiscalYear, type: 'string' }
        ],
        [
            { labels: ['sales'], value: transactions, type: 'number' }
        ],
        [
            { labels: ['purchases'], value: salesInvoices, type: 'number' }
        ],
        [
            { labels: ['payments'], value: purchaseInvoices, type: 'number' }
        ],
        [
            { labels: ['stocks'], value: payments, type: 'number' }
        ],
        [
            { labels: ['transactions'], value: stocks, type: 'number' }
        ],
        [
            { labels: ['assets'], value: assetsTransactions, type: 'number' }
        ],
        [
            { labels: ['periods'], value: periods, type: 'periods' }
        ],
        [
            { labels: ['fiscalYearFrom'], value: date(fiscalYearFrom), type: 'date' }
        ],
        [
            { labels: ['fiscalYearTo'], value: date(fiscalYearTo), type: 'date' }
        ]   
	]
}

const mapper = (json, card) => {
    const name = json.name
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}