import I18n from '../../I18n'

const mapCard = (json) => {
    const {
        ruleId,
        ruleType,
        title,
        appliesTo,
        severity
	} = json
    return [
        [
            { labels: ['ruleId'], value: ruleId, type: 'string', basis: '20%' }
        ],
        [
            { labels: ['title'], value: title, type: 'string', basis: '50%' }
        ],
        [
            { labels: ['severity'], value: I18n.translate(`severity-${severity}`), type: 'number' },
        ],
        [
            { labels: ['ruleType'], value: ruleType, type: 'string' }
        ],
        [
            { labels: ['appliesTo'], value: appliesTo, type: 'string', align: 'right' }
        ]
    ]
}

const mapFilters = (filters) => {
	return filters.map((filter) => {
		if (filter.prop === 'severity') {
			return {
				...filter,
				listMapper: {
					'0': I18n.translate`Low`,
					'1': I18n.translate`Medium`,
					'2': I18n.translate`High`,
					'3': I18n.translate`Critical`,
				}
			}
		}
		return filter
	})
}

const mapper = (json, card) => {
    return {
        ...json,
        name: json.name,
        shortName: json.name,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ['Identifier'], align: 'left', basis: '20%' },
    { label: ['Title'], align: 'left', basis: '50%' },
    { label: ['Severity'], align: 'center' },
    { label: ['Category'], align: 'left' },
    { label: ['Refers to'], align: 'right' }
]

export default {
    map: mapper,
    mapFilters,
    header
}