const header = [
    { label: ["Description"], align: 'left' },
    { label: ["Rule Id"], align: 'left' },
    { label: ["Ocurrences"], align: 'center' },
    { label: ["type"], align: 'center' },
    { label: ["Verified"], align: 'center' }
]

const mapCard = (json) => {
    const {
        description,
        name,
        total,
        type,
        verified,
        rule_identifier
    } = json

    const isRulesFinding = rule_identifier === 'RulesFinding'

    return [
        [
            { labels: ['description'], value: isRulesFinding ? (description || 'N/A') : name, type: 'string' },
        ],
        [
            { labels: ['name'], value: isRulesFinding ? (name || 'N/A') : '', type: 'string' }
        ],
        [
            { labels: ['ocurrences'], value: total, type: 'number' }
        ],
        [
            { labels: ['type'], value: type, type: 'string', align: 'center' }
        ],
        [
            { labels: ['verified'], value: [isRulesFinding ? verified ? 'valid' : 'invalid' : '', isRulesFinding ? `${verified} / ${total}` : ''], type: 'audit' }
        ]
    ]
}

const mapper = (json, card) => {
    const name = json.name

    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
        name,
        shortName: name,
        card: card ? mapCard(json) : []
    }
}

export default {
    map: mapper,
    header
}