import { format } from '../../utils'

const mapDetails = (json) => {
	const {
		taxType,
        taxDeclarationPeriod,
        taxCode,
        taxBase,
        taxCurrencyAmount,
        taxAmountCurrencyCode,
        taxAmount,
        taxPercentage,
        taxExemptionReason,
        taxBaseDescription 
	} = json
	return [
		[
            { label: 'Type', value: taxType, type: 'string' },
            { label: 'Declaration', value: taxDeclarationPeriod, type: 'string' },
            { label: 'code', value: taxCode, type: 'string' },
            { label: 'Base', value: taxBase, type: 'string' }
        ],
        [
            { label: 'Currency Amount', value: taxCurrencyAmount, type: 'string' },
            { label: 'Currency', value: taxAmountCurrencyCode, type: 'string' },
            { label: 'Tax', value: format(taxAmount), type: 'currency' },
            { label: 'Total', value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' }
        ],
        [
            { label: 'Reason', value: taxExemptionReason || 'N/A', type: 'string' },
            { label: 'Description', value: taxBaseDescription || 'N/A', type: 'string' }
        ]
	]
}

const header = [
    { label: ["Tax Type"], align: 'left' },
    { label: ["Tax Declaration Period"], align: 'left' },
    { label: ["Tax Code"], align: 'left' },
    { label: ["Tax Base"], align: 'left' },
    { label: ["Tax Base Description"], align: 'left' },
    { label: ["Foreign Currency Amount"], align: 'left' },
    { label: ["Foreign Currency"], align: 'left' },
    { label: ["Tax Exemption Reason"], align: 'left' },
    { label: ["Tax Amount"], align: 'right' },
    { label: ["Tax Percentage"], align: 'right' }
]

const mapCard = (json) => {
	const {
        taxType,
        taxDeclarationPeriod,
        taxCode,
        taxBase,
        taxBaseDescription,
        taxCurrencyAmount,
        taxAmountCurrencyCode,
        taxExemptionReason,
        taxAmount,
        taxPercentage
	} = json

	return [
        [
            { labels: ['type'], value: taxType, type: 'string' }
        ],
        [
            { labels: ['declaration'], value: taxDeclarationPeriod, type: 'string' }
        ],
        [
            { labels: ['code'], value: taxCode, type: 'string' }
        ],
        [
            { labels: ['base'], value: taxBase, type: 'string' }
        ],
        [
            { labels: ['description'], value: taxBaseDescription, type: 'string' }
        ],
        [
            { labels: ['currencyamount'], value: taxCurrencyAmount, type: 'string' }
        ],
        [
            { labels: ['currency'], value: taxAmountCurrencyCode, type: 'string' }
        ],
        [
            { labels: ['reason'], value: taxExemptionReason, type: 'string' }
        ],
        [
            { labels: ['tax'], value: format(taxAmount), type: 'currency' }
        ],
        [
            { labels: ['total'], value: format((parseFloat(taxPercentage) || 0).toFixed(2), 'normal', null), type: 'percentage' }
        ]
	]
}

const mapper = (json, card, details) => {
    const name = json.invoice_id || json.invoice_line_id || json.sales_invoice__id || json.sales_invoice_line__id || json.purchase_invoice_line_id || json.purchase_invoice__id || json.purchase_invoice_line__id || json.stock_movement_line_id || json.stock_movement__id || json.stock_movement_line__id || json.transaction_line_id || json.transaction__id || json.transaction_line__id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
        details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}