import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		d01,
		l01,
		f01,
		f02,
		f07,
		f08
	} = json

	return [
		[
			{ labels: ['taxonomy'], value: d01, type: 'string', basis: '8%' }

		],
		[
			{ labels: ['account'], value: I18n.translate`${l01}`, type: 'string' }

		],
		[
			{ labels: ['debit_period'], value: format(f02), type: 'currency' }

		],
		[
			{ labels: ['credit_period'], value: format(f01), type: 'currency' }

		],
		[
			{ labels: ['debit_balance'], value: format(f08), type: 'currency' }

		],
		[
			{ labels: ['credit_balance'], value: format(f07), type: 'currency' }

		]
	]
}

const header = [
	{ label: ['Account Id'], align: 'left', basis: '8%' },
	{ label: ['Description'], align: 'left' },
	{ label: ['Period Debit'], align: 'right' },
	{ label: ['Period Credit'], align: 'right' },
	{ label: ['Debit Balance'], align: 'right' },
	{ label: ['Credit Balance'], align: 'right' }
]

const mapper = (json, card) => {
	return {
		...json,
		id: json.d01,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance',
			appendFilters: ['a01'],
			filter: [{ "filters": [{ "negate": false, "value": json.d01 }], "column": "account_id" }]
		}
	}
}

export default {
	map: mapper,
	header
}