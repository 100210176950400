const mapCard = (json) => {
	const {
		taxRegistrationNumber,
		description,
		fiscalYears,
		active,
		organization: { name: organizationName } = {},
	} = json
	return [
		[
			{ labels: ['tax_registration_number'], value: taxRegistrationNumber, type: 'string', basis: '10%' }
		],
		[
			{ labels: ['name'], value: description, type: 'string', basis: '50%' }
		],
		[
			{ labels: ['organization'], value: organizationName, type: 'string' }
		],
		[
			{ labels: ['fiscalYears'], value: fiscalYears.sort((a, b) => b - a).join(', ') || 'N/A', type: 'string' }
		],
		[
			{ labels: ['active'], value: active ? "Yes" : "No", type: 'string', basis: '10%', align: 'center' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ['Tax ID'], align: 'left', basis: '10%' },
	{ label: ['Name'], align: 'left', basis: '50%' },
	{ label: ['Organization'], align: 'left' },
	{ label: ['FiscalYear'], align: 'left' },
	{ label: ['Active'], align: 'center', basis: '10%' },
]

export default {
	map: mapper,
	header
}