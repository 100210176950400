import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { GlobalProvider as ColbiUIProvider, useGlobalValue } from 'colbi_web_ui/lib/state/GlobalProvider'
import AsideMenu from 'colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu'
import Header from 'colbi_web_ui/lib/components/layout/Header/Header'

import { GlobalProvider } from '../../state/globalProvider'
import I18n from '../../I18n'
import { mapProperties, stringifyFilter } from 'colbi_web_ui/lib/utils'
import { logout, pushModal, pushDialog, popModal, uploadAction, toggleMenu, setLocale, auditNoteSubmit, downloadAction, removeModal } from '../../store/actions'
import { List } from 'colbi_web_ui/lib/components'

import { LoadingIndicator } from 'colbi_web_ui/lib/components'

import { ReactComponent as logo } from '../../assets/logo.svg'

import locales from '../../I18n/locales.json'
import theme from '../../style/_theme.json'
import proxy from '../../routes/proxy'

import styles from './Projects.module.sass'


const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>
				{children}
			</ColbiUIProvider>
		</GlobalProvider>
	)
}

const ProjectRow = ({ data }) => {

	const { i18n, uploadFiles } = useGlobalValue()

	return (
		<div className={styles['project']} onClick={() => uploadFiles()}>
			<div className={styles['project-row']}>
				<h5>{`${data.taxRegistrationNumber} - ${data.name} `}</h5>
				<div className={styles['status-container']}><small className={`${styles['status']} ${styles[`status-${data.status}`]}`}>{i18n(`status-${data.status}`)}</small></div>
				<small className='align-right'>{` ${data.fiscalYears.join(", ")} `}</small>
			</div>
		</div>
	)
}

const renderOrganization = (organization, i18n, params) => {
	const projects = (organization.projects || []).sort(({ name: a }, { name: b }) => (a.localeCompare(b))).map(project => {
		const firstFiscalYear = project.fiscalYears.slice(-1)[0]
		return ({
			...project,
			to: `/${project.id}${firstFiscalYear ? `/${firstFiscalYear}` : ''}`
		})
	})
	return (
		<List
			modifiers={['--row-style-alternate']}
			header={[
				{ label: [`${i18n`TRN`} - ${i18n`Project`}`] },
				{ label: [i18n`Status`], columnName: "period", align: 'center' },
				{ label: [i18n`Fiscal Years`], columnName: "period", align: 'right' }
			]}
			items={projects}
			card={ProjectRow}
		/>
	)
}

const Projects = (props) => {
	const {
		user,
		fetch,
		match,
		setLocale,
		logout,
		history,
		reload,
		location,
		pushModal,
		pushDialog,
		uploads,
		uploadFiles,
		submitAuditNote,
		download
	} = props

	if (!user) {
		return <Redirect to={'/login'} push />
	}

	if (user && parseInt(user.mysqlPasswordReset)) {
		return <Redirect to={'/reset_password'} />
	}

	const locale = match.params.locale || 'en'
	const i18n = I18n.use(locale)
	setLocale(locale)

	const queryParams = parse(location.search) || {}
	const routeParams = match.params || {}
	const params = {
		...queryParams,
		...routeParams
	}

	const goto = (page, filters, anchor) => {
		const pageUrl = page ? (typeof page.join === 'function' ? page.join('/') : page).replace(/\/+/g, '/') : null
		const urlFilters = filters ? stringifyFilter(filters) : ''
		const localePrefixed = pageUrl ? `${locale ? `/${locale}${!pageUrl.match(/^\//) ? '/' : ''}` : ''}` : null
		const gotoPage = pageUrl ? `${localePrefixed}${pageUrl}${urlFilters.length ? `?filter=${urlFilters}` : ''}` : `${location.pathname}?filter=${urlFilters}${anchor ? `#${anchor}` : ''}`
		history.push(gotoPage)
	}

	const results = mapProperties({ organizations: 'query(0).list' }, fetch.results, fetch.status, Projects.queries(), {}, {}, i18n, locale)
	const organizations = (results.organizations || [])
		.sort(({ name: a }, { name: b }) => (a.localeCompare(b)))


	if (organizations && organizations.length === 1 && organizations[0].projects.length === 1) {
		const project = organizations[0].projects[0]
		const firstFiscalYear = project.fiscalYears.slice(-1)[0]
		return <Redirect to={`/${locale}/${project.id}${firstFiscalYear ? `/${firstFiscalYear}` : ''}`} push />
	}

	if (!match.params.organizationId && organizations && organizations.length) {
		return <Redirect to={`/${locale}/organizations/${organizations[0].id}`} />
	}
	const currentOrganization = organizations
		.filter(({ id }) => id === params.organizationId)[0] || {}
	const children = match.params.organizationId && renderOrganization((currentOrganization), i18n, params)
	const navigation = (organizations || []).map((organization) => ({
		name: organization.name,
		to: `/organizations/${organization.id}`,
		exact: true
	}))

	return (
		<Provider
			user={user}
			proxy={proxy}
			theme={theme}
			uploads={uploads}
			actions={{
				pushModal,
				pushDialog,
				popModal,
				uploadFiles,
				download,
				submitAuditNote,
				removeModal,
				logout,
				setLocale: (locale) => {
					history.push(`/${locale}${params.projectId ? `/${params.projectId}` : ''}${params.fiscalYear ? `/${params.fiscalYear}` : ''}`)
				},
				goto
			}}
			i18n={i18n}
			locale={locale || locales[0].id}
			locales={locales}
			reload={reload}
			params={params}
			history={history}
			location={location}
		>
			<aside className={styles['aside']}>
				<AsideMenu
					logo={logo}
					title={user.name}
					subtitle={i18n`Organizations`}
					navigation={navigation}
					organizations={organizations}
				/>
			</aside>
			<main className={styles['main']}>
				<Header
					className={styles['header']}
					title={currentOrganization.name && [currentOrganization.name, i18n`Projects`]}
					settingsAction
					navigation={navigation}
					organizations={organizations}
				/>
				{fetch.activity && <LoadingIndicator className={styles['loading-indicator']} />}
				<div className={styles['content']}>{children}</div>
			</main>
		</Provider>
	)
}

Projects.queries = () => ([
	{
		resource: 'organizations',
		body: 'id,name,projects{id,name,fiscalYears,status,taxRegistrationNumber}'
	}
])

export default connect(
	({ user, fetch, menu, uploads }) => (
		{
			user,
			fetch,
			uploads
		}
	),
	{
		logout,
		pushDialog,
		pushModal,
		uploadFiles: uploadAction,
		download: downloadAction,
		submitAuditNote: auditNoteSubmit,
		popModal,
		toggleMenu,
		setLocale,
		removeModal
	}
)(withRouter(Projects))
