import I18n from '../../I18n'
import { format, date } from "../../utils"

const mapDetails = (json) => {
	const {
		workDocumentDescription,
		creditAmount,
		debitAmount,
		settlementAmount,
		productRowKey,
		customer,
		workDate,
		documentNumber,
		workType,
		productCode,
		quantity,
		unit,
		unitPrice,
		lineNumber,
		tax
	} = json

	const { code, type, country, percentage, amount, iecAmount, exempetion = {}, entity, base } = (tax || {})

	return [
		[
			{
				label: 'Line Number',
				value: lineNumber,
				type: 'number'
			},
			{ label: 'Document Type',value: workType, type: 'string' },
			{ label: 'Document Number', value: documentNumber, type: 'string' },
			{ label: 'Description', value: workDocumentDescription, type: 'string' },
			{ label: 'Document Date', value: date(workDate), type: 'date' },
		],
		[
			{ label: 'Unit Price', value: format(unitPrice), type: 'currency' },
			{ label: 'Settlement', value: format(settlementAmount), type: 'currency' },
			{ label: 'Debit Amount', value: format(debitAmount), type: 'currency' },
			{ label: 'Credit Amount', value: format(creditAmount), type: 'currency' },
		],
		[
			customer ? {
				label: 'Customer',
				value: {
					id: customer.id,
					name: customer.name,
					__typename: 'Customer'
				},
				type: 'object'
			} : null,

			{
				label: 'Product Code',
				value: {
					id: productRowKey,
					name: productCode,
					__typename: 'Product'
				},
				type: 'object'
			},
			{ label: 'Quantity', value: quantity, type: 'string' },
			{
				label: 'Unit of Measure',
				value: unit,
				type: 'string'
			},
		],
		tax ? {
			title: 'Tax Information',
			rows: [
				[
					{
						label: 'Tax Code',
						value: code || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Type',
						value: type || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Country',
						value: country || 'N/A',
						type: 'string'
					},
					{
						label: 'Percentage',
						value: format((parseFloat(percentage) || 0).toFixed(2), 'normal', null),
						type: 'percentage'
					},

				],
				[
					{
						label: 'Tax Date',
						value: date(tax.date),
						type: 'date'
					},
					{
						label: 'Tax Amount',
						value: format(amount),
						type: 'currency'
					},

					{
						label: 'IEC Amount',
						value: format(iecAmount),
						type: 'currency'
					},
				],
				[
					{
						label: 'Tax Exemption Code',
						value: exempetion.code || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Exemption Reason',
						value: exempetion.reason || 'N/A',
						type: 'string'
					},
				],
				[
					{
						label: 'Tax Entity',
						value: entity || 'N/A',
						type: 'string'
					},
					{
						label: 'Tax Base',
						value: base || 'N/A',
						type: 'string'
					},
				]
			]
		} : null,
	]
}

const mapCard = (json) => {
	const {
		lineNumber,
		description,
		productCode,
		tax,
		debitAmount,
		quantity,
		unit,
		unitPrice,
		creditAmount
	} = json

	return [
		[
			{ labels: ['description'], value: description, type: 'string' }
		],
		[
			{ labels: ['product'], value: `${ productCode }`, type: 'number' }
		],
		[
			{ labels: ['number'], value: `[${ lineNumber }]`, type: 'number' }
		],
		[
			{ labels: ['tax_percentage'], value: tax.percentage, type: 'percentage' }
		],
		[
			{ labels: ['quantity'], value: debitAmount && debitAmount > 0 && quantity ? I18n.translate`${ format(quantity, undefined, null) } x ${ format(unitPrice) }/${ unit }` : 'N/A', type: 'unity' }
		],
		[
			{ labels: ['quantity'], value: creditAmount && creditAmount > 0 && quantity ? I18n.translate`${ format(quantity, undefined, null) } x ${ format(unitPrice) }/${ unit }` : 'N/A', type: 'unity' }
		],
		[
			{ labels: ['debit_amount'], value: format(debitAmount), type: 'currency' }
		],
		[
			{ labels: ['credit_amount'], value: format(creditAmount), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {

	return {
		...json,
		name: '[' + json.lineNumber + '] ' + json.productCode,
		shortName: json.productCode,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Description"], align: 'left', columnName: "product_description", sortable: true },
	{ label: ["Product"], align: 'center' },
	{ label: ["[No]"], align: 'center' },
	{ label: ["Tax Percentage"], align: 'right' },
	{ label: ["Quantity Debit"], align: 'right' },
	{ label: ["Quantity Credit"], align: 'right' },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true }
]

export default {
	map: mapper,
	header
}