import App from '../../controllers/App/App'
import { rows, SectionTitle, inputs, List, Filters } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'

const queries = (args = {}) => {
    return [
        {
            resource: 'vat_data',
            args: {
                projectId: (args.projectId),
                fiscalYear: (args.fiscalYear),
                filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
                sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined
            },
            body: `__typename, items{...vat_data}, hasMore, total, filters(projectId:$projectId,fiscalYear:$fiscalYear) { prop, name, type, list }`
        }
    ].filter(r => !!r.resource)
}


const vatReportGenerate = (path, navigation, exact) => ({
    path,
    navigation,
    component: App,
    queries,
    exact,
    title: ['label(VAT Report)'],
    components: [
        {
            component: SectionTitle,
            props: {
                title: 'Generate VAT Report'
            }
        },
        {
            component: Filters,
            props: {
                filter: 'param(filter)',
                availableFilters: 'query(0).filters'
            }
        },
        {
            component: List,
            modifiers: ['--row-style-alternate'],
            props: {
                checkbox: true,
                card: rows.EntityCard,
                headerComponent: {
                    component: inputs.FileGenerator,
                    props: {
                        buttonTitle: 'Generate VAT Report'
                    }
                },
                header: 'query(0).header',
                sort: 'param(sort)',
                items: 'query(0).list',
                handleNoResults: true,
                metadata: 'query(0).metadata',
                page: 'param(page)',
                pageSize: 'param(pageSize)',
                pageDefault: 10,
                footer: 'query(0).footer'
            }
        }
    ]

})

export default vatReportGenerate;