import App from '../controllers/App/App'
import { StackLayout } from '../layouts'
import { List, headers, Tabs, Filters, DynamicReportsGrid, AuditSummaryWidget, rows, RawData } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'
import theme from 'colbi_web_ui/lib/style/_theme.json'

const camelize = (str) => {
	return (str || '').replace(/_([a-z])/g, (v) => (v[1].toUpperCase()))
}

const getEntityName = (entity) => {
	return (entity || '').substring(0, (entity || '').length - 1)
}

const queries = (args = {}) => {
	const entities = (args.entities || '').split('/')
	const entityPairs = (entities || []).reduce((pairs, current) => {
		if (!pairs[pairs.length - 1] || pairs[pairs.length - 1].length === 2) {
			pairs.push([])
		}
		if (pairs[pairs.length - 1]) {
			pairs[pairs.length - 1].push(current)
		}
		return pairs
	}, [])
	const entityIndex = entities.length % 2 === 0 ? entityPairs.length - 1 : entityPairs.length - 2
	const entityType = (entityPairs[entityIndex] || [])[0]
	const entityId = (entityPairs[entityIndex] || [])[1]
	const entity = getEntityName(entityType)
	const subEntityIndex = entities.length % 2 === 0 ? -1 : entityPairs.length - 1
	const subEntityType = (entityPairs[subEntityIndex] || [])[0]

	return [
		{
			resource: 'findings_for_record',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				referenceId: entityId
			},
			body: `__typename, items{ ...findings_for_record }, summary {...findings_summary}, hasMore`
		},
		{
			resource: entity,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				[`${camelize(entity)}Id`]: entityId
			},
			body: `...${entity}`
		},
		{
			resource: subEntityType,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
				sort: args.sort ? { type: '[SortInput!]', value: parseFilter(args.sort) } : undefined,
				page: parseInt(args.page, 10) || 0,
				parentId: entityId,
				parentType: entityType ? entityType.substring(0, entityType.length - 1) : null
			},
			downloadable: true,
			body: `__typename, items{...${subEntityType}}, hasMore, total, filters(projectId:$projectId,fiscalYear:$fiscalYear,parentId:$parentId,parentType:$parentType){ prop, name, type, list }`,
		},
	].filter(q => !!q.resource)
}


const entity = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: App,
	queries,
	exact,
	title: [title, 'label(query(1).__typename)', 'query(1).shortName'].filter(l => l),
	components: [
		{
			component: AuditSummaryWidget,
			modifiers: [
				'--root-margin-bottom-small'
			],
			props: {
				icon: 'audit-icon',
				title: 'label(Audit Results)',
				summary: 'query(0).metadata.summary',
				expanded: false,
				children: [{
					component: List,
					props: {
						basePath: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/rules',
						items: 'query(0).list',
						card: rows.EntityCard,
						header: 'query(0).header'
					}
				}]
			}
		},
		{
			component: RawData,
			props: {
				label: 'label(Raw Data)',
				data: 'query(1).raw'
			}
		},
		{
			component: headers.EntityHeader,
			modifiers: [
				'--root-margin-bottom-normal'
			],
			props: {
				type: 'query(1).__typename',
				data: 'query(1).details'
			}
		},
		{
			component: DynamicReportsGrid,
			modifiers: [
				'--root-margin-bottom-normal',
				'--cardholder-background-color-widget',
				'--cardholder-aspect-ratio-16x9'
			],
			props: {
				type: 'query(1).__typename',
				data: 'query(1).reports'
			}
		},
		{
			component: Filters,
			props: {
				filter: 'param(filter)',
				availableFilters: 'query(2).filters',
				stickyTop: parseInt(theme.layout.$headerHeight, 10) + 40,
				anchor: 'results'
			}
		},
		{
			component: Tabs,
			modifiers: [
				'--root-margin-top-small',
				'--root-margin-bottom-small',
			],
			props: {
				basePath: `${path}`,
				showCount: true,
				data: 'query(1).entities',
				labelKey: 'entity',
				routeKey: 'model',
				redirectEnabler: true,
				stickyTop: (Math.min(39, parseInt(theme.layout.$headerHeight, 10) || 39)),
			}
		}
	],
	routes: [
		{
			path,
			exact: true,
			queries: queries,
			component: StackLayout,
			components: [
				{
					component: List,
					modifiers: ['--row-style-alternate'],
					props: {
						basePath: path,
						header: 'query(2).header',
						sort: 'param(sort)',
						items: 'query(2).list',
						card: rows.EntityCard,
						handleNoResults: true,
						metadata: 'query(2).metadata',
						page: 'param(page)',
						status: 'status(0)',
						pageSize: 'param(pageSize)',
						pageDefault: 10,
						footer: 'query(2).footer'
					}
				}
			]
		}
	]
})

export default entity
