import { format, date } from "../../utils"

const mapDetails = (json) => {
	const {
		status,
		period,
		movementDate,
		type,
		user,
		customer,
		unicCode,
		statusReason,
		taxPayable,
		grossTotal,
		netTotal,
		registryDate,
		statusUser,
		statusDate,
		transaction,
		shipFrom,
		shipTo,
		supplier,
		taxEntity,
		startTime,
		endTime,
		eacCode,
		movementComments,
		currencyCode,
		exchangeRate,
		currencyAmount,
		atDocId
	} = json

	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Status', value: status, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Movement Date', value: date(movementDate), type: 'date' },
			{ label: 'Registry Date', value: date(registryDate), type: 'date' },
		],
		[
			{ label: 'Gross Total', value: format(grossTotal), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal), type: 'currency' },
			{ label: 'Tax Payable', value: format(taxPayable), type: 'currency' },
			{
				label: 'Currency Code',
				value: currencyCode || 'N/A',
				type: 'string'
			},
			{
				label: 'Exchange Rate',
				value: format(exchangeRate),
				type: 'currency'
			},
			{
				label: 'Currency Amount',
				value: format(currencyAmount),
				type: 'currency'
			},
		],
		[
			{
				label: 'Entity',
				value: taxEntity,
				type: 'string'
			},
			{ label: 'User', value: user, type: 'string' },
			{ label: 'Status User', value: statusUser, type: 'string' },
			{ label: 'Status Date', value: date(statusDate), type: 'date' },
			{
				label: 'Status Reason',
				value: statusReason || 'N/A',
				type: 'string'
			},
			customer ? { label: 'Customer', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			supplier ? { label: 'Supplier', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null,
			transaction ? { label: 'Transaction', value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object' } : null,
			{
				label: 'Document Unic Code',
				value: unicCode || 'N/A',
				type: 'string'
			},
			{
				label: 'Movement Start Time',
				value: date(startTime),
				type: 'date'
			},
			{
				label: 'Movement End Time',
				value: date(endTime),
				type: 'date'
			},
			{
				label: 'EAC Code',
				value: eacCode || 'N/A',
				type: 'string'
			},
			{
				label: 'AT Document Code',
				value: atDocId || 'N/A',
				type: 'string'
			},
			{
				label: 'Movement Comments',
				value: movementComments || 'N/A',
				type: 'string'
			},
		],
		shipFrom ? {
			title: 'Ship From',
			rows: [
				[
					{ label: 'Address Details', value: shipFrom.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipFrom.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipFrom.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipFrom.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipFrom.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipFrom.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipFrom.country || 'N/A', type: 'string' }
				]
			]
		} : null,
		shipTo ? {
			title: 'Ship To',
			rows: [
				[
					{ label: 'Address Details', value: shipTo.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipTo.street || 'N/A', type: 'string' },
					{ label: 'Number', value: shipTo.number || 'N/A', type: 'string' },
					{ label: 'City', value: shipTo.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipTo.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipTo.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipTo.country || 'N/A', type: 'string' }
				]
			]
		} : null,
		supplier ? {
			title: 'Supplier',
			rows: [
				[
					{ label: 'Supplier Id', value: { id: supplier.id, name: supplier.supplierId, __typename: 'Supplier' }, type: 'object' },
					{ label: 'Company Name', value: supplier.companyName || 'N/A', type: 'string' },
					{ label: 'Tax Id', value: supplier.taxId || 'N/A', type: 'string' },
					{ label: 'City', value: supplier.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: supplier.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: supplier.region || 'N/A', type: 'string' },
					{ label: 'Country', value: supplier.country || 'N/A', type: 'string' }
				]
			]
		} : null
	]
}

const mapCard = (json) => {
	const {
		number,
		numberOfLines,
		series,
		serialNumber,
		status,
		period,
		movementDate,
		type,
		user,
		customer,
		supplier,
		taxPayable,
		grossTotal,
		netTotal
	} = json

	return [
		[
			{ labels: ['ref'], value: `${ number }`, type: 'string' }
		],
		[
			{ labels: ['number'], value: `[${ serialNumber }]`, type: 'number' }
		],
		[
			{ labels: ['number_of_lines'], value: `(${ numberOfLines })`, type: 'number' }
		],
		[
			{ labels: ['series'], value: `${ series }`, type: 'number' }
		],
		[
			{ labels: ['serial_number'], value: `${ serialNumber }`, type: 'number' }
		],
		[
			{ labels: ['status'], value: `${ status }`, type: 'number' }
		],
		[
			{ labels: ['user'], value: user, type: 'number', mobileHidden: true }
		],
		[
			{
				labels: ['type'],
				value: type,
				type: 'number',
				mobileHidden: true
			}
		],
		customer ? [
			{
				labels: ['customer'],
				value: {
					id: customer.id,
					name: `(C) ${customer.name}`,
					__typename: 'Customer'
				},
				type: 'object',
				mobileHidden: true
			}
		] : supplier ? [
			{
				labels: ['supplier'],
				value: {
					id: supplier.id,
					name: `(acronym_supplier) ${supplier.name}`,
					__typename: 'Supplier'
				},
				type: 'object',
				mobileHidden: true
			}
		]: [{
			mobileHidden: true
		}],
		[
			{ labels: ['period'], value: period, type: 'number' }
		],
		[
			{ labels: ['date'], value: date(movementDate), type: 'date' }
		],
		[
			{ labels: ['tax'], value: format(taxPayable), type: 'currency', mobileHidden: true }
		],
		[
			{ labels: ['net'], value: format(netTotal), type: 'currency' }
		],
		[
			{ labels: ['gross'], value: format(grossTotal), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.number,
		shortName: json.number,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Ref"], align: 'left', columnName: "document_number", sortable: true },
	{ label: ["[No]"], align: 'center' },
	{ label: ["(Lines)"], align: 'center' },
	{ label: ["Series"], align: 'center' },
	{ label: ["Serial Number"], align: 'center' },
	{ label: ["Status"], align: 'center' },
	{
		label: ["User"],
		align: 'center',
		mobileHidden: true
	},
	{
		label: ["Type"],
		align: 'center',
		mobileHidden: true
	},
	{ label: ["(C) Customer / (S) Supplier"], align: 'left', mobileHidden: true },
	{ label: ["Period"], align: 'center', columnName: "period_raw", sortable: true },
	{ label: ["Date"], align: 'center' },
	{ label: ["Tax Payable"], align: 'right', mobileHidden: true, columnName: "document_totals_tax_payable", sortable: true },
	{ label: ["Net Total"], align: 'right', columnName: "document_totals_net_total", sortable: true },
	{ label: ["Gross Total"], align: 'right', columnName: "document_totals_gross_total", sortable: true }
]

export default {
	map: mapper,
	header
}