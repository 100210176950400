import { format, date } from "../../utils"

const mapDetails = (json) => {
	const {
		user,
		journal,
		period,
		glPostingDate,
		registryDate,
		debit,
		credit,
		type,
		description,

		archivalNumber,
		taxEntity,
		customer,
		supplier
	} = json

	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			journal ? { label: 'Journal', value: journal.name, type: 'string' } : null,
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Post Date', value: date(glPostingDate), type: 'date' },
			{ label: 'Registry Date', value: date(registryDate), type: 'date' },
			{ label: "Description", value: description, type: 'string' }
		],
		[
			{ label: 'Debit', value: format(debit), type: 'currency' },
			{ label: 'Credit', value: format(credit), type: 'currency' }
		],
		[
			{ label: 'User', value: user, type: 'string' },
			{ label: 'Archival', value: archivalNumber, type: 'string' },
			{ label: 'Tax Entity', value: taxEntity, type: 'string' },
			customer ? { label: 'Customer', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			supplier ? { label: 'Supplier', value: { id: supplier.id, name: supplier.name, __typename: 'Supplier' }, type: 'object' } : null
		]
	]
}

const mapCard = (json) => {

	const {
		transactionId,
		numberOfLines,
		description,
		journal,
		period,
		glPostingDate,
		debit,
		credit,
		type
	} = json

	return [
		[
			{ labels: ['name'], value: `${ transactionId }`, type: 'string' }
		],
		[
			{ labels: ['description'], value: `${ description }`, type: 'string', mobileHidden: true }
		],
		[
			{ labels: ['type'], value: `${ type }`, type: 'number', mobileHidden: true }
		],
		[
			{ labels: ['lines'], value: `${ numberOfLines }`, type: 'number', mobileHidden: true }
		],
		[
			{ labels: ['journal'], value: journal ? journal.name : null, type: 'string', mobileHidden: true }
		],
		[
			{ labels: ['period'], value: period, type: 'number' }
		],
		[
			{ labels: ['date'], value: date(glPostingDate), type: 'date' }
		],
		[
			{ labels: ['debit'], value: format(debit, 'normal'), type: 'currency' }
		],
		[
			{ labels: ['credit'], value: format(credit), type: 'currency' }
		]
	]
}

const mapper = (json, card, details) => {

	return {
		...json,
		name: json.transactionId,
		shortName: json.transactionId,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["ID"], align: 'left', columnName: "transaction_id", sortable: true },
	{ label: ["Description"], align: 'left', mobileHidden: true  },
	{ label: ["Type"], align: 'center', mobileHidden: true  },
	{ label: ["Lines"], align: 'center', mobileHidden: true  },
	{ label: ["Journal"], align: 'left', mobileHidden: true },
	{ label: ["Period"], align: 'center', columnName: "period", sortable: true },
	{ label: ["Date"], align: 'center' },
	{ label: ["Debit"], align: 'right', columnName: "debit_amount", sortable: true },
	{ label: ["Credit"], align: 'right', columnName: "credit_amount", sortable: true }
]

export default {
	map: mapper,
	header
}