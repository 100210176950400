import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		name,
		category,
		subCategory,
		currency
	} = json

	return [
		[
			{ labels: ['category'], value: I18n.translate`${category}`, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['subcategory'], value: I18n.translate`${subCategory}`, type: 'string' }
		],
		[
			{ labels: ['name'], value: I18n.translate`${name}`, type: 'string', basis: '40%' }
		],
		[
			{ labels: ['currency'], value: format(currency), type: 'currency' }
		]
	]
}

const header = [
	{ label: ['Level 01'], align: 'left', basis: '20%' },
	{ label: ['Level 02'], align: 'left' },
	{ label: ['Description'], align: 'left', basis: '40%' },
	{ label: ['Value'], align: 'right' }
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance_taxonomy',
			appendFilters: ['a01'],
			filter: [{ "filters": json.taxonomyCode.map(elem => ({ "negate": false, "value": elem })), "column": "d01" }]
		}
	}
}

const mapFilters = (filters) => {
	return filters.map((filter) => {
		if (filter.prop === 'd_02') {
			return {
				...filter,
				listMapper: {
					'01AMLP': I18n.translate`Non-Current Assets`,
					'02ACP': I18n.translate`Current Assets`,
					'03EQUITY': I18n.translate`Equity`,
					'04LIABILITIES_MLP': I18n.translate`Non-Current Liability`,
					'05LIABILITIES_CP': I18n.translate`Current Liability`,
					'02RAD': I18n.translate`Operating income before depreciation`,
					'02RO': I18n.translate`Operating Income`,
					'02RAI': I18n.translate`Earning before tazes`,
					'02RLP': I18n.translate`Net result for the year`,
					'01ASSETS': I18n.translate`Current Assets`,
				}
			}
		}

		return filter
	})
}

export default {
	map: mapper,
	mapFilters,
	header
}