import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { parse } from 'query-string'
import { connect } from 'react-redux'

import I18n from '../../I18n'
import { GlobalProvider as ColbiUIProvider } from 'colbi_web_ui/lib/state/GlobalProvider'
import { ActionButton, Dropdown, TextInput } from 'colbi_web_ui/lib/components/inputs'

import { login } from '../../store/actions'

import styles from './Login.module.sass'
import { GlobalProvider } from '../../state/globalProvider'

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider { ...props }>
			<ColbiUIProvider { ...props }>
				{ children }
			</ColbiUIProvider>
		</GlobalProvider>
	)
}
class Login extends Component {
	state = {
		logginIn: false,
		username: '',
		password: '',
		hideWelcome: false,
		hideForgot: true,
		language: { id: "en", name: "EN" }
	}

	handleSubmit(e) {
		e.preventDefault()
		const { username, password } = this.state
		this.props.login(username, password)
	}

	render() {
		const { username, password, language, hideWelcome, hideForgot } = this.state
		const { user, loginError, location } = this.props
		const locale = 'en'
		const i18n = I18n.use(locale)

		if (user) {
			const { redirect } = parse(location.search)

			return <Redirect to={ (redirect || parseInt(user.mysqlPasswordReset)) ? `/reset_password` : `/${ language.id }/` } />
		}

		return (
			<Provider
				{ ...{
					i18n,
					location
				}}
			>
				<div className={ styles['root'] }>
					<div className={ styles['login'] }>
						<div className={ styles['login-container'] }>
							<div className={ styles['details'] }>
								<h1 className={ styles['title'] }>Sign in to Col.bi</h1>
								{ loginError && <div className={ styles['error'] }>{ loginError }</div> }
								<form className={ styles['form'] } onSubmit={ this.handleSubmit.bind(this) }>
									<TextInput
										type="text"
										autoComplete="off"
										autoCorrect="off"
										autoCapitalize="off"
										spellCheck="false"
										name={ i18n`Username` }
										placeholder={ i18n`Your username` }
										required
										value={ username }
										onChange={ (e) => {
											this.setState({ username: e.target.value })
										} }
									/>
									<TextInput
										type="password"
										name={ i18n`Password` }
										placeholder={ i18n`Your password` }
										required
										value={ password }
										onChange={ (e) => {
											this.setState({ password: e.target.value })
										} }
									/>
									<div className={ styles['login-actions'] }>
										<Dropdown
											value={ this.state.language }
											placeholder={ '' }
											options={ [{ id: "en", name: "EN" }, { id: "pt", name: "PT" }] }
											i18n={ i18n }
											className={ styles['dropdown'] }
											onChange={ (e) => {
												this.setState({ language: e.target.value })
											} }
										/>
										<ActionButton icon='login-arrow' primary className={ styles['action-button'] } type="submit">Sign in</ActionButton>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className={ styles['sidebar'] }>
						<div className={ styles['background'] } />
						<div className={ ` ${ styles["container-sidebar"] } ${ hideWelcome ? styles['hide'] : 'show' } ` }>
							<h2 className={ styles['title'] }>Welcome</h2>
							<p className={ styles['subtitle'] }>Enter your personal details and start using the Col.bi application.</p>
							<p
								className={ styles['item-link'] }
								onClick={ () => {
									this.setState({
										hideWelcome: true,
										hideForgot: false
									})
								} }
							>Forgot your password?</p>
						</div>
						<div className={ ` ${ styles["container-sidebar"] } ${ hideForgot ? styles['hide'] : 'show' } ` }>
							<h2 className={ styles['title'] }>Forgot your password?</h2>
							<p className={ styles['subtitle'] }>You should ask your system admin to reset your password.</p>
							<p
								className={ styles['item-link'] }
								onClick={ () => {
									this.setState({
										hideWelcome: false,
										hideForgot: true
									})
								} }
							>Understood!</p>
						</div>
					</div>
				</div>
			</Provider>
		)
	}
}

export default connect(
	({ user, loginError }) => (
		{
			user,
			loginError
		}
	),
	{
		login
	}
)(Login)
