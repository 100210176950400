import App from '../controllers/App/App'
import { SectionTitle, List, rows } from 'colbi_web_ui/lib/components'
import { parseFilter } from 'colbi_web_ui/lib/utils'

const queries = (args = {}) => ([
	{
		resource: 'rule_result',
		args: {
			projectId: (args.projectId),
			fiscalYear: (args.fiscalYear),
			id: (args.id),
			locale: (args.locale) || 'en',
			filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : { type: '[FilterInput!]', value: [] }, //
			page: parseInt(args.page, 10) || 0,
		},
		body: `	
		ruleIdentifier,
    	description,
    	lines(fiscalYear:$fiscalYear, projectId: $projectId, id: $id, locale: $locale, page: $page, filter: $filter){
			items { 
				__typename,
				id,
				message,
				messageParameter,
				referenceType,
				referenceId,
				severity,
				verified 
			}, hasMore, total
		}`,
		downloadable: true
	}
])

const findingRule = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: App,
	exact: true,
	queries,
	title: ['label(Audit)', title, 'param(fiscalYear)'],
	components: [
		{
			component: SectionTitle,
			props: {
				title: 'query(0).others.ruleIdentifier',
				subtitle: 'query(0).others.description'
			}
		},
		{
			component: List,
			modifiers: [
				'--root-margin-top-small',
				'--row-style-alternate'
			],
			props: {
				basePath: path + '/finding',
				items: 'query(0).list',
				card: rows.EntityCard,
				metadata: 'query(0).metadata',
				page: 'param(page)',
				status: 'status(0)',
				pageSize: 'param(pageSize)',
				pageDefault: 10,
				footer: 'query(0).footer',
				header: 'query(0).header'
			}
		}
	]
})

export default findingRule
