var regex = /(auto|scroll)/;

const parents = (node, ps) => {
	if (node.parentNode === null) { return ps }
	return parents(node.parentNode, ps.concat([node]))
};

const style = (node, prop) => {
	return getComputedStyle(node, null).getPropertyValue(prop)
};

const overflow = (node) => {
	return style(node, "overflow") + style(node, "overflow-y") + style(node, "overflow-x")
};

const scroll = (node) => {
	return regex.test(overflow(node))
};

const getScrollParent = (node) => {
	if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
		return;
	}

	var ps = parents(node.parentNode, [])

	for (var i = 0; i < ps.length; i += 1) {
		if (scroll(ps[i])) {
			return ps[i]
		}
	}

	return document.defaultView
}

export {
	getScrollParent
}
