import App from '../controllers/App/App'
import { SectionTitle, List, rows } from 'colbi_web_ui/lib/components'

const queries = (args = {}) => ([])

const items = [{
	__typename: "Report",
	id: 'trial_balance',
	card: [
		[{ value: 'Trial Balance' }]
	]
}, {
	__typename: "Report",
	id: 'trial_balance_taxonomy',
	card: [
		[{ value: 'Trial Balance Taxonomy' }]
	]
}, {
	__typename: "Report",
	id: 'balance_taxonomy',
	card: [
		[{ value: 'Balance Taxonomy' }]
	]
}, {
	__typename: "Report",
	id: 'profit_and_loss_taxonomy',
	card: [
		[{ value: 'Profit and Loss Taxonomy' }]
	]
}]

const reports = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: App,
	queries,
	exact,
	title: [title],
	components: [
		{
			component: SectionTitle,
			props: {
				title: ['label(Reports)', ' (', 'param(fiscalYear)', ')']
			}
		},
		{
			component: List,
			modifiers: [
				'--root-margin-top-small'
			],
			props: {
				items,
				card: rows.EntityCard
			}
		}
	]
})

export default reports
