const statusColor = {
	in_progress: 'lightBlue',
	submitted: 'gray',
	sending_to_inbox: 'lightBlue',
	uploaded: 'gray',
	checking_file: 'lightBlue',
	xsd_checking: 'lightBlue',
	saving_to_db: 'lightBlue',
	test_running: 'lightBlue',
	invalid: 'red',
	imported: 'green',
	canceled: 'yellow',
	deleting: 'yellow',
	deleted: 'yellow',
	error: 'red',
	ok: 'ok',
	with_warnings: 'yellow',
	without_warnings: 'green',
	not_executed: 'red'
}

export default statusColor