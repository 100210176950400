import { date } from '../../utils'

const severityRender = (type) => {
	switch (parseInt(type, 10)) {
		case 0:
			return 'Low'
		case 1:
			return 'Medium'
		case 2:
			return 'High'
		case 3:
			return 'Critical'
		default:
			return
	}
}

const mapDetails = (json) => {
	const {
		severity,
		referenceType,
		referenceId,
		createdAt,
		type,
		line,
		column,
		xmlBlock
	} = json

	let others = []

	if (type !== 'RulesFinding') {
		others = [
			[
				{ label: 'Line', value: line || 'N/A', type: 'string' },
				{ label: 'Column', value: column || 'N/A', type: 'string' }
			],
			[
				{ label: 'XML Block', value: xmlBlock || 'N/A', type: 'string' }
			]
		]
	}

	return [
		[
			{
				label: 'Severity',
				value: severityRender(severity),
				type: 'string'
			},
			{ label: 'Refers to', value: { id: referenceId, name: referenceType.substr(6), __typename: referenceType.substr(6) }, type: 'object' },
			{
				label: 'Creation Date',
				value: date(createdAt),
				type: 'date'
			}
		],
		[],
		...others
	]

}



const mapper = (json) => {
	return {
		...json,
		name: '[' + json.lineNumber + '] ' + json.productCode,
		shortName: json.productCode,
		details: mapDetails(json)
	}
}


export default {
	map: mapper
}