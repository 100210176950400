import React from 'react'
import { connect } from 'react-redux'

import { popDialog } from '../../../store/actions'

import Modal from 'colbi_web_ui/lib/components/Modal/Modal'
import { views } from 'colbi_web_ui/lib/components'

import styles from './DialogController.module.sass'

const DialogController = ({ dialogs, popDialog, i18n }) => {
	return dialogs && dialogs.length ? dialogs.map((dialog, index) => {
		const { data, id, title, view } = dialog
		const View = views[view]
		return View ? (
			<Modal key={ index }>
				<div className={ styles['backdrop'] } />
				<div className={ styles['dialog-window'] }>
					<div className={ styles['dialog-header'] }>
						<h5>{ title || i18n`Confirm` }</h5>
					</div>
					<div className={ styles['dialog-content'] }>
						<View
							data={ data }
							i18n={ i18n }
							confirm={ () => {
								popDialog(id, true)
							} }
							cancel={ () => {
								popDialog(id, false)
							} }
						/>
					</div>
				</div>
			</Modal>
		) : null
	}) : null
}

export default connect(
	({ dialog }) => (
		{
			dialogs: dialog
		}
	),
	{
		popDialog
	}
)(DialogController)
