import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { parse } from 'query-string'
import { connect } from 'react-redux'
import { logout, resetPassword } from '../../store/actions'
import { ActionButton, TextInput } from 'colbi_web_ui/lib/components/inputs'
import Icon from 'colbi_web_ui/lib/components/Icon'

import styles from './ResetPassword.module.sass'
import I18n from '../../I18n'

class ResetPassword extends Component {
	state = {
		logginIn: false,
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',

		//Validation with Password Strength Check
		disabled: true,
		status: {
			lowUpperCase: false,
			oneNumber: false,
			eightCharacter: false
		},
		errorPasswordHideClass: true,
		errorConfirmPasswordHideClass: true,
		result: {
			class: '',
			text: '',
		},
		passwordStrengthClass: '',
	}

	checkStrength(password) {
		let strength = 0;

		if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
			strength += 2;

			this.setState(prevState => ({
				status: {
					...prevState.status,
					lowUpperCase: true
				},
				errorPasswordHideClass: true
			}))
		} else {
			this.setState(prevState => ({
				status: {
					...prevState.status,
					lowUpperCase: false
				},
				errorPasswordHideClass: false
			}))
		}

		if (password.match(/([0-9])/)) {
			strength += 1;

			this.setState(prevState => ({
				status: {
					...prevState.status,
					oneNumber: true
				},
				errorPasswordHideClass: true
			}))

		} else {
			this.setState(prevState => ({
				status: {
					...prevState.status,
					oneNumber: false
				},
				errorPasswordHideClass: false
			}))
		}

		if (password.length > 9) {
			strength += 1;

			this.setState(prevState => ({
				status: {
					...prevState.status,
					eightCharacter: true
				},
				errorPasswordHideClass: true
			}))

		} else {
			this.setState(prevState => ({
				status: {
					...prevState.status,
					eightCharacter: false
				},
				errorPasswordHideClass: false
			}))
		}

		if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) { strength += 1; }

		//Password Strength:
		if (strength === 0) {
			this.setState({
				result: {
					class: '',
					text: ''
				},
				passwordStrengthClass: ''
			})
		} else if (strength < 3) {
			this.setState({
				result: {
					class: 'text-danger',
					text: 'Very Weak'
				},
				passwordStrengthClass: 'progress-bar-danger'
			})
			return 'Very Weak'
		} else if (strength === 3) {
			this.setState({
				result: {
					class: 'text-warning',
					text: 'Weak'
				},
				passwordStrengthClass: 'progress-bar-warning'
			})
			return 'Weak'
		} else if (strength === 4) {
			this.setState({
				result: {
					class: 'text-sucess-medium',
					text: 'Strength'
				},
				passwordStrengthClass: 'progress-bar-sucess-medium'
			})
			return 'Medium'
		} else if (strength === 5) {
			this.setState({
				result: {
					class: 'text-success',
					text: 'Very Strength'
				},
				passwordStrengthClass: 'progress-bar-success'
			})
			return 'Strong'
		}
	}

	handleSubmit(user, e) {
		const { id, organizationId, role } = user
		const { oldPassword, newPassword, confirmPassword } = this.state

		e.preventDefault()
		this.props.resetPassword(oldPassword, newPassword, confirmPassword, id, organizationId, role)
	}

	handlePassword(e) {
		const { value } = e.target

		this.setState({ newPassword: value })

		if (this.checkStrength(value) !== 'Medium' || this.checkStrength(value) !== 'Strong') {
			this.setState({ disabled: true })
		}

		if (this.state.confirmPassword === value) {
			this.setState({ errorConfirmPasswordHideClass: true })

			if (this.checkStrength(value) === 'Strong' || this.checkStrength(value) === 'Medium') {
				this.setState({ disabled: false })
			}
		}
	}

	handleConfirmPassword(e) {
		const { newPassword } = this.state
		const confirmPassword = e.target.value

		this.setState({ confirmPassword })

		if (newPassword === confirmPassword) {
			this.setState({ errorConfirmPasswordHideClass: true })

			if (this.checkStrength(newPassword) === 'Medium' || this.checkStrength(newPassword) === 'Strong') {
				this.setState({ disabled: false })
			}

		} else {
			this.setState({
				disabled: true,
				errorConfirmPasswordHideClass: false
			})
		}
	}

	render() {
		const {
			oldPassword,
			newPassword,
			confirmPassword,
			disabled,
			status,
			errorPasswordHideClass,
			errorConfirmPasswordHideClass,
			//result,
			//passwordStrengthClass 
		} = this.state

		const { lowUpperCase, oneNumber, eightCharacter } = status

		const { user, resetPasswordError, location } = this.props
		const { redirect } = parse(location.search)

		const locale = 'en'
		const i18n = I18n.use(locale)

		if (!user) {
			return <Redirect to={'/login'} push />
		}

		if (user && !parseInt(user.mysqlPasswordReset)) {
			return <Redirect to={redirect || `/`} />
		}

		return (
			<div className={styles['root']}>
				<div className={styles['main']}>
					<div className={styles['login-container']}>
						<div className={styles['details']}>
							<h1 className={styles['title']}>Reset Password</h1>
							{resetPasswordError && <div className={styles['error']}>{resetPasswordError}</div>}
							<form className={styles['form']} onSubmit={this.handleSubmit.bind(this, user)}>
								<label>
									<TextInput
										type="password"
										name={i18n`Old password`}
										placeholder=''
										required
										value={oldPassword}
										onChange={(e) => {
											this.setState({ oldPassword: e.target.value })
										}}
									/>
								</label>
								<label>
									<TextInput
										type="password"
										name={i18n`New password`}
										placeholder=''
										required
										value={newPassword}
										onChange={(e) => {
											this.handlePassword(e)
										}}
									/>
									<div>
										<small className={errorPasswordHideClass ? styles['hide'] : styles['msg-input-error']}>
											{i18n`Enter a strong password. Check the requirements.`}
										</small>
									</div>
								</label>
								<label>
									<TextInput
										type="password"
										name={i18n`Confirm password`}
										placeholder=''
										required
										value={confirmPassword}
										onChange={(e) => { this.handleConfirmPassword(e) }}
									/>
									<div>
										<small className={errorConfirmPasswordHideClass ? styles['hide'] : styles['msg-input-error']}>
											{i18n`Passwords don't match`}
										</small>
									</div>
								</label>
								<div className={styles['reset-actions']}>
									<button className={styles['logout-button']} onClick={this.props.logout}>
										<small><strong>Another user account</strong></small>
									</button>
									<ActionButton
										primary
										className={styles['action-button']}
										type="submit"
										disabled={disabled}>Reset</ActionButton>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={styles['aside']}>
					<h2 className={styles['title']}>Instructions</h2>
					{user.expired && <p className={styles['msg-expired']}>Your password has expired, create a new password, please.</p>}
					<p className={styles['subtitle']}>Minimum Requirements:</p>
					<div className={styles["wrap-strength-password"]}>
						{/* <p>Password Strength: <span id="result" className={ Class ? styles[Class] : undefined }>{text}</span></p>
						<div className={styles["progress"]}>
							<div className={`${styles["progress-bar"]} ${passwordStrengthClass && styles[passwordStrengthClass]}`} role="progressbar"></div>
						</div> */}
						<ul className={styles["list-unstyled"]}>
							<li className={`${lowUpperCase ? styles['text-success'] : 'text'} `}>
								{lowUpperCase ? <Icon name="success" /> : <Icon name="danger" />}
								<span>1 lowercase and 1 uppercase</span>
							</li>
							<li className={`${oneNumber ? styles['text-success'] : 'text'}`}>
								{oneNumber ? <Icon name="success" /> : <Icon name="danger" />}
								<span>1 number (0-9)</span>
							</li>
							<li className={`${eightCharacter ? styles['text-success'] : 'text'}`}>
								{eightCharacter ? <Icon name="success" /> : <Icon name="danger" />}
								<span>At least 10 Character</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(
	({ user, resetPasswordError }) => (
		{
			user,
			resetPasswordError
		}
	),
	{
		logout,
		resetPassword
	}
)(ResetPassword)
