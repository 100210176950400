import App from '../controllers/App/App'
import { AuditSummaryWidget, Grid, cards, Widget, charts } from 'colbi_web_ui/lib/components'

const queries = (args = {}) => {
	return args.fiscalYear ? [
		{
			resource: 'projectSummary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: '...projectSummary'
		},
		{
			resource: 'audit_summary',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'severity, count'
		},
		{
			resource: 'reports',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'report'
		},
		{
			resource: 'balance',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'report'
		},
		{
			resource: 'profitAndLoss',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'report'
		},
		{
			resource: `invoices_summary`,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear)
			},
			body: 'summary{ key, value }'
		},
		{
			resource: 'summary_report',
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
			},
			body: 'report',
			downloadable: true,
			downloadOnly: true
		}
	] : []
}


const components = (path) => {
	if (process.env.REACT_APP_LOCATION === 'SK') {
		return [
			{
				component: AuditSummaryWidget,
				modifiers: ['--root-margin-bottom-normal'],
				props: {
					icon: 'audit-icon',
					title: 'label(Audit Results)',
					summary: 'query(1).list',
					link: {
						to: 'audit',
						name: 'label(See All)'
					}
				}
			},
			{
				component: Grid,
				props: {
					columnsTemplate: [1, 1, 1],
					items: [
						{
							label: 'Sales Net Total',
							value: 'query(0).skSalesNetTotal',
							backgroundColor: '#2196F3',
							color: '#FFFFFF',
							icon: 'sales'
						},
						{
							label: 'Sales Tax Payable',
							value: 'query(0).skSalesTaxPayable',
							backgroundColor: '#74AF27',
							color: '#FFFFFF',
							icon: 'net-avg'
						},
						{
							label: 'Sales Gross Total',
							value: 'query(0).skSalesGrossTotal',
							backgroundColor: '#F44336',
							color: '#FFFFFF',
							icon: 'net-sales'
						}
					],
					cards: [cards.TotalCard]
				}
			},
			{
				component: Grid,
				props: {
					columnsTemplate: [1, 1, 1],
					items: [
						{
							label: 'Purchases Net Total',
							value: 'query(0).skPurchasesNetTotal',
							backgroundColor: '#FFC107',
							color: '#FFFFFF',
							icon: 'sales'
						},
						{
							label: 'Purchases Tax Payable',
							value: 'query(0).skPurchasesTaxPayable',
							backgroundColor: '#76afba',
							color: '#FFFFFF',
							icon: 'net-avg'
						},
						{
							label: 'Purchases Gross Total',
							value: 'query(0).skPurchasesGrossTotal',
							backgroundColor: '#9a9a9a',
							color: '#FFFFFF',
							icon: 'net-sales'
						}
					],
					cards: [cards.TotalCard]
				}
			}
		]
	} else {
		return [
			{
				component: AuditSummaryWidget,
				modifiers: ['--root-margin-bottom-normal'],
				props: {
					icon: 'audit-icon',
					title: 'label(Audit Results)',
					summary: 'query(1).list',
					link: {
						to: 'audit',
						name: 'label(See All)'
					}
				}
			},
			{
				component: Widget,
				modifiers: ['--root-margin-bottom-normal'],
				props: {
					icon: 'sales',
					title: 'label(Sales Summary)',
					summary: [
						{
							name: 'label(No. Invoices)',
							value: 'query(0).invoicesCount'
						},
						{
							name: 'label(No. Invoices Lines)',
							value: 'query(0).invoiceLinesCount'
						}
					],
					link: {
						to: 'invoices',
						name: 'label(Invoices)'
					},
					children: [
						{
							component: Grid,
							modifiers: ['--grid-border-inline'],
							props: {
								columnsTemplate: [1, 1],
								aspect: ['31x10'],
								items: [
									{
										data: 'query(5).list',
										labelsPosition: 'inside',
										stacked: true,
										summarized: true,
										links: [{
											to: ['param(projectId)', 'param(fiscalYear)', '/invoices'],
											filterColumn: 'period',
											category: 'invoice_type'
										}]
									},
									{
										data: [
											{
												name: 'label(Sales Gross Total)',
												value: 'currency(query(0).invoicesGrossTotal)',
												icon: 'gross-sales'
											},
											{
												name: 'label(Sales Net Total)',
												value: 'currency(query(0).invoicesNetTotal)',
												icon: 'net-sales'
											},
											{
												name: 'label(Invoice Avg Net)',
												value: 'currency(query(0).invoicesNetAverage)',
												icon: 'kpi-icon'
											},
											{
												name: 'label(Invoice Max Value)',
												value: 'currency(query(0).invoicesMaxValue)',
												icon: 'kpi-icon'
											}
										]
									}
								],
								cards: [charts.BarChart, charts.KpisLegend]
							}
						}
					]
				}
			},
			{
				component: Grid,
				modifiers: [
					'--grid-padding-none',
					'--root-margin-bottom-normal'
				],
				props: {
					columnsTemplate: [1, 1],
					items: [
						{
							title: 'label(Balance Sheet)',
							icon: 'balance-sheet',
							link: {
								to: 'link(/:projectId/:fiscalYear/reports/balance_taxonomy)',
								name: 'label(Report)'
							},
							children: [
								{
									component: Grid,
									modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
									props: {
										aspect: ['4x3'],
										columnsTemplate: [1, 1],
										items: [
											{
												data: 'query(3).report',
												link: {
													to: ['param(projectId)', 'param(fiscalYear)', '/reports/balance_taxonomy'],
													filterColumn: 'd_02',
													filters: [{
														column: 'a01',
														value: '13'
													}]
												}
											},
											{
												data: 'query(3).report'
											}
										],
										cards: [charts.BalanceSheetChart, charts.BalanceSheetChartLegend]
									}
								}
							]
						},
						{
							title: 'label(Profit and Loss)',
							icon: 'profit-loss',
							link: {
								to: 'link(/:projectId/:fiscalYear/reports/profit_and_loss_taxonomy)',
								name: 'label(Report)'
							},
							children: [
								{
									component: Grid,
									modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
									props: {
										aspect: ['4x3'],
										columnsTemplate: [1, 1],
										items: [
											{
												data: 'query(4).report',
												link: {
													to: ['param(projectId)', 'param(fiscalYear)', '/reports/profit_and_loss_taxonomy'],
													filterColumn: 'code',
													filters: [{
														column: 'a01',
														value: '13'
													}]
												}
											},
											{ data: 'query(4).report' }
										],
										cards: [charts.ProfitAndLossChart, charts.ProfitAndLossChartLegend]
									}
								}
							]
						}
					],
					cards: [Widget]
				}
			},
			{
				component: Grid,
				modifiers: [
					'--grid-padding-none',
					'--root-margin-bottom-normal'
				],
				props: {
					columnsTemplate: [1, 1],
					// aspect: ['16x9', '16x9'],
					items: [
						{
							title: 'label(Sales vs Accounting)',
							icon: 'comparation',
							children: [
								{
									component: Grid,
									modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
									props: {
										columnsTemplate: [1],
										aspect: ['21x9'],
										items: [
											{
												currency: true,
												data: ['query(2).report.2', 'query(2).report.3.result'],
												dataLabels: ['label(Commercial Documents Issued (NET))', 'label(Revenues (CTB))'],
												links: [
													{
														to: ['param(projectId)', 'param(fiscalYear)', '/invoices'],
														filterColumn: 'period'
													},
													{
														to: ['param(projectId)', 'param(fiscalYear)', '/reports/trial_balance_taxonomy'],
														filterColumn: 'a01',
														filter: 'query(2).report.3.filters'
													}, {}
												]
											}
										],
										cards: [charts.BarChart]
									}
								}
							]
						},
						{
							title: 'label(VAT Paid / Deducted)',
							icon: 'comparation',
							children: [
								{
									component: Grid,
									modifiers: ['--grid-border-inline', '--grid-margin-none', '--grid-padding-none'],
									props: {
										columnsTemplate: [1],
										aspect: ['21x9'],
										items: [
											{
												currency: true,
												data: ['query(2).report.4.result', 'query(2).report.5.result'],
												dataLabels: ['label(Paid VAT)', 'label(Deducted VAT)'],
												links: [
													{
														to: ['param(projectId)', 'param(fiscalYear)', '/transactions'],
														filterColumn: 'period',
														filter: 'query(2).report.4.filters'
													},
													{
														to: ['param(projectId)', 'param(fiscalYear)', '/transactions'],
														filterColumn: 'period',
														filter: 'query(2).report.5.filters'
													}, {}
												]
											}
										],
										cards: [charts.BarChart]
									}
								}
							]
						}
					],
					cards: [Widget]
				}
			},
			{
				component: Grid,
				modifiers: [
					'--root-margin-bottom-normal',
					'--cardholder-background-color-widget',
					'--mobile--cardholder-aspect-ratio-4x5'
				],
				props: {
					columnsTemplate: [1],
					aspect: ['31x10'],
					items: [
						{
							title: 'label(Number of documents per day)',
							data: 'query(2).report.6',
							icon: 'sales-stats',
							link: {
								to: ['param(projectId)', 'param(fiscalYear)', '/invoices'],
								filterColumn: 'invoice_date'
							}
						},
					],
					cards: [cards.SalesByWeekDay]
				}
			},
			{
				component: Grid,
				modifiers: [
					'--root-margin-bottom-normal',
					'--cardholder-background-color-widget',
					'--mobile--cardholder-aspect-ratio-4x5'
				],
				props: {
					columnsTemplate: [1, 1, 1],
					aspect: ['5x4', '5x4', '5x4'],
					items: [
						{
							title: 'label(Sales (Net))',
							data: ['query(2).report.0']
						},
						{
							title: 'label(Customers (Net))',
							data: ['query(2).report.1']
						},
						{
							title: 'label(Top sales by country)',
							data: 'query(2).report.7',
							icon: 'top'
						}
					],
					cards: [cards.TopsCard, cards.TopsCard, cards.SalesByCountryCard]
				}
			},
			{
				component: Grid,
				modifiers: [
					'--root-margin-bottom-normal',
					'--root-background-color-widget-background-color'
				],
				props: {
					columnsTemplate: [1, 1, 1],
					items: [
						{
							title: 'label(Customers/Suppliers)',
							items: [
								{
									name: 'label(Customers)',
									count: 'query(0).customersNifCount',
									link: `${path}/master_data/customers`
								},
								{
									name: 'label(Customers Code)',
									count: 'query(0).customersCount'
								},
								{
									name: 'label(Customers Countries)',
									count: 'query(0).customersCountriesCount'
								},
								{
									name: 'label(Suppliers)',
									count: 'query(0).suppliersNifCount',
									link: `${path}/master_data/suppliers`
								},
								{
									name: 'label(Suppliers Code)',
									count: 'query(0).suppliersCount'
								},
								{
									name: 'label(Suppliers Countries)',
									count: 'query(0).suppliersCountriesCount'
								}
							]
						},
						{
							title: 'label(Products)',
							items: [
								{
									name: 'label(Products)',
									count: 'query(0).productsCount',
									link: `${path}/master_data/products`
								},
								{
									name: 'label(Products Group)',
									count: 'query(0).productsGroupCount'
								},
								{
									name: 'label(Products Code)',
									count: 'query(0).productsCodeCount'
								},
								{
									name: 'label(Tax Codes)',
									count: 'query(0).taxCodesCount'
								}
							]
						},
						{
							title: 'label(Transactions)',
							items: [
								{
									name: 'label(Customers on Transactions)',
									count: 'query(0).customersInvoicesCount'
								},
								{
									name: 'label(Products on Transactions)',
									count: 'query(0).productsInvoicesCount'
								},
								{
									name: 'label(Invoices)',
									count: 'query(0).invoicesCount',
									link: `${path}/invoices`
								},
								{
									name: 'label(Invoice Lines)',
									count: 'query(0).invoiceLinesCount'
								},
								{
									name: 'label(Financial Accounts)',
									count: 'query(0).generalLedgersCount'
								},
								{
									name: 'label(Posting Accounts)',
									count: 'query(0).leafAccountsCount'
								},
								{
									name: 'label(GL Transactions)',
									count: 'query(0).transactionsCount',
									link: `${path}/transactions`
								},
								{
									name: 'label(GL Transaction Lines)',
									count: 'query(0).transactionLinesCount'
								}
							]
						}
					],
					cards: [cards.LinksCard]
				}
			}
		]
	}
}

const overview = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: App,
	queries,
	exact,
	title: [title],
	components: components(path)
})

export default overview
