const loginSuccess = (data) => (
	{
		type: 'LOGIN_SUCCESS',
		payload: {
			data
		}
	}
)
const loginError = (data) => (
	{
		type: 'LOGIN_ERROR',
		payload: {
			data
		}
	}
)
export const logoutSuccess = () => (
	{
		type: 'LOGOUT_SUCCESS'
	}
)

export const login = (username, password) => (
	(dispatch, getState) => {
		const queriesBody = {
			query: `mutation($u:String!,$p:String!){session(username:$u,password:$p){id,name,username,role,mysqlPasswordReset,expired}}`,
			variables: {
				u: username,
				p: password
			}
		}
		fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res
				.json()
				.then((res) => {
					const {data, errors} = res

					if(errors){
						return dispatch(loginError(errors[0].message))
					}
					
					if (data.session) {
						dispatch(loginSuccess(data.session))
					} else {
						dispatch(loginError('Invalid credentials. Make sure you are using your username and password.'))
					}
				})
				.catch(() => dispatch(loginError('Api error or Unknown error. Please try again later')))
			)
	}
)

export const logout = () => (
	(dispatch, getState) => {
		const queriesBody = {
			query: `mutation{deleteSession{ok}}`
		}
		if (queriesBody) {
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res.json().catch(() => ({})))
				.then((res) => {
					const data = res.data || {}
					if (data.deleteSession && data.deleteSession.ok) {
						try {
							window.localStorage.setItem("session", null)
						} catch (e) {
							console.error("100 - Local storage")
						}
						dispatch(logoutSuccess(data.session))
					} else if (res.errors) {
						dispatch(logoutSuccess())
					}
				})
				.catch(() => {
					dispatch(logoutSuccess())
				})
		}
	}
)



//RESET PASSWORD

const resetPasswordSuccess = (data) => (
	{
		type: 'RESET_SUCCESS',
		payload: {
			data
		}
	}
)

const resetPasswordError = (msg) => (
	{
		type: 'RESET_ERROR',
		payload: {
			msg: msg
		}
	}
)

export const resetPassword = (oldPassword, newPassword, confirmPassword, userId, organizationId, role) => (
	(dispatch) => {

		if (newPassword !== confirmPassword) {
			return dispatch(resetPasswordError('Your "new password" and "confirm password" does not match.'))
		}

		const queriesBody = {
			query: `mutation($p:String!,$n:String!){reset_password(oldPassword:$p,newPassword:$n){success}}`,
			variables: {
				p: oldPassword,
				n: newPassword,
			}
		}

		if (queriesBody) {
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
			.then((res) => res
				.json()
				.then((res) => {
					const {data, errors} = res

					if(errors){
						return dispatch(resetPasswordError(errors[0].message))
					}

					if ((data || {}).reset_password && (data || {}).reset_password.success) {
						try {
							window.localStorage.setItem("session", null)
							dispatch(resetPasswordSuccess(data.session))
						} catch (e) {
							console.error("100 - Local storage")
						}
						dispatch(logoutSuccess(data.session))
					} else {
						dispatch(resetPasswordError('Invalid password. Make sure your password is correct.'))
					}
				})
				.catch(() => dispatch(resetPasswordError('Api error or Unknown error. Please try again later')))
			)
		}
	}
)