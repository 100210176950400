/* global FileReader */
var from2 = require('from2')
var toBuffer = require('typedarray-to-buffer')

export default function (file, options) {
	options = options || {}
	var offset = options.offset || 0
	var chunkSize = options.chunkSize || 1024 * 1024 // default 1MB chunk has tolerable perf on large files
	var fileReader = new FileReader(file)

	var from = from2(function (size, cb) {
		if (offset >= file.size) return cb(null, null)
		fileReader.onloadend = function loaded(event) {
			var data = event.target.result
			if (data instanceof ArrayBuffer) data = toBuffer(new Uint8Array(event.target.result))
			cb(null, data)
		}
		var end = offset + chunkSize
		var slice = file.slice(offset, end)
		fileReader.readAsText(slice, options.encoding || 'utf8')
		offset = end
	})

	from.name = file.name
	from.size = file.size
	from.type = file.type
	from.lastModified = file.lastModified

	fileReader.onerror = function (err) {
		from.destroy(err)
	}

	return from
}
