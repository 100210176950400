import I18n from '../../I18n'
import { datetime } from '../../utils'

const mapCard = (json) => {
	const {
		name,
		flowStatus,
		status,
		uploadedAt,
		submitedBy,
	} = json

	return [
		[
			{ labels: ['name'], value: name, type: 'string', align: 'align-left', basis: '26%' }
		],
		[
			{ labels: ['user'], value: submitedBy, type: 'string', mobileHidden: true, basis: '10%' }
		],
		[
			{ labels: ['uploaded'], value: datetime(uploadedAt), type: 'datetime', mobileHidden: true, basis: '10%' }
		],
		[
			{ labels: ['status'], value: [status, I18n.translate`${status}`, flowStatus], type: 'progressbar', basis: '54%' }
		]

	]
}

const mapper = (json, card) => {
	return {
		...json,
		id: json.id,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: json.status === 'imported' ? `/:locale(pt|en)?/:projectId/repository/folders/${json.fiscalYear}` : `/:locale(pt|en)?/:projectId/repository/activity/status/${json.id}`
		}
	}
}

const header = [
	{ label: ['Name'], align: 'left', basis: '25.3%' },
	{ label: ['Uploaded by'], align: 'left', mobileHidden: true, basis: '9.3%' },
	{ label: ['Uploaded at'], align: 'center', mobileHidden: true, basis: '9.3%' },
	{ label: ['Status'], align: 'center', basis: '53.3%' }
]

export default {
	map: mapper,
	header
}