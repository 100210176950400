const header = [
    { label: ["First Name"], align: 'left' },
    { label: ["Last Name"], align: 'left' },
    { label: ["Telephone"], align: 'center' },
    { label: ["E-mail"], align: 'left' },
]

const mapCard = (json) => {
	const {
        contactPersonFirstName,
        contactPersonLastName,
        telephone,
        email
	} = json

	return [
        [
            { labels: ['firstName'], value: contactPersonFirstName, type: 'string' }
        ],
        [
            { labels: ['lastName'], value: contactPersonLastName, type: 'string' }
        ],
        [
            { labels: ['telephone'], value: telephone, type: 'number' }
        ],
        [
            { labels: ['email'], value: email, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
        shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}