import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { parse } from 'query-string'

import I18n from '../../I18n'
import locales from '../../I18n/locales.json'
import theme from '../../style/_theme.json'
import proxy from '../../routes/proxy'

import { mapProperties, hydrateRoute, number, parseFilter } from 'colbi_web_ui/lib/utils'
import { logout, pushModal, pushDialog, popModal, uploadAction, toggleMenu, setLocale, auditNoteSubmit, removeModal } from '../../store/actions'

import { List, Filters, LoadingIndicator } from 'colbi_web_ui/lib/components'
import AsideMenu from 'colbi_web_ui/lib/components/layout/AsideMenu/AsideMenu'
import Header from 'colbi_web_ui/lib/components/layout/Header/Header'

import { EntityCard } from 'colbi_web_ui/lib/components/rows'
import { FloatButton } from 'colbi_web_ui/lib/components/inputs'
import { GlobalProvider as ColbiUIProvider } from 'colbi_web_ui/lib/state/GlobalProvider'
import { GlobalProvider } from '../../state/globalProvider'
import ModalController from '../App/ModalController/ModalController'
import { ReactComponent as logo } from '../../assets/logo.svg'

// import Header from '../App/AppHeader/Header'
// import AsideMenu from '../App/AsideMenu/AsideMenu'
import navigation from './navigation'

import styles from './Settings.module.sass'

const tabs = [
	// {
	// 	section: '/',
	// 	label: 'General'
	// },
	{
		section: 'projects',
		label: 'Projects',
		createType: 'Project'
	},
	{
		section: 'users',
		label: 'Users',
		createType: 'User'
	},
	{
		section: 'rules',
		label: 'Rules',
		createType: 'Rule'
	}
]

const Provider = ({ children, ...props }) => {
	return (
		<GlobalProvider {...props}>
			<ColbiUIProvider {...props}>
				{children}
			</ColbiUIProvider>
		</GlobalProvider>
	)
}

const Settings = (props) => {
	const {
		user,
		fetch,
		match,
		setLocale,
		location,
		toggleMenu,
		logout,
		history,
		pushModal,
		popModal,
		menu,
		reload,
		pushDialog,
		uploads,
		uploadFiles,
		submitAuditNote
	} = props

	if (!user) {
		return <Redirect to={'/login'} push />
	}

	if (user && parseInt(user.mysqlPasswordReset)) {
		return <Redirect to={'/reset_password'} />
	}

	if (!(user.role === 's' || user.role === 'so')) {
		return null
	}

	const locale = match.params.locale || 'en'
	const i18n = I18n.use(locale)
	setLocale(locale)

	const queryParams = parse(location.search) || {}
	const routeParams = match.params || {}
	const params = {
		...queryParams,
		...routeParams
	}

	const data = mapProperties(
		{ items: 'query(0).list', header: 'query(0).header', footer: 'query(0).footer', metadata: 'query(0).metadata', page: 'param(page)', status: 'status(0)', pageSize: 'param(pageSize)', pageDefault: 10, filter: 'param(filter)', availableFilters: 'query(0).filters' },
		(fetch || {}).results,
		(fetch || {}).status,
		(Settings.queries && Settings.queries(params)) || [],
		props,
		params,
		i18n,
		locale
	)
	const currentSection = (match.params.section || '/')
	const floatCreateTypename = (tabs.find(({ section }) => section === currentSection) || {}).createType

	if (currentSection === '/') {
		const basePath = "/settings/:organizationId?"
		const hydrated = hydrateRoute(basePath, { ...params })
		return <Redirect to={`${hydrated}${tabs[0].section}`} replace />
	}

	return (
		<div className={styles['app']}>
			<Provider
				user={user}
				proxy={proxy}
				theme={theme}
				uploads={uploads}
				actions={{
					pushModal, pushDialog, popModal, uploadFiles, submitAuditNote, removeModal, logout,
					setLocale: (locale) => {
						history.push(`/${locale}${params.projectId ? `/${params.projectId}` : ''}${params.fiscalYear ? `/${params.fiscalYear}` : ''}`)
					},
				}}
				i18n={i18n}
				locale={locale || locales[0].id}
				locales={locales}
				reload={reload}
				params={params}
				history={history}
				location={location}
			>
				<div className={`${styles['aside']} ${menu === 'expanded' ? '' : styles['is-collapsed']}`}>
					<AsideMenu
						logo={logo}
						logout={logout}
						title={menu === 'expanded' ? i18n`Settings` : ''}
						navigation={navigation}
						locales={locales}
						i18n={i18n}
						locale={locale}
						history={history}
						expanded={menu === 'expanded'}
						params={{
							...match.params
						}}
					/>
				</div>
				<div className={styles['main']}>
					<Header
						className={styles['header']}
						logout={logout}
						navigation={navigation}
						locales={locales}
						i18n={i18n}
						locale={locale}
						search={false}
						history={history}
						params={{
							...match.params
						}}
						theme={theme}
						title={i18n`${match.params.section || ''}`}
						toggleMenu={toggleMenu}
						expanded={menu !== 'expanded'}
					/>
					<main className={`${styles['content'] || ''} ${styles['foreground'] || ''}`}>
						{fetch.activity && <LoadingIndicator className={`${styles['loading-indicator']} ${menu === 'expanded' ? '' : styles['small-margin']}`} />}
						<Filters
							filter={data.filter}
							availableFilters={data.availableFilters}
							stickyTop={theme}
							anchor='results'
						/>
						<List
							modifiers={['--root-margin-top-small', '--row-style-alternate']}
							{...(data || {})}
							card={EntityCard}
							onClick={(e) => {
								e.originalEvent.preventDefault()
								e.originalEvent.stopPropagation()
								const { id, card, __typename } = e.target.value || {}
								const title = (
									card && card[0] && card[0][0] ?
										card[0][0].value :
										''
								)
								const result = pushModal({
									title,
									view: `${__typename}Form`,
									args: { id }
								})
								result
									.then(() => {
										reload()
									})
									.catch(() => null)
							}}
						/>
						{floatCreateTypename ? <FloatButton
							className={styles['float-button']}
							icon={`new-${(floatCreateTypename || '').toLowerCase()}`}
							offset={30}
							onClick={() => {
								const result = pushModal({
									title: i18n`New ${floatCreateTypename}`,
									view: `${floatCreateTypename}Form`,
									data: { __typename: floatCreateTypename }
								})
								result
									.then(() => {
										reload()
									})
									.catch(() => null)
							}
							} /> : null}
					</main>
					<ModalController />
				</div>
			</Provider>
		</div>
	)
}

Settings.queries = (args) => {
	const { section, pageSize, page, filter } = args || {}

	return section ? [
		{
			resource: section,
			args: {
				pageSize: number(pageSize),
				page: page ? number(page) : 0,
				filter: filter ? { type: '[FilterInput!]', value: parseFilter(filter) } : undefined,
			},
			body: `__typename, items{...${section}},hasMore,total,filters{ prop, name, type, list }`
		}
	] : []
}

export default connect(
	({ user, fetch, menu, uploads }) => (
		{
			user,
			fetch,
			menu,
			uploads
		}
	),
	{
		toggleMenu,
		logout,
		setLocale,
		pushModal,
		popModal,
		removeModal,
		pushDialog,
		uploadFiles: uploadAction,
		submitAuditNote: auditNoteSubmit
	}
)(withRouter(Settings))
