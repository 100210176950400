import App from '../controllers/App/App'
import { List, rows, Filters } from 'colbi_web_ui/lib/components'
import theme from 'colbi_web_ui/lib/style/_theme.json';
import { parseFilter } from 'colbi_web_ui/lib/utils';

const queries = (args = {}) => {
	return ([
		{
			resource: args.id,
			args: {
				projectId: (args.projectId),
				fiscalYear: (args.fiscalYear),
				filter: args.filter ? { type: '[FilterInput!]', value: parseFilter(args.filter) } : undefined,
				page: parseInt(args.page, 10) || 0,
			},
			body: `__typename, items{ ...${args.id} }, hasMore, total, filters(projectId:$projectId,fiscalYear:$fiscalYear){ prop, name, type, list }`,
			downloadable: true
		}
	])
}

const report = (path, navigation, exact, title) => ({
	path,
	navigation,
	component: App,
	queries,
	exact,
	title: [title, 'label(param(id))'],
	components: [
		// {
		// 	component: SectionTitle,
		// 	props: {
		// 		title: ['label(param(id))', ' (', 'param(fiscalYear)', ')']
		// 	}
		// },
		{
			component: Filters,
			props: {
				filter: 'param(filter)',
				availableFilters: 'query(0).filters',
				stickyTop: parseInt(theme.layout.$headerHeight, 10),
				anchor: 'results'
			}
		},
		// {
		// 	component: FiltersSummary,
		// 	modifiers: [
		// 		'--root-background-color-alternate-foreground-color',
		// 		'--root-border-radius-regular'
		// 	],
		// 	props: {
		// 		filter: 'param(filter)',
		// 		filters: 'query(0).filters',
		// 		stickyTop: parseInt(theme.layout.$headerHeight, 10),
		// 		anchor: 'results'
		// 	}
		// },
		{
			component: List,
			modifiers: [
				'--root-margin-top-small',
				'--row-style-alternate'
			],
			props: {
				items: 'query(0).list',
				cardKey: 'param(id)',
				card: rows.EntityCard,
				metadata: 'query(0).metadata',
				page: 'param(page)',
				status: 'status(0)',
				pageSize: 'param(pageSize)',
				pageDefault: 10,
				footer: 'query(0).footer',
				header: 'query(0).header'
			}
		}
	]
})

export default report
