const mapCard = (json) => {
	const {
        taxRegistration_number,
        taxType,
        country
	} = json

	return [
        [
            { labels: ['tax_registration'], value: taxRegistration_number, type: 'string' }
        ],
        [
            { labels: ['type'], value: taxType, type: 'string' }
        ],
        [
            { labels: ['country'], value: country, type: 'string' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.customer_id || json.supplier_id

	return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

const header = [
	{ label: ["Tax Registration Number"], align: 'left' },
    { label: ["Tax Type"], align: 'left' },
    { label: ["Country"], align: 'left' }
]

export default {
	map: mapper,
	header
}