const proxy = {
	FindingSummary: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/rules/:id',
	Finding: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/rules/:subId/findings/:id',
	Customer: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/customers/:id',
	Account: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/accounts/:id',
	Product: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/products/:id',
	GeneralLedger: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/general_ledgers/:id',
	Header: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/headers/:id',
	Supplier: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/suppliers/:id',
	Transaction: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/transactions/:id',
	Invoice: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/invoices/:id',
	PurchaseInvoice: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/purchase_invoices/:id',
	StockMovement: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/stock_movements/:id',
	WorkDocument: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/work_documents/:id',
	Payment: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/payments/:id',
	Report: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/:id',

	// To the Findgins links 
	CustomerOpenSalesInvoice: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/customers',
	SupplierOpenPurchaseInvoice: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/suppliers',
	AnalysisTypeTableEntry: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/analysis_types',
	MovementTypeTableEntry: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/movement_types',
	TransactionLineAnalysis: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/transactions',
	TransactionLineTaxInformation: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/transactions',
	AssetSupplier: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/assets',
	PurchaseInvoiceTaxInfoTotal: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/purchase_invoices',
	InvoiceTaxInformationTotal: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/invoices',
	AssetValuationImpairment: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/assets',
	PaymentLineAnalysis: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/payments',
	IlTaxInformation: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/invoice',
	AssetValuation: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/assets',
	PurchaseIlAnalysis: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/purchase_invoices',
	IlAnalysis: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/invoice',
	AnalysisTypes: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/analysis_types',
	StockMovementLineTaxInformation: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/stock_movements',
	PurchaseIlTaxInformation: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/purchase_invoices',
	CustomerAccount: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/customers',
	SupplierAccount: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/suppliers',
	PaymentSettlement: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/payments',
	PurchaseInvoiceSettlement: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/purchase_invoices',
	InvoiceSettlement: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/invoices',
	TaxTableEntry: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/tax_table_entry_details',
	AssetValuationAppreciation: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/assets',
	PhysicalStockAcquisition: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/physical_stocks',
	Journal: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/transactions',

	TrialBalanceTaxonomy: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance?filter=:projectId',

	//ERP
	ErpCustomer: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_master_data/erp_customers/:id',
	ErpSupplier: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_master_data/erp_suppliers/:id',
	ErpSaleInvoice: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_transactional_data/erp_sale_invoices/:id',
	ErpPurchaseInvoice: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_transactional_data/erp_purchase_invoices/:id'
}

export default proxy
