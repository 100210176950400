import JSZip from 'jszip'
var toBuffer = require('typedarray-to-buffer')

const uploadStart = (files) => (
	{
		type: 'UPLOAD_FILE_START',
		payload: { files: files }
	}
)

const uploadProgress = (filename, progress) => (
	{
		type: 'UPLOAD_FILE_PROGRESS',
		payload: { file: filename, progress }
	}
)
const uploadFailed = (filename, message) => (
	{
		type: 'UPLOAD_FILE_FAILED',
		payload: { file: filename, message }
	}
)

const deleteError = (data) => {
	return (
		{
			type: 'DELETE_ERROR',
			payload: {
				data
			}
		}
	)
}

const openUpload = (data) => {
	return (
		{
			type: 'OPEN',
			payload: {
				data
			}
		}
	)
}

const prepareFile = (file) => {
	return new Promise((resolve, reject) => {
		if (file.type === 'text/xml') {
			const zip = new JSZip()
			const fileReader = new FileReader()

			fileReader.readAsArrayBuffer(file)

			fileReader.onloadend = function loaded(event) {
				let result = event.target.result;
				if (result instanceof ArrayBuffer) {
					result = toBuffer(new Uint8Array(event.target.result))

					const zipFileName = file.name.replace('.xml', '.zip')

					zip.file(file.name, result)

					zip.generateAsync({ type: "blob" })
						.then(function (content) {
							const zippedFile = new File([content], zipFileName);
							resolve(zippedFile)
						})
				} else {
					reject(new Error("Invalid File"))
				}
			}
			fileReader.onerror = function (error) {
				reject(error)
			}
		} else {
			resolve(file)
		}
	})
}

let requests = []

export const uploadAction = (projectId, files, action) => (
	(dispatch, getState) => {
		if (!projectId && !files) {
			return dispatch(openUpload(true))
		}

		if (projectId && action === 'close') {
			return dispatch(openUpload(false))
		}

		if (requests && requests.length) {
			requests.forEach((request) => {
				request.abort()
			})
			requests = []
		}

		if (!projectId || !files) {
			return
		}

		dispatch(uploadStart(files))
		const uploads = files.map((file) => {
			return new Promise((resolve, reject) => {
				const request = new XMLHttpRequest()
				request.addEventListener('load', (e) => {
					resolve()
				})
				request.addEventListener('progress', (e) => {
					if (e.lengthComputable) {
						const progress = e.loaded / e.total
						dispatch(uploadProgress(file.name, progress))
					}
				})
				request.upload && request.upload.addEventListener('progress', (e) => {
					if (e.lengthComputable) {
						const progress = e.loaded / e.total
						dispatch(uploadProgress(file.name, progress))
					}
				})
				request.onreadystatechange = () => {
					if (request.readyState === 4) {
						if (request.status === 200) {
							console.log(request.responseText)
						} else if (request.status === 413) {
							dispatch(uploadFailed(file.name, "Error: File too large"))
						} else if (request.status === 0) {
							dispatch(uploadFailed(file.name, "Error: Connection doesn't exist!"))
						} else {
							console.log('response: ', request.responseText)
							dispatch(uploadFailed(file.name, "Error: " + request.responseText))
						}
					}
				}

				requests.push(request)

				const data = new FormData()

				prepareFile(file)
					.then((resultFile) => {
						data.append('file', resultFile)
						data.append('projectSourceId', projectId)

						request.open('POST', '/api/upload', true)
						request.send(data)
					})
					.catch((e) => {
						dispatch(uploadFailed(file.name, e.message))
					})
			})
		})
		return Promise.all(uploads)
	}
)

export const setDataFileStatusAction = ({ fiscalYear, projectId, files, status }) => (
	(dispatch) => {
		const queriesBody = {
			query: `mutation($p:String!,$f:String!,$d:[Int!]!,$s:String!){set_data_file_status(projectId:$p,fiscalYear:$f,dataFileIds:$d,dataFileStatus:$s)}`,
			variables: {
				p: projectId,
				f: fiscalYear,
				d: files,
				s: status // deleted or imported
			}
		}

		return fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res.json().catch(() => ({})))
			.then((res) => {
				const data = res.data || {}

				if (data && data.set_data_file_status) {
					window.location.reload()
				} else {
					dispatch(deleteError('Unknown'))
					throw new Error('Unknown')
				}
			})
			.catch((e) => {
				// console.log(`ERROR: ${ e }`)
				throw new Error(e.message)
			})

	}
)

export const deleteFilePermanentlyAction = ({ fiscalYear, projectId, files, userId }) => (
	(dispatch) => {
		const queriesBody = {
			query: `mutation($p:String!,$f:String!,$d:[Int!]!,$u:String!){delete_file_permanently(projectId:$p,fiscalYear:$f,dataFileIds:$d,userId:$u)}`,
			variables: {
				p: projectId,
				f: fiscalYear,
				d: files,
				u: userId
			}
		}

		return fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res.json().catch(() => ({})))
			.then((res) => {
				const data = res.data || {}

				if (data && data.delete_file_permanently) {
					window.location.reload()
				} else {
					dispatch(deleteError('Unknown'))
					throw new Error('Unknown')
				}
			})
			.catch((e) => {
				// console.log(`ERROR: ${ e }`)
				throw new Error(e.message)
			})

	}
)

export const reprocessRepositoryAction = ({ fiscalYear, projectId, userId }) => (
	(dispatch) => {
		const queriesBody = {
			query: `mutation($p:String!,$f:String!,$u:String!){reprocess_repository(projectId:$p,fiscalYear:$f,userId:$u)}`,
			variables: {
				p: projectId,
				f: fiscalYear,
				u: userId
			}
		}

		return fetch('/graphql', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify(queriesBody)
		})
			.then((res) => res.json().catch(() => ({})))
			.then((res) => {
				const data = res.data || {}

				if (data && data.reprocess_repository) {
					window.location.reload()
				} else {
					dispatch(deleteError('Unknown'))
					throw new Error('Unknown')
				}
			})
			.catch((e) => {
				// console.log(`ERROR: ${ e }`)
				throw new Error(e.message)
			})

	}
)