import Login from '../controllers/Login/Login'
import Projects from '../controllers/Projects/Projects'
import ResetPassword from '../controllers/ResetPassword/ResetPassword'
import Settings from '../controllers/Settings/Settings'
// import Repository from '../controllers/Repository/Repository'

import overview from './overview'
import audit from './audit'
import auditSumary from './auditSumary'
import masterData from './masterData'
import findingRule from './findingRule'
import finding from './finding'
import entity from './entity'
import transactions from './transactions'
import repository from './repository'
import reports from './reports'
import report from './report'
import notFound from './notFound'
import navigation from './navigation'

import erpMasterData from './erp/masterData'
import erpMasterDataEntity from './erp/masterDataEntity'
import erpTransactionalData from './erp/transactionalData'
import erpTransactionalDataEntity from './erp/transactionalDataEntity'
import vatReportGenerate from './vat-report/generate'
import vatDataHistory from './vat-report/data';

const routes = [
	{
		path: '/login',
		component: Login,
		exact: true
	},
	{
		path: '/:locale(pt|en)?',
		component: Projects,
		exact: true
	},
	{
		path: '/:locale(pt|en)?/organizations/:organizationId',
		component: Projects,
		exact: true
	},
	{
		path: '/reset_password',
		component: ResetPassword,
		exact: true
	}, {
		path: '/:locale(pt|en)?/settings/:section?',
		component: Settings,
		exact: false
	},
	repository('/:locale(pt|en)?/:projectId/repository/:section?/:fiscalYear?'),
	overview('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)?/', navigation, true, 'label(Overview)'),
	reports('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/reports', navigation, true, 'label(Reports)'),
	report('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/:id', navigation, true, 'label(Reports)'),
	vatReportGenerate('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/vat_report', navigation, true, 'label(VAT Report)'),
	vatDataHistory('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/vat_data_history', navigation, true, 'label(VAT Data History)'),
	//repository('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)?/repository/:entity?', navigation, true, 'label(Repository)'),
	// masterDataEntity('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/:entity/:entityId', navigation),
	masterData('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/:entity?', navigation, true, 'label(Master Data)'),
	erpMasterData('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_master_data/:entity?', navigation, true, 'label(ERP Master Data)'),
	entity('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/master_data/:entities*', navigation, true, 'label(Master Data)'),
	erpMasterDataEntity('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_master_data/:entities*', navigation, true, 'label(ERP Master Data)'),
	finding('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/rules/:entityId/finding/:id', navigation, true, 'label(Finding)'),
	findingRule('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/rules/:id', navigation, true, 'label(Rules)'),
	audit('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/audit/', navigation, true, 'label(Audit)'),
	auditSumary('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/audit_execution_summary', navigation, true, 'label(Execution Summary)'),
	transactions('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:entity', navigation, true),
	erpTransactionalData('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_transactional_data/:entity?', navigation, true, 'label(ERP Transactional Data)'),
	erpTransactionalDataEntity('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/erp_transactional_data/:entities*', navigation, true, 'label(ERP Transactional Data)'),
	entity('/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/:entities*', navigation),
	notFound('/:locale(pt|en)?/f:projectId?/:fiscalYear([0-9]{4}|current)?/*', navigation),
]

export default routes
