import { buildRequestBody, alias } from "../../utils";

const push = (id, title, view) => (
	{
		type: 'PUSH_MODAL',
		id,
		payload: {
			title,
			view,
			transition: 'enter'
		}
	}
)

const pop = (id) => (
	{
		type: 'POP_MODAL',
		id
	}
)

const remove = (id) => (
	{
		type: 'REMOVE_MODAL',
		id
	}
)

const startFetchingModalData = (id) => (
	{
		type: 'FETCH_MODAL_DATA',
		id
	}
)

const startSavingModalData = (id) => (
	{
		type: 'SAVE_MODAL_DATA',
		id
	}
)

const updateStatus = (id, status, errors) => (
	{
		type: 'UPDATE_MODAL_STATUS',
		id,
		errors,
		status
	}
)

const updateData = (id, data, args, status) => (
	{
		type: 'UPDATE_MODAL_DATA',
		id,
		args,
		status,
		payload: data
	}
)

const removeModalData = (id) => (
	{
		type: 'REMOVE_MODAL_DATA',
		id
	}
)

const promises = {}

export const pushModal = ({ title, data, args, view }) => (
	(dispatch) => {
		const id = `MODAL_${ Date.now() }`
		dispatch(push(id, title, view))
		dispatch(updateData(id, data, args, 'initial'))

		return new Promise((resolve, reject) => {
			promises[id] = [resolve, reject]
		})
	}
)

export const updateModalData = ({ id, data }) => (
	(dispatch, getState) => {
		const currentData = getState().modalsData[id].data
		dispatch(updateData(id, { ...(currentData || {}), ...(data || {}) }))
	}
)

export const updateModalStatus = ({ id, status, errors }) => (
	(dispatch) => {
		dispatch(updateStatus(id, status, errors))
	}
)

export const updateModalErrors = ({ id, data }) => (
	(dispatch, getState) => {
		const currentData = getState().modalsData[id].data
		dispatch(updateData(id, { ...(currentData || {}), ...(data || {}) }))
	}
)

export const fetchModalData = ({ id, queries, mapper }) => (
	(dispatch, getState) => {
		const locale = getState().locale
		const currentData = (getState().modalsData[id] || {}).data || {}

		if (queries) {
			dispatch(startFetchingModalData(id))
			const queriesBody = buildRequestBody(queries, locale)
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res.json().catch(() => ({})))
				.then((res) => {
					const data = ((res || {}).data || {})
					const dataObject = {
						data: {
							...currentData
						}
					}
					mapper.forEach((prop, index) => {
						const query = queries[index]
						if (query) {
							const queryAlias = alias({
								resource: query.resource,
								args: query.args,
								locale
							})
							dataObject[prop] = data[queryAlias]
						}
					})
					dispatch(updateData(id, dataObject))
				})
				.catch((e) => {
					console.log(e)
				})
		}
	}
)

export const saveModalData = (id) => (
	(dispatch, getState) => {
		const modalData = (getState().modalsData[id] || { data: {} }).data.data
		if (modalData && modalData.__typename) {
			const modalName = modalData.__typename.toLowerCase()
			const queriesBody = {
				query: `mutation($${ modalName }:${ modalData.__typename }Input!){createOrUpdate${ modalData.__typename }(${ modalName }:$${ modalName }){id}}`,
				variables: {
					[modalName]: {
						...modalData,
						__typename: undefined
					}
				}
			}
			dispatch(startSavingModalData(id))
			fetch('/graphql', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify(queriesBody)
			})
				.then((res) => res.json().catch(() => ({})))
				.then((res) => {
					const data = res.data || {}
					const errors = res.errors || [{ message: 'Unknown Error' }]
					if (data && data[`createOrUpdate${ modalData.__typename }`] && data[`createOrUpdate${ modalData.__typename }`].id) {
						dispatch(pop(id))
						dispatch(removeModalData(id))
						if (promises[id] && promises[id][0]) {
							promises[id][0]()
						}
						Reflect.deleteProperty(promises, id)
					} else {
						dispatch(updateModalStatus({ id, status: 'error', errors }))
						// console.log('dispatch form error', errors)
					}
				})
		} else {
			if (modalData && !modalData.__typename) {
				console.error('Missing __typename from model data')
			}
			dispatch(pop(id))
			dispatch(removeModalData(id))
			if (promises[id] && promises[id][1]) {
				promises[id][1]()
			}
			Reflect.deleteProperty(promises, id)
		}
	}
)

export const popModal = (id) => (
	(dispatch) => {
		dispatch(pop(id))
		dispatch(removeModalData(id))
		if (promises[id] && promises[id][1]) {
			promises[id][1]()
		}
		Reflect.deleteProperty(promises, id)
	}
)

export const removeModal = (id) => (
	(dispatch) => {
		dispatch(remove(id))
	}
)
