import React, { createContext, useContext } from 'react'
import countries from 'i18n-iso-countries'

import locales from '../I18n/locales.json'

locales.forEach(({ id }) => {
	countries.registerLocale(require(`i18n-iso-countries/langs/${id}.json`))
})

export const GlobalContext = createContext()

export const GlobalProvider = ({ proxy, theme, actions, children, i18n, locale, reload, params }) => {
	return (
		<GlobalContext.Provider value={{ proxy, theme, i18n, locale, countries, reload, params, ...actions }}>
			{children}
		</GlobalContext.Provider>
	)
}
export const useGlobalValue = () => useContext(GlobalContext) || {}