import I18n from '../../I18n'
import { format, date } from '../../utils'

const mapDetails = (json) => {
	const {
		registryDate,
		statusUser,
		statusDate,
		customer,
		shipFrom,
		shipTo,
		type,
		user,
		status,
		period,
		invoiceDate,
		transaction,
		taxPayable,
		grossTotal,
		netTotal
	} = json
	return [
		[
			{ label: 'Type', value: type, type: 'string' },
			{ label: 'Status', value: status, type: 'string' },
			{ label: 'Period', value: period, type: 'number' },
			{ label: 'Invoice Date', value: date(invoiceDate), type: 'date' },
			{ label: 'Registry Date', value: date(registryDate), type: 'date' },
		],
		[
			{ label: 'Gross Total', value: format(grossTotal), type: 'currency' },
			{ label: 'Net Total', value: format(netTotal), type: 'currency' },
			{ label: 'Tax Payable', value: format(taxPayable), type: 'currency' }
		],
		[
			{ label: 'User', value: user, type: 'string' },
			{ label: 'Status User', value: statusUser, type: 'string' },
			{ label: 'Status Date', value: date(statusDate), type: 'date' },
			customer ? { label: 'Customer', value: { id: customer.id, name: customer.name, __typename: 'Customer' }, type: 'object' } : null,
			transaction ? {
				label: 'Transaction',
				value: {
					id: transaction.id,
					name: transaction.name,
					__typename: 'Transaction'
				},
				type: 'object'
			} : null
		],
		shipFrom ? {
			title: 'Ship From',
			rows: [
				[
					{ label: 'ID', value: shipFrom.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipFrom.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipFrom.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: shipFrom.deliveryDate || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipFrom.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipFrom.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipFrom.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipFrom.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipFrom.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipFrom.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipFrom.address.country || 'N/A', type: 'string' },
				]
			]
		} : null,
		shipTo ? {
			title: 'Ship To',
			rows: [
				[
					{ label: 'ID', value: shipTo.deliveryId || 'N/A', type: 'string' },
					{ label: 'Location', value: shipTo.locationId || 'N/A', type: 'string' },
					{ label: 'Warehouse', value: shipTo.warehouseId || 'N/A', type: 'string' },
					{ label: 'Date', value: shipTo.deliveryDate || 'N/A', type: 'date' }
				],
				[
					{ label: 'Address Details', value: shipTo.address.detail || 'N/A', type: 'string' },
					{ label: 'Street', value: shipTo.address.streetName || 'N/A', type: 'string' },
					{ label: 'Number', value: shipTo.address.buildingNumber || 'N/A', type: 'string' },
					{ label: 'City', value: shipTo.address.city || 'N/A', type: 'string' },
					{ label: 'Postal Code', value: shipTo.address.postalCode || 'N/A', type: 'string' },
					{ label: 'Region', value: shipTo.address.region || 'N/A', type: 'string' },
					{ label: 'Country', value: shipTo.address.country || 'N/A', type: 'string' },
				]
			]
		} : null
	]
}

const mapCard = (json) => {
	const {
		number,
		refNumber,
		numberOfLines,
		type,
		status,
		invoiceDate,
		transaction,
		taxPayable,
		grossTotal,
		netTotal,
		country
	} = json

	return [
		[{ labels: ['id'], value: refNumber, type: 'string' }],
		[{ labels: ['lines'], value: numberOfLines, type: 'number' }],
		[{ labels: ['type'], value: I18n.translate`${type}`, type: 'number' }],
		[{ labels: ['country'], value: I18n.translate`${country}`, type: 'number' }],
		[{ labels: ['number'], value: I18n.translate`[${number}]`, type: 'number' }],
		[{ labels: ['date'], value: date(invoiceDate), type: 'date' }],
		[{ labels: ['status'], value: I18n.translate`(${status})`, type: 'number' }],
		transaction && transaction.id ? [{ labels: ['transaction'], value: { id: transaction.id, name: transaction.name, __typename: 'Transaction' }, type: 'object', mobileHidden: true }] : [{ labels: ['transaction'], value: 'N/A', type: 'string' }],
		[{ labels: ['net'], value: format(netTotal), type: 'currency' }],
		[{ labels: ['tax'], value: format(taxPayable), type: 'currency', mobileHidden: true }],
		[{ labels: ['gross'], value: format(grossTotal), type: 'currency' }]
	]
}

const mapper = (json, card, details) => {
	return {
		...json,
		name: json.refNumber,
		shortName: json.refNumber,
		details: details ? mapDetails(json) : [],
		card: card ? mapCard(json) : [],
		raw: json.raw
	}
}

const header = [
	{ label: ["Identifier"], align: 'left', columnName: "invoice_no", sortable: true },
	{ label: ["lines"], align: 'center', columnName: "number_lines", sortable: true },
	{ label: ["Type"], align: 'center', columnName: "invoice_type", sortable: true },
	{ label: ["Country"], align: 'center' },
	{ label: ["[No]"], align: 'center' },
	{ label: ["Date"], align: 'center', columnName: "invoice_date", sortable: true },
	{ label: ["Status"], align: 'center', columnName: "document_status_invoice_status", sortable: true },
	{ label: ["Transaction"], align: 'left', mobileHidden: true },
	{ label: ["Net Total"], align: 'right', columnName: "document_totals_net_total", sortable: true },
	{ label: ["Tax Payable"], align: 'right', mobileHidden: true, columnName: "document_totals_tax_payable", sortable: true },
	{ label: ["Gross Total"], align: 'right', columnName: "document_totals_gross_total", sortable: true }
]

export default {
	map: mapper,
	header
}