import { format } from "../../utils"
import I18n from '../../I18n'

const mapCard = (json) => {
	const {
		name,
		category,
		currency
	} = json

	return [
		[
			{ labels: ['category'], value: I18n.translate`${category}`, type: 'string', basis: '20%' }
		],
		[
			{ labels: ['name'], value: I18n.translate`${name}`, type: 'string', basis: '60%' }
		],
		[
			{ labels: ['currency'], value: format(currency), type: 'currency' }
		]
	]
}

const header = [
	{ label: ['Level 01'], align: 'left', basis: '20%' },
	{ label: ['Description'], align: 'left', basis: '60%' },
	{ label: ['Value'], align: 'right' }
]

const mapper = (json, card) => {
	return {
		...json,
		name: json.name,
		shortName: json.name,
		card: card ? mapCard(json) : [],
		link: {
			to: '/:locale(pt|en)?/:projectId/:fiscalYear([0-9]{4}|current)/reports/trial_balance_taxonomy',
			appendFilters: ['a01'],
			filter: [{ "filters": json.taxonomyCode.map(elem => ({ "negate": false, "value": elem })), "column": "d01" }]
		}
	}
}

export default {
	map: mapper,
	header
}