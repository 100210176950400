import { format } from '../../utils'

const header = [
    { label: ["Analysis ID"], align: 'left' },
    { label: ["Analysis Type"], align: 'center' },
    { label: ["Foreign Currency"], align: 'center' },
    { label: ["Analysis Amount"], align: 'right' },
    { label: ["Foreign Currency Amount"], align: 'right' }
]

const mapCard = (json) => {
	const {
        analysisId,
        analysisType,
        analysisAmountCurrencyCode,
        analysisAmount,
        analysisCurrencyAmount
	} = json

	return [
        [
            { labels: ['analysisId'], value: analysisId, type: 'string' }
        ],
        [
            { labels: ['analysisType'], value: analysisType, type: 'number' }
        ],
        [
            { labels: ['foreignCurrency'], value: analysisAmountCurrencyCode, type: 'number' }
        ],
        [
            { labels: ['analysisAmount'], value: format(analysisAmount), type: 'currency' }
        ],
        [
            { labels: ['foreignCurrencyAmount'], value: format(analysisCurrencyAmount), type: 'currency' }
        ]
	]
}

const mapper = (json, card) => {
    const name = json.invoice_line_id || json.payment_line_id || json.purchase_invoice_line_id || json.transaction_line_id
    
    return {
        ...json,
        __typename: json.__typename,
        id: json.id,
		name,
		shortName: name,
		card: card ? mapCard(json) : []
	}
}

export default {
    map: mapper,
    header
}