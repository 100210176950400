import { datetime } from '../../../utils'

const STATUS = {
    'IN_PROGRESS': '0',
    'SUCCESS': '1',
    'ERROR': '2',
    'DELETED': '3'
}

const STATUS_STYLE = {
    '0': {
        style: 'in_progress',
        label: 'IN PROGRESS'
    },
    '1': {
        style: 'success',
        label: 'SUCCESS'
    },
    '2': {
        style: 'error',
        label: 'WITH ERRORS'
    },
    '3': {
        style: 'error',
        label: 'DELETED'
    }
}

const mapCard = (json) => {
    const {
        id,
        reportType,
        createdAt,
        updatedAt,
        status,
        filePath,
        username
    } = json;

    return [
        [
            { labels: 'Report Type', value: reportType, type: 'string' }
        ],
        [
            { labels: 'Requested By', value: username, type: 'string' }
        ],
        [
            { labels: 'Created At', value: datetime(createdAt), type: 'datetime' }
        ],
        [
            { labels: 'Updated At', value: datetime(updatedAt), type: 'datetime' }
        ],
        [
            { labels: 'Status', value: [(STATUS_STYLE[status] || {}).style, (STATUS_STYLE[status] || {}).label, 'center'], type: 'status' }
        ],
        [
            {
                labels: 'Delete',
                value: status === STATUS['SUCCESS'] ? {
                    key: reportType === 'Vat Control' ? 'vat_control_id' : 'vat_return_id',
                    value: id
                } : null,
                type: 'deleteReport',
                basis: '5%'
            }
        ],
        [
            {
                labels: 'Download',
                value: status === STATUS['SUCCESS'] ? filePath : null,
                type: 'downloadReport',
                basis: '5%'
            }
        ]
    ]
}

const mapper = (json, card) => {
    return {
        ...json,
        card: card ? mapCard(json) : []
    }
}

const header = [
    { label: ['Report Type'], align: 'left', columnName: 'report_type', sortable: true },
    { label: ['Requested By'], align: 'left' },
    { label: ['Created At'], align: 'center', columnName: 'created_at', sortable: true },
    { label: ['Updated At'], align: 'center', columnName: 'updated_at', sortable: true },
    { label: ['Status'], align: 'center', columnName: 'status', sortable: true },
    { label: [''], align: 'left', basis: '5%' },
    { label: [''], align: 'left', basis: '5%' }
]

export default {
    map: mapper,
    header
}
