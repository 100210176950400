export const SimpleEntity = {
	entity: 'SimpleEntity',
	body: 'id,name'
}

export const TaxInformation = {
	entity: 'TaxInformation',
	body: 'entity,base,exemptionReason,exemptionCode,type,countryRegion,code,percentage,amount'
}
export const projectSummary = {
	entity: 'ProjectSumary',
	body: 'invoicesNetTotal,invoicesGrossTotal,invoicesNetAverage,invoicesMaxValue,transactionLinesCount,transactionsCount,leafAccountsCount,generalLedgersCount,invoiceLinesCount,invoicesCount,productsInvoicesCount,customersInvoicesCount,taxCodesCount,productsCodeCount,productsGroupCount,productsCount,suppliersCountriesCount,suppliersNifCount,suppliersCount,customersCountriesCount,customersNifCount,customersCoun,skPurchasesTaxPayable,skPurchasesNetTotal,skPurchasesGrossTotal,skSalesNetTotal,skSalesTaxPayable,skSalesGrossTotal'
}

export const address = {
	entity: 'Address',
	body: 'number,street,detail,postalCode,city,region,country'
}

export const customer = {
	entity: 'Customer',
	body: 'id,taxId,taxEntity,accountId,phone,contact,customerId,companyName,selfBilling,kpiPercentageOnSales,kpiSalesTotalNet,kpiSalesDebitTotalNet,fax,email,website,billingAddress{...address},shipAddresses{...address},entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear)'
}

export const activity = {
	entity: 'Activity',
	body: '__typename,id,name,type,status,uploadedAt,startDate,endDate,submitedBy,checked,projectId,fiscalYear,flowStatus'
}

export const activity_detail = {
	entity: 'ActivityDetail',
	body: 'name,version,type,findingType,findingMessage,uploadedAt,uploadedByName,status,fiscalYear'
}

export const folders = {
	entity: 'DataFile',
	body: '__typename,id,name,type,status,uploadedAt,startDate,endDate,submitedBy,checked,projectId,fiscalYear,auditStatus'
}

export const folders_detail = {
	entity: 'FolderDetail',
	body: 'id,name,generalLedgerEntries,invoiceEntries,stockMovementEntries,workingDocumentEntries,paymentEntries,uploadedAt,uploadedByName,type,version,totalFindings{severity,count}'
}

export const findings = {
	entity: 'Finding',
	body: 'id,severity,referenceType,referenceId,createdAt'
}
export const finding = {
	entity: 'Finding',
	body: 'id,message,messageParameter,severity,referenceType,referenceId,createdAt,type,line,column,xmlBlock, audit_note(projectId:$projectId){id,message,justified}'
}

export const company_repository = {
	entity: 'CompanyRepository',
	body: 'id,name,type,fiscalYear,auditFileVersion,taxRegistrationNumber,businessName,startDate,endDate,glTransactionsCount,invoicesCount,stockMovementsCount,workingDocumentCount,paymentsCount'
}

export const simpleCustomer = {
	entity: 'SimpleEntity',
	body: 'id,name'
}

export const customers = {
	entity: 'Customer',
	body: 'id,companyName,taxId,taxEntity,taxIdCount,accountId,phone,contact'
}

export const trial_balance = {
	entity: 'TrialBalance',
	body: 'id,name,factType,d01,l01,f01,f02,f05,f06,f07,f08,code,rowKeyGeneralLedgers'
}

export const trial_balance_taxonomy = {
	entity: 'TrialBalanceTaxonomy',
	body: 'id,name,factType,d01,l01,f01,f02,f07,f08'
}

export const balance_taxonomy = {
	entity: 'Balance',
	body: '__typename,category,subCategory,name,currency,taxonomyCode'
}

export const profit_and_loss_taxonomy = {
	entity: 'ProfitAndLoss',
	body: 'id,category,name,currency,code,taxonomyCode'
}

export const supplier = {
	entity: 'Supplier',
	body: 'id,taxId,taxEntity,accountId,phone,contact,supplierId,companyName,selfBilling,fax,email,website,billingAddress{...address},shipAddresses{...address},entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }'
}

export const suppliers = {
	entity: 'Supplier',
	body: 'id,taxId,companyName,taxEntity,taxIdCount,accountId,phone,contact,country'
}

export const nomenclatureCode = {
	entity: 'NomenclatureCodes',
	body: 'cnCode'
}

export const onuNumber = {
	entity: 'OnuNumbers',
	body: 'unNumber'
}

export const product = {
	entity: 'Product',
	body: 'id,description,productCode,barCode,type,group,kpiPercentageOnSales,kpiSalesTotalNet,kpiSalesDebitTotalNet,nomenclatureCodes{...nomenclatureCode},onuNumbers{...onuNumber},entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear)'
}

export const products = {
	entity: 'Product',
	body: 'id,description,descriptionCount,type,productCode,group'
}

export const general_ledger = {
	entity: 'GeneralLedger',
	body: 'id,accountId,taxonomyCode,taxEntity,description,openingDebit,closingDebit,openingCredit,closingCredit,groupingCategory,groupingCode,taxonomyReference,level,leafAccount,entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model },reports(projectId:$projectId,fiscalYear:$fiscalYear)'
}

export const general_ledgers = {
	entity: 'GeneralLedger',
	body: 'id,accountId,taxonomyCode,taxEntity,description,openingDebit,closingDebit,openingCredit,closingCredit'
}

export const simpleJournal = {
	entity: 'SimpleEntity',
	body: 'id,name'
}

export const journal = {
	entity: 'Journal',
	body: 'id,journalId,description,taxEntity'
}

export const journals = {
	entity: 'Journal',
	body: 'id,journalId,description'
}

export const tax_table_entries = {
	entity: 'TaxTable',
	body: 'code,description,taxCountryRegion,taxExpirationDate,taxPercentage'
}

export const file = {
	entity: 'File',
	body: `id,name,type,version,uploadedAt`
}

export const application = {
	entity: 'Application',
	body: `name,version,certificateNumber,owner,type`
}
export const company = {
	entity: 'Company',
	body: `name,vat,taxEntity,businessName,registry,phone,fax,email,website`
}

export const transactionsSummary = {
	entity: 'TransactionsSummary',
	body: `entriesNumber,debitTotal,creditTotal`
}

export const movementsSummary = {
	entity: 'MovementsSummary',
	body: `entriesNumber,quantityIssued`
}

export const header = {
	entity: 'Header',
	body: `
		id,
		description,
		fiscalYear,
		auditFileVersion,
		taxRegistrationNumber,
		businessName,
		startDate,
		endDate,
		createdDate,
		glTransactionsCount,
		invoicesCount,
		stockMovementsCount,
		workingDocumentCount,
		paymentsCount,
		application{...application},
		company{...company},
		salesInvoices{...transactionsSummary},
		payments{...transactionsSummary},
		glTransactions{...transactionsSummary},
		workingDocuments{...transactionsSummary},
		stockMovements{...movementsSummary},
		file{...file}
	`
}
export const headers = {
	entity: 'Header',
	body: 'id,fiscalYear,auditFileVersion,taxRegistrationNumber,businessName,startDate,endDate,glTransactionsCount,invoicesCount,stockMovementsCount,workingDocumentCount,paymentsCount'
}

export const transaction_line = {
	entity: 'TransactionLine',
	body: `
		id,
		recordId,
		credit,
		debit,
		description,
		type,
		transactionId,
		transactionDate,
		transactionPeriod,
		journalId,
		docArchivalNumber,
		taxEntity,
		generalLedger{
			id,
			name
		},
		customer{
			id,
			name
		},
		supplier{
			id,
			name
		},
		invoice{
			id,
			name
		}
	`
}
export const transaction_lines = {
	entity: 'TransactionLine',
	body: 'id,credit,debit,description,generalLedger{id,name}' // recordId, 
}

export const transaction = {
	entity: 'Transaction',
	body: `id,transactionId,type,period,description,credit,debit,user,glPostingDate,taxEntity,importId,archivalNumber,journal{ ...SimpleEntity }, entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }`
}

export const transactions = {
	entity: 'Transaction',
	body: 'id,transactionId,type,description,user,period,glPostingDate,debit,credit,numberOfLines,journal{ ...SimpleEntity }'
}

export const invoice_line = {
	entity: 'InvoiceLine',
	body: `
		id,
		description,
		unit,
		unitPrice,
		quantity,
		lineNumber,
		productCode,
		debitAmount,
		creditAmount,
		invoiceNo,
		settlementAmount,
		customer{
			id,
			name,
		},
		shipTo{
			city,
			postalCode,
			region,
			country
		},
		tax{...TaxInformation},
		product{
			id,
			name
		}
	`
}

export const invoice_lines = {
	entity: 'InvoiceLine',
	body: 'id,description,taxPercentage,unit,unitPrice,quantity,lineNumber,productCode,debitAmount,creditAmount'
}

export const invoice = {
	entity: 'Invoice',
	body: `raw,id,refNumber,type,period,status,statusUser,statusDate,taxPayable,netTotal,grossTotal,user,registryDate,invoiceDate,taxEntity,importId,transaction{__typename,id,name},customer{...simpleCustomer},entities(projectId:$projectId,fiscalYear:$fiscalYear){ entity, count, model }` // archivalNumber,shipFrom{deliveryId,locationId,address{detail,city,country}},shipTo{deliveryId,locationId,address{detail,city,country}},
}

export const invoices = {
	entity: 'Invoice',
	body: 'id,refNumber,number,status,country,numberOfLines,type,user,period,invoiceDate,taxPayable,netTotal,grossTotal,numberOfLines,customer{...SimpleEntity},transaction{...SimpleEntity}'
}

export const purchase_invoice = {
	entity: 'PurchaseInvoice',
	body: `id,refNumber,type,period,taxPayable,netTotal,grossTotal,user,invoiceDate,taxEntity,importId,supplierId,supplierRowKey` // archivalNumber,shipFrom{deliveryId,locationId,address{detail,city,country}},shipTo{deliveryId,locationId,address{detail,city,country}},
}

export const purchase_invoices = {
	entity: 'PurchaseInvoice',
	body: 'id,refNumber,number,type,user,period,invoiceDate,taxPayable,netTotal,grossTotal,supplierId,supplierRowKey'
}

export const payment_line = {
	entity: 'PaymentLine',
	body: 'id,refNumber,description,type,lineNumber,series,serialNumber,paymentStatus,transactionDate,settlementAmount,creditAmount,debitAmount,paymentType,customer{ ...SimpleEntity }, tax{...TaxInformation}'
}

export const payment_lines = {
	entity: 'PaymentLine',
	body: 'id,refNumber,lineNumber,series,serialNumber,paymentStatus,transactionDate,settlementAmount,debitAmount,creditAmount'
}

//raw,refNumber,taxEntity,importId,archivalNumber,shipFrom{deliveryId,locationId,address{detail,city,country}},shipTo{deliveryId,locationId,address{detail,city,country}},customer{...customer}
export const payment = {
	entity: 'Payment',
	body: `id,type,period,status,statusUser,statusDate,taxPayable,netTotal,grossTotal,user,registryDate,transactionDate,transaction{__typename,id,name},entities(projectId:$projectId,fiscalYear:$fiscalYear){entity,count,model}`
}

export const paymentsRow = {
	entity: 'Payment',
	body: 'id,refNumber,number,status,numberOfLines,type,user,period,taxPayable,netTotal,grossTotal,numberOfLines'
}

export const payments = {
	entity: 'Payment',
	body: 'id,refNumber,number,status,numberOfLines,description,transaction{__typename,id,name},type,user,period,transactionDate,taxPayable,netTotal,grossTotal,numberOfLines,customer{...simpleCustomer},settlementAmount'
}

export const stock_movement_line = {
	entity: 'StockMovementLine',
	body: `
		id,
		description,
		productCode,
		lineNumber,
		series,
		serialNumber,
		taxType,
		type,
		movementDate,
		taxCountry,
		taxCode,
		quantity,
		taxPercentage,
		debitAmount,
		creditAmount,
		settlementAmount,
		iecAmount,
		documentType,
		documentNumber,
		shipTo{
			city,
			postalCode,
			region,
			country
		},
		transaction{ ...SimpleEntity },
		supplier{ ...SimpleEntity },
		customer{ ...SimpleEntity },
		product{ ...SimpleEntity }
	`
}

export const stock_movement_lines = {
	entity: 'StockMovementLine',
	body: `
		id,
		description,
		productCode,
		lineNumber,
		series,
		serialNumber,
		taxType,
		taxCountry,
		taxCode,
		taxPercentage,
		quantity,
		debitAmount,
		creditAmount
	`
}

export const stock_movement = {
	entity: 'StockMovement',
	body:
		`
		id
		number
		type
		period
		status
		statusUser
		statusDate
		taxPayable
		netTotal
		grossTotal
		user
		registryDate
		movementDate
		taxEntity
		importId
		unicCode
		customer{...SimpleEntity}
		entities(projectId:$projectId, fiscalYear:$fiscalYear){ 
			entity, 
			count, 
			model 
		},
		shipFrom{
			number,
			street,
			detail,
			city,
			postalCode,
			region,
			country
		},
		startTime,
		endTime,
		eacCode,
		atDocId,
		movementComments,
		currencyCode,
		currencyAmount,
		statusReason,
		exchangeRate,
		shipTo{
			number,
			street,
			detail,
			city,
			postalCode,
			region,
			country
		},
		supplier{
			id,
			supplierId,
			accountId,
			companyName,
			taxId,
			billingAddress{ ...address }
		}
	`
	//raw,archivalNumber

}

export const stock_movements = {
	entity: 'StockMovement',
	body: 'id,number,series,serialNumber,movementDate,status,numberOfLines,type,user,period,taxPayable,netTotal,grossTotal,numberOfLines,customer{...SimpleEntity}'
}

export const work_document_line = {
	entity: 'WorkDocumentLine',
	body: `
		id,
		name,
		shortName,
		lineNumber,
		description,
		workDocumentDescription,
		debitAmount,
		creditAmount,
		unitPrice,
		productCode,
		quantity,
		unit,
		settlementAmount,
		lineNumber,
		productRowKey
		customer {
			...simpleCustomer
		}
		workDate,
		documentNumber,
		workType,
		tax{
			code,
			type,
			country,
			percentage,
			amount,
			date,
			iecAmount,
			exemption{
				code,
				reason
			},
			entity,
			base
		}`
}

export const work_document_lines = {
	entity: 'WorkDocumentLine',
	body: `
		id,
		productCode,
		description,
		lineNumber,
		tax{
			percentage
		},
		unit,
		unitPrice,
		quantity,
		debitAmount,
		creditAmount
		`
}

export const work_document = {
	entity: 'WorkDocument',
	body: `id,type,period,status,number,statusUser,documentType,statusDate,taxPayable,netTotal,grossTotal,user,registryDate,postDate,customer{...simpleCustomer},exchangeRate,currencyCode,currencyAmount,eacCode,unicCode,statusReason,transactionId,taxEntity,entities(projectId:$projectId,fiscalYear:$fiscalYear){entity,count,model}`
}

export const work_documents = {
	entity: 'WorkDocument',
	body: 'id,number,series,documentType,serialNumber,status,numberOfLines,type,user,period,postDate,taxPayable,netTotal,grossTotal,numberOfLines,customer{...simpleCustomer}'
}

export const report = {
	entity: 'WorkDocument',
	body: 'id,name,number,series,serialNumber,status,numberOfLines,type,user,period,postDate,taxPayable,netTotal,grossTotal,numberOfLines,customer{...simpleCustomer}'
}

export const users = {
	entity: 'User',
	body: 'id, name, username, role'
}

export const projects = {
	entity: 'Project',
	body: 'id, description, taxRegistrationNumber, organization{ name }, fiscalYears, active'
}

export const project = {
	entity: 'Project',
	body: 'id, description, taxRegistrationNumber, organization{ name }, fiscalYears, active'
}

export const rules = {
	entity: 'Rule',
	body: 'id, ruleId, ruleType, title, appliesTo, description, severity, adapter, level, referenceType, successMessage, insuccessMessage, content, appliesToDataTypes, appliesToVersion, dependencies'
}

export const audit_execution_summary = {
	entity: 'AuditExecutionSummary',
	body: 'id, ruleIdentifier, result, createdAt, rulesTitle, dataFilesName'
}

export const findings_for_record = {
	entity: 'AuditRecord',
	body: 'rule, severity, category, description, occurrences, validation, findingId, ruleId'
}

export const findings_summary = {
	entity: 'AuditSummary',
	body: 'severity, count'
}

//ERP 
export const erp_customers = {
	entity: 'ErpCustomer',
	body: 'id,customerId,name,accountId,taxRegistrationNumber,openBalance'
};

export const erp_customer = {
	entity: 'ErpCustomer',
	body: 'id,customerId,taxRegistrationNumber,taxRegistrationNumber1,name,streetName,city,postalCode,region,country,telephone,fax,accountId,openBalance'
};

export const erp_suppliers = {
	entity: 'ErpSupplier',
	body: 'id,supplierId,name,accountId,taxRegistrationNumber,openBalance'
};

export const erp_supplier = {
	entity: 'ErpSupplier',
	body: 'id,supplierId,taxRegistrationNumber,taxRegistrationNumber1,name,streetName,city,postalCode,region,country,telephone,fax,accountId,openBalance'
};

export const erp_sale_invoices = {
	entity: 'ErpSaleInvoice',
	body: 'id,invoiceNumber,invoiceType,customerId,invoiceDate,glPostingDate,netTotal,taxPayable,grossTotal'
};

export const erp_sale_invoice = {
	entity: 'ErpSaleInvoice',
	body: 'id,invoiceNumber,invoiceType,period,invoiceDate,glPostingDate,grossTotal,taxPayable,netTotal,taxCode,taxPercentage,currencyCode,taxAmount,customer{...SimpleEntity},customerId'
};

export const erp_purchase_invoices = {
	entity: 'ErpPurchaseInvoice',
	body: 'id,invoiceNumber,invoiceType,supplierId,invoiceDate,glPostingDate,netTotal,taxPayable,grossTotal'
};

export const vat_purchases = {
	entity: 'ErpPurchaseInvoice',
	body: 'id,invoiceNumber,invoiceType,invoiceDate,glPostingDate,netTotal,taxPayable,grossTotal,checked'
};

export const vat_invoices = {
	entity: 'ErpSaleInvoice',
	body: 'id,invoiceNumber,invoiceType,invoiceDate,glPostingDate,netTotal,taxPayable,grossTotal,checked'
};

export const erp_purchase_invoice = {
	entity: 'ErpPurchaseInvoice',
	body: 'id,invoiceNumber,invoiceType,period,invoiceDate,glPostingDate,grossTotal,taxPayable,netTotal,taxCode,taxPercentage,currencyCode,taxAmount,supplier{...SimpleEntity},supplierId'
};

export const vat_data = {
	entity: 'VatData',
	body: 'id, invoiceNumber,invoiceDate,netTotal,taxPayable,grossTotal,taxAmount,vatType,checked,period,glPostingDate'
};
export const vat_reports = {
	entity: 'VatReport',
	body: 'id,reportType,userId,projectId,fiscalYear,createdAt,updatedAt,status,filePath,username'
};