import { hydrateRoute, stringifyFilter } from "colbi_web_ui/lib/utils"

let navigation = []

if (process.env.REACT_APP_LOCATION === 'SK') {
	navigation = [
		process.env.REACT_APP_MENU_OVERVIEW === "1" && {
			name: 'label(Overview)',
			to: '/:projectId/:fiscalYear([0-9]{4})/',
			exact: true,
			icon: 'dashboard'
		},
		process.env.REACT_APP_MENU_AUDIT === "1" && {
			name: 'label(Audit)',
			icon: 'audit-icon',
			badge: 'prop(totalFindings)',
			navigation: [
				{
					name: ['label(Results)', ' (', 'prop(totalFindings)', ')'],
					to: '/:projectId/:fiscalYear([0-9]{4})/audit',
				},
				{
					name: 'label(Execution Summary)',
					to: '/:projectId/:fiscalYear([0-9]{4})/audit_execution_summary'
				}
			]
		},
		process.env.REACT_APP_MENU_REPORTS === "1" && {
			name: 'label(Reports)',
			exact: false,
			icon: 'reports',
			navigation: [
				{
					name: 'label(Trial Balance)',
					to: '/:projectId/:fiscalYear([0-9]{4})/reports/trial_balance',
				},
				{
					name: 'label(Trial Balance Taxonomy)',
					to: '/:projectId/:fiscalYear([0-9]{4})/reports/trial_balance_taxonomy',
				},
				{
					name: 'label(Balance Taxonomy)',
					to: `/:projectId/:fiscalYear([0-9]{4})/reports/balance_taxonomy${hydrateRoute(`?filter=${stringifyFilter([{ column: 'a01', filters: [{ value: '13' }] }])}&resetFilters=${Date.now()}`, {})}`
				},
				{
					name: 'label(Profit and Loss Taxonomy)',
					to: `/:projectId/:fiscalYear([0-9]{4})/reports/profit_and_loss_taxonomy${hydrateRoute(`?filter=${stringifyFilter([{ column: 'a01', filters: [{ value: '13' }] }])}&resetFilters=${Date.now()}`, {})}`
				}
			]
		},
		process.env.REACT_APP_MENU_ERP_MASTER_DATA === "1" && {
			name: 'label(ERP Master Data)',
			icon: 'master-data',
			navigation: 'prop(erpMasterData)'
		},
		process.env.REACT_APP_MENU_ERP_TRANSACTIONAL_DATA === "1" && {
			name: 'label(ERP Transactional Data)',
			icon: 'transactions',
			navigation: 'prop(erpTransactionalData)'
		},
		{
			name: 'label(VAT Report)',
			icon: 'vat-report',
			navigation: [
				{
					name: 'label(VAT Generation)',
					to: '/:projectId/:fiscalYear([0-9]{4})/vat_report'
				},
				{
					name: 'label(VAT Generation History)',
					to: `/:projectId/:fiscalYear([0-9]{4})/vat_data_history${hydrateRoute(`?sort=${stringifyFilter([{ column: 'updated_at', order: 'desc' }])}`, {})}`
				}
			]
		},
		process.env.REACT_APP_MENU_MASTER_DATA === "1" && {
			name: 'label(Master Data)',
			icon: 'master-data',
			navigation: 'prop(masterData)'
		},
		process.env.REACT_APP_MENU_TRANSACTIONS === "1" && {
			name: 'label(Transactions)',
			icon: 'transactions',
			navigation: 'prop(transactionalData)'
		},
		process.env.REACT_APP_MENU_REPOSITORY === "1" && {
			name: 'label(Repository)',
			to: '/:projectId/repository',
			exact: true,
			icon: 'repository',
			funcOnClick: "() => uploadFiles()"
		},
		{
			type: 'separator'
		},
		{
			name: 'label(Home)',
			to: '/',
			exact: true,
			icon: 'back'
		}
	].filter(i => i)
} else {
	navigation = [
		{
			name: 'label(Overview)',
			to: '/:projectId/:fiscalYear([0-9]{4})/',
			exact: true,
			icon: 'dashboard'
		},
		{
			name: 'label(Audit)',
			icon: 'audit-icon',
			badge: 'prop(totalFindings)',
			navigation: [
				{
					name: ['label(Results)', ' (', 'prop(totalFindings)', ')'],
					to: '/:projectId/:fiscalYear([0-9]{4})/audit',
				},
				{
					name: 'label(Execution Summary)',
					to: '/:projectId/:fiscalYear([0-9]{4})/audit_execution_summary'
				}
			]
		},
		{
			name: 'label(Reports)',
			exact: false,
			icon: 'reports',
			navigation: [
				{
					name: 'label(Trial Balance)',
					to: '/:projectId/:fiscalYear([0-9]{4})/reports/trial_balance',
				},
				{
					name: 'label(Trial Balance Taxonomy)',
					to: '/:projectId/:fiscalYear([0-9]{4})/reports/trial_balance_taxonomy',
				},
				{
					name: 'label(Balance Taxonomy)',
					to: `/: projectId /: fiscalYear([0 - 9]{ 4}) / reports / balance_taxonomy${hydrateRoute(`?filter=${stringifyFilter([{ column: 'a01', filters: [{ value: '13' }] }])}&resetFilters=${Date.now()}`, {})}`
				},
				{
					name: 'label(Profit and Loss Taxonomy)',
					to: `/: projectId /: fiscalYear([0 - 9]{ 4}) / reports / profit_and_loss_taxonomy${hydrateRoute(`?filter=${stringifyFilter([{ column: 'a01', filters: [{ value: '13' }] }])}&resetFilters=${Date.now()}`, {})} `
				}
			]
		},
		{
			name: 'label(Master Data)',
			icon: 'master-data',
			navigation: 'prop(masterData)'
		},
		{
			name: 'label(Transactions)',
			icon: 'transactions',
			navigation: 'prop(transactionalData)'
		},
		{
			name: 'label(Repository)',
			to: '/:projectId/repository',
			exact: true,
			icon: 'repository',
			funcOnClick: "() => uploadFiles()"
		},
		{
			type: 'separator'
		},
		{
			name: 'label(Home)',
			to: '/',
			exact: true,
			icon: 'back'
		}
	].filter(i => i)
}

export default navigation
