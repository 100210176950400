import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import 'colbi_web_ui/lib/style/variableInjector.scss'
import 'colbi_web_ui/lib/style/index.sass'

import { ReactComponent as Icons } from 'colbi_web_ui/lib/assets/icons.inline.svg'

import './client/polyfills/object.entries.js'

import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { Provider } from 'react-redux'

import routes from './client/routes'
import DataLoader from './client/components/DataLoader'
import configureStore from './client/store'
import { fetchAction } from './client/store/actions'
import * as serviceWorker from './client/serviceWorker'

const store = configureStore({})

ReactDOM.render(
	<Provider store={ store }>
		<Router>
			<DataLoader routes={ routes } dispatch={ store.dispatch } fetch={ fetchAction }>
				{
					(reload) => (
						<>
							<Icons key={ 'icons' } />
							{ renderRoutes(routes, { reload }) }
						</>
					)
				}
			</DataLoader>
		</Router>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
